import React from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';

import { Link } from 'react-router-dom';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

export interface ICardTagsProps {
  tags: ICardProps['tags']
  isMobile: boolean
  isCluster?: boolean
  wrapperClassName?: string
  textClassName?: string
}

function Tags({
  tags,
  isMobile,
  isCluster,
  wrapperClassName,
  textClassName,
}: ICardTagsProps) {
  const {
    top100Prefix,
  } = useTop100Context();

  if (!tags || !tags.length) return null;

  return (
    <div
      className={cn(
        s.tags,
        wrapperClassName,
      )}
    >
      {tags.map(tag => {
        const isBreadcrumbs = tag.type === 'breadcrumb' || tag.type === 'breadcrumbs';

        if (tag.link && tag.link.length) {
          const outerLink = tag.type !== 'breadcrumb';

          if (isCluster && isBreadcrumbs) {
            return (
              <Typography
                variant="defaultMedium"
                className={cn(
                  s.tag,
                  s.tagGray,
                  textClassName,
                )}
                key={tag.link}
                element={(
                  <Link
                    to={tag.link}
                    {...getTop100Markup(isMobile, top100Prefix, `tag::${outerLink ? 'outer' : tag.link}`)}
                    itemScope
                    itemType="http://schema.org/BreadcrumbList"
                  />
                )}
              >
                <span
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                >
                  <link itemProp="url" href={tag.link} />
                  <meta itemProp="position" content="1" />
                  <meta itemProp="name" content={tag.text} />
                  {tag.text}
                </span>
              </Typography>
            );
          }

          if (!isCluster && isBreadcrumbs) {
            return null;
          }

          return (
            <Typography
              variant="defaultMedium"
              className={cn(
                s.tag,
                textClassName,
              )}
              key={tag.link}
              element={(
                // eslint-disable-next-line
                <a href={tag.link} target="_blank" rel="noopener noreferrer nofollow" />
              )}
              {...getTop100Markup(isMobile, top100Prefix, `tag::${outerLink ? 'outer' : tag.link}`)}
            >
              {tag.text}
            </Typography>
          );
        }

        if (!isCluster && isBreadcrumbs) {
          return null;
        }

        return (
          <Typography
            variant="defaultMedium"
            className={cn(
              s.tag,
              s.tagGray,
              textClassName,
            )}
            key={tag.text}
          >
            {tag.text}
          </Typography>
        );
      })}
    </div>
  );
}

Tags.defaultProps = {
  wrapperClassName: '',
  textClassName:    '',
  isCluster:        false,
};

export { Tags };
