import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';

import _throttle from 'lodash.throttle';

import { Link } from 'react-router-dom';

import Image from '@rambler-components/image';
import Button from '@rambler-components/button';

import { ICardProps } from 'Card';

import { safeGet } from 'utils/safeGet';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { Icon } from 'common/components/Icon';
import useIsInViewport from 'common/hooks/useIsInViewport';
import { SCROLL2SITE_INFO } from 'config/constants/commercialLongread';

import { Title } from 'common/components/Card/Title';
import { Tags } from 'common/components/Card/Tags';

import cardStyles from 'common/components/Card/styles.css';

import { ClusterMediaSection } from '../components/ClusterMainMedia';
import { ClusterText } from '../components/ClusterText';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.config.runProfile,
  ],
  (
    isMobile,
    runProfile,
  ) => ({
    isMobile,
    runProfile,
  }),
);

interface ICardLongreadClusterProps {
  article: ICardProps['article']
  tags: ICardProps['tags']
  isLazy: ICardProps['isLazy']
  wrapperClassName?: string
}

function LongreadPremiumCluster({
  article,
  tags,
  isLazy,
  wrapperClassName,
}: ICardLongreadClusterProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    isMobile,
    runProfile,
  } = useSelector(selectData);
  const [isBookmarkShown, setIsBookmarkShown] = useState(false);
  const [isBookmarkSticky, setIsBookmarkSticky] = useState(true);
  const [xOffset, setXOffset] = useState(0);
  const textReaded = useRef(false);

  const scrollToSite = useRef<HTMLDivElement>(null);
  const redirectRef = useRef<HTMLDivElement>(null);
  const bookmarkRef = useRef<HTMLAnchorElement>(null);
  const bookmarkWrapperRef = useRef<HTMLDivElement>(null);
  const alertRef = useRef<HTMLDivElement>(null);
  const articleRef = useRef<HTMLDivElement>(null);
  const articleWrapperRef = useRef<HTMLDivElement>(null);

  const isRedirectInView = useIsInViewport(redirectRef);
  const isAlertInView = useIsInViewport(alertRef);

  useEffect(() => {
    if (isRedirectInView) {
      bookmarkRef.current!.click();
    }
  }, [isRedirectInView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsBookmarkSticky(!isAlertInView);
  }, [isAlertInView]);

  const articleWrapperNode = articleWrapperRef.current!;
  const bookmarkNode = bookmarkRef.current;

  // Не articleWrapperNode так как иначе в useEffect не обновится замыкание
  const getPageFields = () => (articleWrapperRef.current
    ? (document.body.clientWidth - articleWrapperRef.current.clientWidth) / 2
    : 0);

  useEffect(() => {
    const onScroll = _throttle(() => {
      // сравнивать xOffset с новым значение не нужно,
      // Так как, если они равны, ререндера не будет
      setXOffset(getPageFields() - window.pageXOffset);
    // 13 - ~75 кадров в сек
    }, 13);

    onScroll();

    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!article) return null;

  const sendPageReadEvent = (isInView: boolean) => {
    if (window.rcm && !textReaded.current && isInView) {
      textReaded.current = true;

      window.rcm('pageread', {
        itemId: article.id,
      });
    }
  };

  const partnerInfo = SCROLL2SITE_INFO(runProfile)[article.id];

  const rubricAlias = safeGet(() => article.topic.alias, 'longread');
  const link = { link: `/${rubricAlias}/${article.id}-${article.normalized_title}/` };

  const pageFields = getPageFields();

  const clusterNode = (
    <div
      className={cn(
        s.cluster,
        isMobile ? s.clusterMobile : s.clusterDesktop,
        wrapperClassName,
        !isBookmarkSticky && s.fixed,
      )}
      ref={articleRef}
      style={!isBookmarkSticky && articleWrapperNode && bookmarkNode
        ? {
          width:         articleWrapperNode.clientWidth,
          // Отступ от низа, чтобы правильно происходил переход,
          // когда меняется позиционирование закладки
          paddingBottom: bookmarkNode.clientHeight,
          // Для позиционирования когда страница шире вьюпорта
          left:          xOffset,
        }
        : {}}
    >
      <Title
        title={article.long_title}
        isMobile={isMobile}
        link={link}
        type="longread_detail"
        isLink={false}
        isMainCard
        textClassName={s.premiumTitle}
        wrapperClassName={s.premiumTitleWrapper}
      />
      <Tags
        wrapperClassName={cn(
          cardStyles.tags,
          isMobile ? cardStyles.tagsMobile : cardStyles.tagsDesktop,
          s.premiumTags,
        )}
        tags={tags}
        isMobile={isMobile}
        isCluster
      />
      <ClusterMediaSection
        cluster={article}
        isLazy={isLazy}
        isPremium
      />
      <ClusterText
        text={article.body}
        isPremium
      />
      <InView as="div" onChange={(inView: boolean) => sendPageReadEvent(inView)} />
    </div>
  );

  return (
    <>
      <div
        className={s.clusterWrapper}
        style={!isBookmarkSticky && articleRef.current
          // Чтобы страницы не меняла свою высоту
          ? { paddingTop: articleRef.current.scrollHeight }
          : {}}
        ref={articleWrapperRef}
      >
        {clusterNode}
      </div>

      {!partnerInfo && !isMobile && (
        <div className={s.buttonWrapper}>
          <Link
            to="/longread/"
            {...getTop100Markup(isMobile, top100Prefix, 'cluster_premium::all_articles_button')}
          >
            <Button>
              Все статьи
            </Button>
          </Link>
        </div>
      )}

      {partnerInfo && (
        <div
          className={s.scrollToSite}
          ref={scrollToSite}
          style={pageFields
            ? {
              // Растягиваем scroll2site на всю ширину окна
              marginLeft:  -pageFields,
              marginRight: -pageFields,
            }
            : {}}
        >
          <div className={s.redirect} ref={redirectRef} />
          <div
            className={s.bookmarkWrapper}
            style={isBookmarkSticky && bookmarkNode
              ? { paddingTop: bookmarkNode.clientHeight }
              : {}}
            ref={bookmarkWrapperRef}
          >
            <a
              className={cn(
                s.bookmark,
                isBookmarkSticky && s.fixed,
                !isBookmarkShown && s.deep,
              )}
              style={
                isBookmarkSticky && bookmarkWrapperRef.current && bookmarkNode
                  ? {
                    left: xOffset
                      + (articleWrapperNode.clientWidth - bookmarkNode.clientWidth) / 2,
                  }
                  : {}
              }
              ref={bookmarkRef}
              href={partnerInfo.url}
              aria-label="Закладка"
            >
              <span className={s.bookmarkContent}>
                <Icon id="arrow-up" style={{ width: 20, height: 20 }} />

                <Image
                  src={partnerInfo.logo}
                  isS3={false}
                  isImg
                  alt=""
                  isLazy={false}
                  className={s.bookmarkLogo}
                  onLoad={() => setIsBookmarkShown(true)}
                  onError={() => setIsBookmarkShown(true)}
                />
              </span>
            </a>
          </div>
          <div ref={alertRef}>
            <span className={s.scrollToSiteAlert}>
              Дальше вы перейдете на сайт нашего партнера
            </span>
            <img
              src={partnerInfo.screenshot}
              alt=""
              className={s.scrollToSiteScreenshot}
            />
          </div>
        </div>
      )}
    </>
  );
}

LongreadPremiumCluster.defaultProps = {
  wrapperClassName: '',
};

export { LongreadPremiumCluster };
