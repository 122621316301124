import React from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';

import { Link } from 'react-router-dom';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

export interface ICardSwithLinksProps {
  switchLinks: ICardProps['switch_links']
  isMobile: boolean
  className?: string
}

function SwitchLinks({
  switchLinks,
  isMobile,
  className,
}: ICardSwithLinksProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  if (!switchLinks || !switchLinks.length) return null;

  return (
    <div
      className={cn(
        s.switchLinks,
        className,
      )}
    >
      {switchLinks.map(switchLink => (
        <Typography
          variant="defaultMedium"
          className={s.switchLink}
          key={switchLink.link}
          element={(
            <Link
              to={switchLink.link}
              {...getTop100Markup(isMobile, top100Prefix, `switchLink::${switchLink.link}`)}
            />
          )}
        >
          {switchLink.text}
        </Typography>
      ))}
    </div>
  );
}

SwitchLinks.defaultProps = {
  className: '',
};

export { SwitchLinks };
