class SteamParticle {
  x: number;
  y: number;
  size: number;
  startSize: number;
  endSize: number;
  angle: number;
  startLife: number;
  lifeTime: number;
  velY: number;
  velX: number;
  alpha: number;

  constructor(x: number, y: number) {
    const deviation = (Math.round(Math.random()) * 2 - 1) * (Math.random() * 50);
    this.x = x + deviation;
    this.y = y;

    this.size = 1;
    this.startSize = 32;
    this.endSize = 40;

    this.angle = Math.random() * 359;

    this.startLife = new Date().getTime();
    this.lifeTime = 0;

    this.velY = -1 - (Math.random() * 0.5);
    this.velX = Math.floor(Math.random() * (-6) + 3) / 10;
  }

  update(maxLifeTime: number) {
    this.lifeTime = new Date().getTime() - this.startLife;
    this.angle += 0.2;

    const lifePerc = ((this.lifeTime / maxLifeTime) * 100);

    this.size = this.startSize + ((this.endSize - this.startSize) * lifePerc * 0.1);

    this.alpha = 1 - (lifePerc * 0.01);
    this.alpha = Math.max(this.alpha, 0);

    this.x += this.velX;
    this.y += this.velY;
  }
}

export default SteamParticle;
