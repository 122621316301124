import React from 'react';
import cn from 'classnames';

import { IBiorhythm } from 'Card';

import { DraftText } from 'common/components/DraftText';
import { Typography } from 'common/components/Typography';

import mainStyles from '../styles.css';
import s from './styles.css';

interface ICardBiorhythmTextProps {
  data: IBiorhythm[]
  isMobile: boolean
  isMainCard: boolean
}

const BiorhythmText: React.FC<ICardBiorhythmTextProps> = React.memo(({
  isMobile,
  isMainCard,
  data,
}) => {
  if (!data || !data.length) return null;

  if (isMainCard) {
    return (
      <div className={cn(
        s.full,
        isMobile ? s.fullMobile : s.fullDesktop,
      )}
      >
        {data.map(chartDescription => (
          <div
            className={s.fullItem}
            key={chartDescription.id}
          >
            <div className={s.fullTop}>
              <span className={cn(
                mainStyles[`color-${chartDescription.type}`],
                s.fullPercent,
              )}
              >
                {chartDescription.percent}
                %
              </span>
              <span className={s.fullTitle}>
                {' '}
                &mdash;
                {' '}
                {chartDescription.title}
              </span>
            </div>
            <DraftText
              data={chartDescription.draftParsed}
              title={chartDescription.title}
              className={cn(
                s.fullDraftText,
                isMobile ? s.fullDraftTextMobile : s.fullDraftTextDesktop,
              )}
              isMainCard
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={cn(
      s.short,
      isMobile ? s.shortMobile : s.shortDesktop,
    )}
    >
      {data.map(chartDescription => (
        <div className={s.shortParam} key={chartDescription.id}>
          <Typography
            variant="h2"
            component="span"
            className={cn(
              mainStyles[`color-${chartDescription.type}`],
              s.shortValue,
            )}
          >
            {chartDescription.percent}
            %
          </Typography>
          <span
            className={s.shortText}
              // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: chartDescription.title.split(' ').join('<br />'),
            }}
          />
        </div>
      ))}
    </div>
  );
});

export { BiorhythmText };
