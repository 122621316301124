import React from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';

import s from './styles.css';

interface ITarotPromoProps {
  isMobile: boolean
  link: ICardProps['link']
}

const POSTFIX_BY_LINK: {[key: string]: string} = {
  '/gadanie-online/taro-keltskiy-krest/': '',
  '/taro/data-rozhdeniya/':               'ByBirthdate',
  '/taro/lyubov/':                        'Love',
  '/taro/budushchee/':                    'Future',
  '/taro/da-net/':                        'Short',
};

const CARD_ID_BY_LINK: {[key: string]: number} = {
  '/gadanie-online/taro-keltskiy-krest/': 1,
  '/taro/data-rozhdeniya/':               8,
  '/taro/lyubov/':                        6,
  '/taro/budushchee/':                    10,
  '/taro/da-net/':                        15,
};

function TarotPromo({
  isMobile,
  link = { link: '' },
}: ITarotPromoProps) {
  const isCeltic = link.link === '/gadanie-online/taro-keltskiy-krest/';
  const postfix = POSTFIX_BY_LINK[link.link];
  const cardId = CARD_ID_BY_LINK[link.link];

  if (isCeltic) {
    return (
      <>
        <div className={cn(s.cardBack, isMobile ? s.cardBackMobile : s.cardBackDesktop)} />
        <div className={cn(s.card, isMobile ? s.cardMobile : s.cardDesktop)} />
        {isMobile && (
          <div className={cn(
            s.planet,
            s.planetNormal,
            s.planetMobile,
          )}
          />
        )}
        <div className={cn(
          s.planet,
          s.planetBig,
          isMobile ? s.planetMobile : s.planetDesktop,
        )}
        />
        {!isMobile && <div className={cn(s.star, s.starSmall, s.starDesktop)} />}
        {!isMobile && <div className={cn(s.star, s.starNormal, s.starDesktop)} />}
        <div className={cn(s.star, s.starBig, isMobile ? s.starMobile : s.starDesktop)} />
      </>
    );
  }

  return (
    <>
      <div className={cn(
        s.symbol,
        s[`symbol${postfix}`],
        s[`symbol${postfix}${isMobile ? 'Mobile' : 'Desktop'}`],
      )}
      />
      <div className={cn(s.rotateBack, isMobile && s.rotateBackMobile)}>
        <div className={cn(s.tarotCard, s['tarotCard-back'], isMobile && s.tarotCardMobile)} />
      </div>
      <div className={s.rotateFront}>
        <div className={cn(
          s.tarotCard,
          s['tarotCard-front'],
          s[`tarotCard-${cardId}`],
          isMobile && s.tarotCardMobile,
        )}
        />
      </div>
      {!isMobile && (
        <div className={cn(
          s[`star${postfix}`],
          s[`star${postfix}Small`],
          s[`star${postfix}Desktop`],
        )}
        />
      )}
      {!isMobile && (
        <div className={cn(
          s[`star${postfix}`],
          s[`star${postfix}Normal`],
          s[`star${postfix}Desktop`],
        )}
        />
      )}
      <div className={cn(
        s[`star${postfix}`],
        s[`star${postfix}Big`],
        s[`star${postfix}${isMobile ? 'Mobile' : 'Desktop'}`],
      )}
      />
      <div className={cn(
        s[`planet${postfix}`],
        s[`planet${postfix}${isMobile ? 'Mobile' : 'Desktop'}`],
      )}
      />
    </>
  );
}

export { TarotPromo };
