import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { ICardProps } from 'Card';

import { jsxFromHtmlString } from 'common/utils/draftParser';

import { useTop100Context } from 'common/contexts/top100Context';

import Toggle from './Toggle';
import s from './index.css';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.currentParams.url,
    (state: IAppState) => state.runtime.path,
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.isBot,
    (state: IAppState) => state.runtime.origin,
  ],
  (
    currentPage,
    currentSection,
    currentParamsUrl,
    path,
    isMobile,
    isBot,
    origin,
  ) => ({
    currentPage,
    currentSection,
    currentParamsUrl,
    path,
    isMobile,
    isBot,
    origin,
  }),
);

export const DraftText = React.memo((
  {
    data,
    title = '',
    className = '',
    isMainCard = false,
  }: {
    data: ICardProps['draftParsed']
    title: string
    className: string
    isMainCard: boolean
  },
) => {
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    currentPage,
    currentSection,
    currentParamsUrl,
    isMobile,
    isBot,
    origin,
  } = useSelector(selectRuntimeData);
  const useTextToggle = useMemo(
    () => currentPage === 'omens'
      && currentSection === 'calendar'
      && currentParamsUrl === ''
      && isMainCard,
    [currentPage, currentSection, currentParamsUrl, isMainCard],
  );

  if (!data) return null;

  const content = jsxFromHtmlString(data!, isMobile, top100Prefix, s, title, origin, isBot);
  /* Функция jsxFromHtmlString может возвращать:
    - массив JSX.Element[] (в случае если draft -> blocks — это массив, состоящий из > 1 элемента)
    - JSX.Element ( если draft -> blocks содержит только 1 узел)

    Чтобы можно было пройтись map-ом по контенту, если это не массив, делаем его массивом
  */
  let contentArray = [];
  if (!Array.isArray(content)) {
    contentArray = [content];
  } else {
    contentArray = content;
  }

  if (useTextToggle) {
    return (
      <Toggle
        text={(contentArray as JSX.Element[])}
        isMobile={isMobile}
        isMainCard={isMainCard}
        currentPage={currentPage}
        currentSection={currentSection}
        currentParamsUrl={currentParamsUrl}
        className={className}
      />
    );
  }

  return (
    <div
      className={cn(
        s.draftContent,
        isMobile ? s.draftContentMobile : s.draftContentDesktop,
        className,
      )}
      itemProp={isMainCard ? 'articleBody' : ''}
    >
      {/* @ts-ignore TODO разобраться/поправить TS */}
      {contentArray.map((element, index) => {
        const key = `DraftText-${currentPage}-${currentSection}-${currentParamsUrl}-${index};`;

        return (
          <React.Fragment key={key}>
            {element}
          </React.Fragment>
        );
      })}
    </div>
  );
});
