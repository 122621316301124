import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { snackbarEvent } from '@rambler-components/snackbar';

import { getSignByDate } from 'common/utils/signByDate';

import { IBubble } from 'Card';

const STORAGE_SIGN_KEY = 'rh_bubble_sign';

export const useSelectSign = () => {
  const birthdate = useSelector((state: IAppState) => state.account.birthday);

  /** знак, выбранный пользователем */
  const [selectSign, setSelectSign] = useState('');

  /** флаг, что проверка выбранного знака произошла -
   чтобы не было миганий при отрисовки элементов, корорые зависят от
   выбора знака
   */
  const [signChecked, setSignChecked] = useState(false);
  /** флаг того, что знак определился из аккаунта пользователя
   и например - выбор знака "руками" будет недоступен
  */
  const [isAccountSign, setIsAccountSign] = useState(false);

  const checkSelectSign = () => {
    if (birthdate) {
      const sign = getSignByDate(birthdate);

      if (sign) {
        setSelectSign(sign);
        setIsAccountSign(true);
        setSignChecked(true);
        return;
      }
    }

    const bubbleSign = window.localStorage.getItem(STORAGE_SIGN_KEY) || '';
    setSignChecked(true);
    setSelectSign(bubbleSign);
  };

  const onSaveSign = (sign?: IBubble['sign'], name?: IBubble['name']) => {
    if (sign && (selectSign !== sign)) {
      try {
        window.localStorage.setItem(STORAGE_SIGN_KEY, sign);
        setSelectSign(sign);
        snackbarEvent({
          message:           `Знак «${name || sign}» выбран основным`,
          align:             'bottom center',
          withCloseButton:   true,
          autoCloseDuration: 3000,
        });
      } catch {
        snackbarEvent({
          message:           'Не получилось сохранить. Попробуйте еще раз.',
          type:              'error',
          align:             'bottom center',
          withCloseButton:   true,
          autoCloseDuration: 3000,
        });
      }
    }
  };

  const onCancelSaveSign = (name: IBubble['name']) => {
    if (!selectSign) return;

    try {
      window.localStorage.removeItem(STORAGE_SIGN_KEY);
      setSelectSign('');
      snackbarEvent({
        message:           `Знак «${name || selectSign}» больше не основной`,
        align:             'bottom center',
        withCloseButton:   true,
        autoCloseDuration: 3000,
      });
    } catch {
      snackbarEvent({
        message:           'Не получилось отменить. Попробуйте еще раз.',
        type:              'error',
        align:             'bottom center',
        withCloseButton:   true,
        autoCloseDuration: 3000,
      });
    }
  };

  useEffect(() => {
    checkSelectSign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthdate]);

  return {
    selectSign,
    signChecked,
    isAccountSign,
    onSaveSign,
    onCancelSaveSign,
  };
};
