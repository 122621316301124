import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { setContentLikesLink } from 'common/redux/pages';
import { sentryReactSend } from 'utils/sentry/client';

import { APP_VERSION } from 'config/constants';
import { SPECIAL_URLS } from 'config/constants/fortune';
import updateCounters from 'utils/counters/updater';

import s from './styles.css';
import { Loader } from './Loader';

const selectSpecialRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.currentParams.specialKey,
    (state: IAppState) => state.runtime.currentParams.specialCurrent,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (
    specialKey,
    specialCurrent,
    isMobile,
  ) => ({
    specialKey,
    specialCurrent,
    isMobile,
  }),
);

const selectBirthdate = createSelector(
  [
    (state: IAppState) => state.account.birthday,
  ],
  birthdate => ({
    birthdate,
  }),
);

interface IFortuneProps {
  link: string
  name: string
  className?: string
}

function FortuneGame({
  link,
  name,
  className,
}: IFortuneProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    specialKey,
    specialCurrent,
    isMobile,
  } = useSelector(selectSpecialRuntimeData);
  const { birthdate } = useSelector(selectBirthdate);

  const [isResult, setIsResult] = useState(Boolean(specialKey));
  // для гадания по дате рождения пробрасываем дату для инпута
  const birthdateParam = name === 'birth-date-fortune' && birthdate
    ? `&birthdate=${birthdate.split('-').reverse().join('-')}`
    : '';
  const device = `device=${isMobile ? 'mobile' : 'tablet'}`;
  const specialUrls = SPECIAL_URLS[name];
  const specialParams = specialKey
    ? `&key=${specialKey}&${specialUrls.elem}=${specialCurrent || 1}&builtin=true`
    : `&builtin=true${birthdateParam}`;
  const src = `${link}?${device}${specialParams}`;

  useEffect(() => {
    const handleMessage = (e: any) => {
      const { data } = e;
      if (!data) return;

      if (data.type === 'click') {
        if (data.isExternal) {
          window.location.href = data.url;
        } else {
          history.push(data.url);
        }
      } else if (data.type === 'result') {
        let url = specialUrls.ts;
        const updated = window.location.search.indexOf('updated') !== -1;
        const special = data.key ? 'special=true' : '';
        const params = special
          ? `?${special}${updated ? '&updated' : ''}`
          : `${updated ? '?updated' : ''}`;
        if (data.key) {
          url = `${specialUrls.ts}${data.key}/${specialUrls.elem}-${data.elem}/`;
        }
        const urlWithParams = `${url}${params}`;
        window.history.replaceState({ url: urlWithParams }, 'title', urlWithParams);
        dispatch(setContentLikesLink(url));
        updateCounters(isMobile ? APP_VERSION.MOBILE : APP_VERSION.DESKTOP);
        setIsResult(Boolean(data.key));
      } else if (data.type === 'error') {
        sentryReactSend(data.error);
        // eslint-disable-next-line no-console
        console.error(`${new Date()} ${data.error}`);
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        isResult && isMobile && s.rootMobileResult,
        s[`root-${name}`],
        className,
      )}
    >
      <Loader fortune={name} />
      <iframe
        className={cn(s.iframe, s.loader)}
        src={src}
        width="100%"
        height="100%"
        title="game"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
      />
    </div>
  );
}

FortuneGame.defaultProps = {
  className: '',
};

export { FortuneGame };
