import { safeGet } from 'utils/safeGet';

import { getPuidsStrForEaglePlatform } from 'common/utils/searchIframe';

const RECORD_ID_REGEXP = /record_id=(\d+)/;
const RECORD_ID_BROADCAST_REGEXP = /.+\/(\d+)/;
const MULTIPLAYER_FILTER_ID_REGEXP = /multiplayer_filter_id=(\d+)/;

/**
 * Получение параметров для плеера от EaglePlatform
 *
 * @param html - html-код видео плеера
 * @param puids - рекламные пуиды
 * @param isBroadcast - флаг что это трансляция
 */
export const useVideoParams = (
  html: string,
  puids: PuidsType,
  isBroadcast: boolean,
) => {
  const recordId = safeGet(
    () => Number(html.match(isBroadcast ? RECORD_ID_BROADCAST_REGEXP : RECORD_ID_REGEXP)![1]),
  );
  const multiplayerFilterId = safeGet(
    () => Number(html.match(MULTIPLAYER_FILTER_ID_REGEXP)![1]),
    undefined,
  );

  const playerTemplateId = 10709;

  const sspJparams: PuidsType = getPuidsStrForEaglePlatform(puids, true) as any;

  return {
    recordId,
    adTemplateId: undefined,
    multiplayerFilterId,
    playerTemplateId,
    sspJparams,
  };
};
