/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import format from 'date-fns/format';

import { Link } from 'react-router-dom';

import ImageComponent from '@rambler-components/image';

import { ICardProps } from 'Card';

import { DATE_FORMAT } from 'config/constants/calendar';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.isBot,
  ],
  (
    isMobile,
    isBot,
  ) => ({
    isMobile,
    isBot,
  }),
);
const MAX_PLACEHOLDERS_COUNT = 4;

interface ICardNamesPersons {
  celebrities: NonNullable<ICardProps['celebrities']>
  className?: string
}

function NamesPersons({
  celebrities,
  className,
}: ICardNamesPersons) {
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    isMobile,
    isBot,
  } = useSelector(selectData);

  const [currentCut, setCurrentCut] = useState(2);

  const listArr = useMemo(
    () => {
      if (!celebrities.link && currentCut === 0) return celebrities.list;

      return celebrities.link
        ? celebrities.list.slice(0, 4)
        : celebrities.list.slice(0, currentCut);
    },
    [celebrities, currentCut],
  );

  const getImageUrl = useCallback((image: string | undefined, index: number) => {
    if (image) return image;

    const neededIndex = index < MAX_PLACEHOLDERS_COUNT
      ? index
      : index % MAX_PLACEHOLDERS_COUNT;

    return `https://store.rambler.ru/horoscopes-prod/ts/names/${neededIndex + 1}.png`;
  }, []);

  const formatDateLink = (link: string) => {
    if (/\/names\/calendar\/\d+-\d+-\d+/gi.test(link)) {
      const unformattedDate = link.match(/\d+-\d+-\d+[a-zA-Z0-9:]+/gi);
      if (unformattedDate && unformattedDate[0]) {
        const formattedDate = format(new Date(unformattedDate[0]), DATE_FORMAT);
        return link.replace(/\d+-\d+-\d+[a-zA-Z0-9:]+/gi, formattedDate);
      }
    }

    return link;
  };

  const getShortcutText = () => {
    const listDiff = celebrities.list.length - listArr.length;

    return listDiff === 1
      ? 'Ещё 1 известный человек'
      : `Ещё ${listDiff} известных людей`;
  };

  if (!celebrities || !listArr.length) return null;

  return (
    <div className={cn(
      s.root,
      isMobile ? s.rootMobile : s.rootDesktop,
      className,
    )}
    >
      {celebrities.title && (
        <h3 className={cn(
          s.title,
          isMobile ? s.titleMobile : s.titleDesktop,
        )}
        >
          {celebrities.title}
        </h3>
      )}
      {listArr.map((person, index) => {
        const key = `NamesPersons-${index}`;

        return (
          <div
            className={cn(
              s.person,
              isMobile ? s.personMobile : s.personDesktop,
              !isMobile && celebrities.title && s.personDesktopWithTopTitle,
            )}
            key={key}
          >
            <div className={s.image}>
              <ImageComponent
                className={cn(
                  s.img,
                  person.image && s.imgPerson,
                )}
                isImg={isBot}
                src={getImageUrl(person.image, index)}
                alt={`${person.name}. ${person.description}`}
                isLazy
                isS3
              />
            </div>
            <div className={s.top}>
              <Typography variant="h3" component="h4" className={s.name}>{person.name}</Typography>
              <Typography variant="defaultMedium" className={s.years}>{person.years}</Typography>
            </div>
            <Typography variant="defaultMedium" className={s.bottom}>{person.description}</Typography>
          </div>
        );
      })}
      {celebrities.link ? (
        <Typography
          variant="defaultMedium"
          className={cn(
            s.action,
            isMobile ? s.actionMobile : s.actionDesktop,
          )}
          element={(
            <Link
              to={formatDateLink(celebrities.link.link)}
              {...getTop100Markup(isMobile, top100Prefix, 'celebrities::more_people_born')}
            />
          )}
        >
          {celebrities.link.title}
        </Typography>
      ) : null}
      {!celebrities.link && currentCut > 0 ? (
        <Typography
          variant="defaultMedium"
          className={cn(
            s.action,
            isMobile ? s.actionMobile : s.actionDesktop,
          )}
          element={<span onClick={() => setCurrentCut(0)} />}
        >
          {getShortcutText()}
          <Icon id="arrow-down" className={s.icon} />
        </Typography>
      ) : null}
    </div>
  );
}

NamesPersons.defaultProps = {
  className: '',
};

export { NamesPersons };
