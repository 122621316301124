import React from 'react';

import { ICardProps } from 'Card';

import { safeGet } from 'utils/safeGet';
import { CLUSTER_TYPE } from 'config/constants/cluster';

import { Carousel } from 'common/components/Carousel';

import { ClusterVideo } from './components/ClusterVideo';
import { ClusterImage } from './components/ClusterImage';

type PropsType = {
  cluster: ATCluster
  isLazy: ICardProps['isLazy']
  isPremium?: boolean
};

/**
 * Картинка/галерея/видео, в зависимости от типа кластера
 *
 * @param cluster
 * @param runtime
 * @param isLazy - Lazy load
 */
export function ClusterMediaSection({
  cluster,
  isLazy,
  isPremium,
}: PropsType) {
  if (
    cluster.cluster_type === CLUSTER_TYPE.video
      && safeGet(() => cluster.main_video!.embed_code)
  ) {
    return (
      <ClusterVideo cluster={cluster} />
    );
  }

  if (cluster.cluster_type === CLUSTER_TYPE.photo) {
    return (
      <Carousel items={cluster?.gallery || []} />
    );
  }

  if (cluster.image) {
    return (
      <ClusterImage
        cluster={cluster}
        isLazy={isLazy}
        isPremium={isPremium}
      />
    );
  }
  return null;
}

ClusterMediaSection.defaultProps = {
  isPremium: false,
};
