import React, { useState, useMemo } from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import {
  Category, synonymsByCategory, tarotList, urlsByCategory,
} from 'config/constants/tarot';

import { UserForm } from './UserForm';
import { CompatibilityForm } from './CompatibilityForm';
import { SearchForm } from './SearchForm';
import { DateByDevice } from './DateByDevice';
import { DateSwitchForm } from './components/DateSwitchForm';
import { DashboardByDate } from './DashboardByDate';
import { NamesCategoriesForm } from './NamesCategoriesForm';

import { DateByDeviceContext } from './contexts/dateByDeviceContext';

import s from './styles.css';

export type Size = 'normal' | 'small' | 'big' | 'full';

interface ICardFormProps {
  form: ICardProps['form']
  isMobile: boolean
  className?: string
  link?: ICardProps['link']
}

const onSuggestActionTarot = (query: string) => {
  const lowerCaseQuery = query.toLowerCase();
  const isOneLetter = lowerCaseQuery.length === 1;

  const allCards = tarotList.reduce((acc, category) => {
    // Для старших арканов добавляем синонимы через запятую тк их немного
    if (category.slug === 'starshie-arkany') {
      const cards = category.cards.map(card => {
        const synonyms = card.synonyms?.join(', ');

        return ({
          ...card,
          name: synonyms ? [card.name, synonyms].join(', ') : card.name,
        });
      });

      acc.push(...cards);
      return acc;
    }

    acc.push(...category.cards);
    return acc;
  }, [] as typeof tarotList[0]['cards']);

  const filterCards = allCards.filter(({ name, synonyms }) => {
    // Если пользователь ввел одну букву то показываем те карты, что начинаются на эту букву
    if (isOneLetter) {
      const isNameStartsWithQuery = name
        .toLowerCase()
        .startsWith(lowerCaseQuery);

      return isNameStartsWithQuery;
    }

    const hasQuery = name.toLowerCase().includes(lowerCaseQuery);
    const hasSynonyms = synonyms?.some(synonym => synonym.toLowerCase().includes(lowerCaseQuery));

    return hasQuery || hasSynonyms;
  });

  // Сначала идут саджесты которые начинаются на введеную строку, потом остальные
  // например при вводе "ту" сначала идут тузы, в конце колесо фортуны
  const sortedCards = [...filterCards].sort((a, b) => {
    const aIndex = a.name.toLowerCase().indexOf(lowerCaseQuery);
    const bIndex = b.name.toLowerCase().indexOf(lowerCaseQuery);
    return (aIndex > -1 && bIndex > -1 && (aIndex - bIndex)) || -1;
  });

  const suggests = sortedCards.slice(0, 10).map(item => ({
    ind:   item.id,
    url:   `/taro/znachenie-kart/${item.slug}/`,
    descr: item.name,
    sug:   item.name,
  }));

  const sameCategoryKey = Object.keys(synonymsByCategory)
    .find(key => synonymsByCategory[Number(key) as Category]
      .some(syn => syn.includes(lowerCaseQuery)));

  // Если пользователь вводит категорию, то вставляем саджест синонимов к категории
  if (sameCategoryKey !== undefined && !isOneLetter) {
    const synonyms = synonymsByCategory[Number(sameCategoryKey) as Category];
    const synonymsShort = synonyms.length > 5
      ? [synonyms.slice(0, 2).join(', '), '...', synonyms.slice(-2).join(', ')].join(', ')
      : synonyms.join(', ');

    suggests.unshift({
      ind:   '0',
      url:   urlsByCategory[Number(sameCategoryKey) as Category],
      descr: `Масть ${synonyms.join(', ')}`,
      sug:   `Масть ${synonymsShort}`,
    });
  }

  return {
    query,
    suggests,
  } as any;
};

export const getSizeStyle = (isMobile: boolean, size: Size) => {
  if (isMobile) {
    return '';
  }

  switch (size) {
    case 'small':
      return s.sizeSmall;
    case 'normal':
      return s.sizeNormal;
    case 'big':
      return s.sizeBig;
    case 'full':
      return s.sizeFull;
    default:
      return s.sizeNormal;
  }
};

export const getTop100 = (
  isMobile: boolean,
  top100Prefix: string,
  form: ICardProps['form'],
  tail: string,
) => getTop100Markup(isMobile, top100Prefix, `form::${form}::${tail}`);

function Form({
  form,
  link,
  isMobile,
  className,
}: ICardFormProps) {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const dashboardMaxYear = new Date().getFullYear() - 5;

  const formByType: Record<NonNullable<ICardProps['form']>, JSX.Element | null> = useMemo(() => ({
    user_date_of_birth: <UserForm
      form="user_date_of_birth"
      link={link}
    />,
    user_date_gender: <UserForm
      form="user_date_gender"
      link={link}
    />,
    horoscope_date_common: <UserForm
      form="horoscope_date_common"
      link={link}
    />,
    compatibility:         <CompatibilityForm form="compatibility" />,
    compatibility_chinese: <CompatibilityForm form="compatibility_chinese" />,
    search_dreams:         <SearchForm form="search_dreams" />,
    names_search:          <SearchForm form="names_search" />,
    search_primety:        <SearchForm form="search_primety" />,
    search_taro:           (
      <SearchForm form="search_taro" onSuggestAction={onSuggestActionTarot} showSearchButton />
    ),
    sign_for_date: <UserForm
      form="sign_for_date"
      link={link}
    />,
    moon_by_date: <UserForm
      form="moon_by_date"
    />,
    moon_day_dropdown: <UserForm
      form="moon_day_dropdown"
    />,
    sun_by_date: <UserForm
      form="sun_by_date"
    />,
    chinese_sign_for_date: <DateByDevice
      form="chinese_sign_for_date"
      isMobile={isMobile}
      minYear={1900}
      maxYear={2034}
    />,
    dashboard_personal: <DateByDevice
      form="dashboard_personal"
      isMobile={isMobile}
      minYear={1910}
      maxYear={dashboardMaxYear}
      maxDate={`${dashboardMaxYear}-01-01`}
    />,
    date_switch_form:  <DateSwitchForm />,
    dashboard_by_date: <DashboardByDate
      form="dashboard_personal"
      isMobile={isMobile}
    />,
    names_categories: <NamesCategoriesForm
      isMobile={isMobile}
    />,
    human_design_form:   null,
    personal_taro_today: <UserForm
      form="personal_taro_today"
    />,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const contextMemo = useMemo(() => ({ isButtonClicked, setIsButtonClicked }), [isButtonClicked]);

  if (!form || !formByType[form]) return null;

  return (
    <DateByDeviceContext.Provider value={contextMemo}>
      <div
        className={cn(
          s.form,
          isMobile ? s.formMobile : s.formDesktop,
          form === 'dashboard_by_date' && s.formDashboardByDate,
          form === 'date_switch_form' && s.formDateSwitchForm,
          [
            'search_dreams',
            'names_search',
            'search_primety',
            'search_taro',
          ].includes(form) && s.formSearch,
          className,
        )}
      >
        {formByType[form]}
      </div>
    </DateByDeviceContext.Provider>
  );
}

Form.defaultProps = {
  className: '',
  link:      undefined,
};

export { Form };
