import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import NukaCarousel, { ControlProps } from 'nuka-carousel';

import { Item } from './Item';
import { Control, CarouselDirection } from './Controls';

import s from './styles.css';

interface ICarousel {
  items: ClusterGallerySlideType[];
  className?: string
}

function CarouselComponent({ items, className }: ICarousel) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const controlOnClick = (nextIndex: number) => {
    setActiveIndex(nextIndex);
  };

  const renderNextControl = (controlProps: ControlProps) => {
    const { nextDisabled, currentSlide } = controlProps;

    if (isMobile) {
      return null;
    }

    return (
      <Control
        activeIndex={activeIndex}
        disabled={nextDisabled}
        type={CarouselDirection.next}
        onClick={() => !nextDisabled && controlOnClick(currentSlide + 1)}
      />
    );
  };

  const renderPrevControl = (controlProps: ControlProps) => {
    const { previousDisabled, currentSlide } = controlProps;

    if (isMobile) {
      return null;
    }

    return (
      <Control
        activeIndex={activeIndex}
        disabled={previousDisabled}
        type={CarouselDirection.prev}
        onClick={() => !previousDisabled && controlOnClick(currentSlide - 1)}
      />
    );
  };

  const carouselProps = {
    slideIndex:                activeIndex,
    dragging:                  false,
    enableKeyboardControls:    true,
    speed:                     200,
    withoutControls:           isMobile,
    disableEdgeSwiping:        true,
    renderCenterLeftControls:  renderPrevControl,
    renderCenterRightControls: renderNextControl,
    defaultControlsConfig:     {
      pagingDotsClassName:          s.dot,
      pagingDotsContainerClassName: s.dotsContainer,
      pagingDotsStyle:              { backgroundColor: '#c6cad8', opacity: 1 },
    },
  };

  return (
    <div className={cn(s.root, isMobile && s.mobile, className)}>
      <NukaCarousel {...carouselProps}>
        {items.map((item, index) => (
          <Item
            key={item.id}
            item={item}
            positionCount={index + 1}
            allItemsCount={items.length}
          />
        ))}
      </NukaCarousel>
    </div>
  );
}

CarouselComponent.defaultProps = {
  className: '',
};

export const Carousel = memo(CarouselComponent);
