import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ICardProps } from 'Card';

import {
  getMoonType,
  MoonPhases,
} from 'config/constants/moon';
import signNames from 'config/constants/signNames';

import { Icon } from 'common/components/Icon';
import { Specs } from 'common/components/Card/Specs';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './index.css';

interface Props {
  data: ICardProps['highlighted']
  isMobile: boolean
  className?: string
}

function Highlights({
  data,
  isMobile,
  className,
}: Props) {
  const {
    top100Prefix,
  } = useTop100Context();
  const [isOpened, setIsOpened] = useState(false);

  if (!data || (!data.list || !data.list.length)) return null;

  const {
    list,
    title,
    shortcut,
  } = data;

  const newList = isOpened ? list : list.slice(0, shortcut);

  const onHighlightClick = (anchor: ICardProps['anchor']) => {
    if (!anchor) return;

    const elem = document.getElementById(`anchor-${anchor}`);
    if (elem) {
      window.scrollTo({
        top:      elem.getBoundingClientRect().top + window.scrollY,
        behavior: 'smooth',
      });
    }
  };

  const renderSigns = (signs: (keyof typeof SIGN)[]) => {
    if (signs.length === 1) {
      return (
        <div className={s.signs}>
          <Icon id={signs[0]} className={s.sign} />
          {' '}
          <span className={s.signTitle}>{signNames.zodiac[signs[0]]}</span>
        </div>
      );
    }

    return (
      <div className={s.signs}>
        <Icon id={signs[0]} className={s.sign} />
        <Icon id="arrow-forward" className={s.arrow} />
        <Icon id={signs[1]} className={s.sign} />
      </div>
    );
  };

  return (
    <div
      className={cn(
        s.root,
        isMobile && s.rootMobile,
        className,
      )}
    >
      {title && (
        <Typography
          variant="h2"
          component="h2"
          className={cn(
            s.title,
            isMobile ? s.titleMobile : s.titleDesktop,
          )}
        >
          {title}
        </Typography>
      )}
      {newList.map(({
        icon, items, link, anchor, signs, title: highlightTitle,
      }, index) => {
        // todo: fix type
        const Element: any = link ? Link : 'div';
        let iconTitle = null;
        let iconName = icon;

        if (icon) {
          const isMoonDay = icon.includes('moon_day_');
          const isMoonPhase = icon.includes('_moon');

          if (isMoonDay || isMoonPhase) {
            const moonDay = parseInt(
              isMoonDay
                ? icon.replace('moon_day_', '')
                : MoonPhases[icon as unknown as MoonPhases],
              10,
            );

            const phase = getMoonType(moonDay);
            iconTitle = `Фаза луны - ${phase}`;
            iconName = `moon_day_${moonDay}`;
          }
        }

        const key = `${iconName}-${index}`;

        return (
          <Typography
            variant="defaultMedium"
            key={key}
            className={cn(
              s.highlight,
              link && s.highlightLink,
              anchor && s.highlightWithAnchor,
              s[iconName],
            )}
            element={(
              <Element
                to={link}
                onClick={() => onHighlightClick(anchor)}
                {...link ? getTop100Markup(isMobile, top100Prefix, `highlighted::${link}`) : {}}
              />
            )}
          >
            <span className={s.highlightTitleWrapper}>
              {iconName && <Icon id={iconName} className={s.highlightIcon} title={iconTitle} />}
              {highlightTitle && (
                <span className={cn(s.highlightTitle, isMobile && s.highlightTitleMobile)}>
                  {highlightTitle}
                </span>
              )}
              {signs && signs.length && renderSigns(signs)}
            </span>

            <Specs
              specs={items.map(item => ({ key: item.name, value: [item.text] }))}
              wrapperClassName={s.specs}
              isMobile={isMobile}
              clickableTextClassName={s.text}
            />
          </Typography>
        );
      })}
      {shortcut && (
        <Typography
          variant="defaultRegular"
          className={cn(
            s.more,
            isOpened && s.moreOpened,
          )}
          element={(
            <button
              type="button"
              onClick={() => setIsOpened(!isOpened)}
              {...getTop100Markup(isMobile, top100Prefix, `highlighted::${isOpened ? 'hide' : 'show'}`)}
            />
          )}
        >
          {isOpened ? 'Скрыть' : 'Читать далее'}
          <Icon id="arrow-down" className={s.icon} />
        </Typography>
      )}
    </div>
  );
}

Highlights.defaultProps = {
  className: '',
};

export { Highlights };
