import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { ICardProps } from 'Card';

import { YANDEX_METRICS } from 'config/constants/counters';
import YandexEvent from 'utils/counters/YandexEvent';

import { Title } from 'common/components/Card/Title';
import { Icon } from 'common/components/Icon';
import { Likes } from 'common/components/Card/Likes';
import { CoffeeSteam } from 'common/components/Card/@Fortune/Coffee/CoffeeSteam';
import { Typography } from 'common/components/Typography';

import { safeGet } from 'utils/safeGet';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import rubricStyles from 'common/components/Card/@Longread/ListItem/components/ListRubric/styles.css';
import cardStyles from 'common/components/Card/styles.css';
import s from './styles.css';
import { TarotPromo } from './TarotPromo';

interface IPromoCardProps {
  isMobile: boolean
  isAnimated: boolean
  id: ICardProps['id']
  title: ICardProps['title']
  likes: ICardProps['likes']
  topic: TopicType
  link: ICardProps['link']
}

const TAROT_STYLES_BY_LINK: {[key: string]: string} = {
  '/gadanie-online/taro-keltskiy-krest/': s.linkTarot,
  '/taro/data-rozhdeniya/':               s.linkTarotByBirthdate,
  '/taro/lyubov/':                        s.linkTarotLove,
  '/taro/budushchee/':                    s.linkTarotFuture,
  '/taro/da-net/':                        s.linkTarotShort,
};

function PromoCard({
  isMobile,
  isAnimated,
  id,
  title,
  likes,
  topic,
  link = { link: '' },
}: IPromoCardProps) {
  const isCardAnimated = isAnimated || true;
  const {
    top100Prefix,
  } = useTop100Context();

  const isTarot = Boolean(TAROT_STYLES_BY_LINK[link.link]);
  const isRetrograde = link.link.includes('retrograde-mercury');
  const isRunes = link.link === '/gadanie-online/gadanie-na-runah/';
  const isChronos = link.link === '/chronos/';
  const isCoffee = !isTarot
    && !isChronos
    && !isRetrograde
    && !isRunes;

  const onClickChronos = () => {
    (new YandexEvent(YANDEX_METRICS.COMMON)).send({
      type: 'reachGoal',
      data: 'to_chronos_link_click',
    });
  };

  const cardTitle = (
    <Title
      title={title}
      isMobile={isMobile}
      type="longread"
      isLink={false}
      wrapperClassName={cn(
        isMobile ? s.titleMobile : s.titleDesktop,
        !isChronos && isMobile && s.titleGame,
        s.upper,
      )}
      key={`PromoCard-${id}-title`}
    />
  );

  // todo: fix type
  const RubricComponent: any = topic
    ? Link
    : 'span';

  const cardPromo = isChronos
    ? (
      <Typography
        variant="defaultMedium"
        className={cn(
          s.promo,
          s.upper,
          isMobile ? s.promoMobile : s.promoDesktop,
        )}
        key={`PromoCard-${id}-partner`}
      >
        Партнерский материал
      </Typography>
    )
    : (
      <div
        className={cn(
          s.upper,
          cardStyles.tags,
          rubricStyles.rubrics,
          isMobile ? rubricStyles.rubricsMobile : rubricStyles.rubricsDesktop,
        )}
        key={`PromoCard-${id}-rubric`}
      >
        <Typography
          variant="defaultMedium"
          className={rubricStyles.rubric}
          element={(
            <RubricComponent
              to={safeGet(() => `/${topic.alias}/`, '')}
              {...topic ? getTop100Markup(isMobile, top100Prefix, `promo_card::${topic.alias}`) : {}}
            />
          )}
        >
          {isRetrograde ? 'Спецпроект' : 'Гадания'}
        </Typography>
      </div>
    );

  const url = 'https://learn.chronos.ru/rambler';

  let cardLink = null;
  if (isChronos) {
    cardLink = (
      /* eslint-disable-next-line jsx-a11y/anchor-has-content */
      <a
        className={cn(
          s.link,
          isMobile && s.linkMobile,
        )}
        href={url}
        target="_blank"
        rel="noopener noreferrer nofollow"
        onClick={onClickChronos}
        {...getTop100Markup(isMobile, top100Prefix, `promo_card::${url}`)}
      />
    );
  } else if (isRetrograde) {
    cardLink = (
      /* eslint-disable-next-line jsx-a11y/anchor-has-content */
      <a
        className={cn(
          s.link,
          s.linkRetrograde,
          isMobile && s.linkMobile,
        )}
        href={link.link}
        {...getTop100Markup(isMobile, top100Prefix, `promo_card::${link.link}`)}
      />
    );
  } else {
    cardLink = (
      <Link
        className={cn(
          s.link,
          TAROT_STYLES_BY_LINK[link.link],
          isCoffee && s.linkCoffee,
          isRunes && s.linkRunes,
          isMobile && s.linkMobile,
          isCardAnimated && s.linkAnimated,
        )}
        to={link.link}
        {...getTop100Markup(isMobile, top100Prefix, `promo_card::${link.link}`)}
      >
        {isTarot && isCardAnimated && <TarotPromo isMobile={isMobile} link={link} />}
        {isRunes && isCardAnimated && (
          <>
            <div className={cn(s.rune, s.fehu, isMobile ? s.fehuMobile : s.fehuDesktop)} />
            <div className={cn(s.rune, s.kaunan, isMobile ? s.kaunanMobile : s.kaunanDesktop)} />
            <div className={cn(s.rune, s.opala, isMobile ? s.opalaMobile : s.opalaDesktop)} />
            <div className={cn(s.rune, s.perb, isMobile ? s.perbMobile : s.perbDesktop)} />
            <div className={cn(s.rune, s.sowilo, isMobile ? s.sowiloMobile : s.sowiloDesktop)} />
          </>
        )}
      </Link>
    );
  }

  return (
    <div className={cn(
      s.root,
      isMobile ? s.rootMobile : s.rootDesktop,
      isTarot && s.rootTarot,
    )}
    >
      {isChronos && (<Icon id="chronos" className={s.chronos} />)}
      {
        isMobile
          ? [cardTitle, cardPromo]
          : [cardPromo, cardTitle]
      }
      {!isChronos && !isRetrograde && (
        <Likes
          likes={likes}
          isFullImageCard
          className={s.upper}
        />
      )}
      {!isMobile && (
        <div
          className={cn(
            cardStyles.pseudoFooter,
            cardStyles.pseudoFooterLongreadList,
            isMobile ? cardStyles.pseudoFooterMobile : cardStyles.pseudoFooterDesktop,
          )}
        />
      )}
      {!isChronos && !isRetrograde && (
        <Typography variant="defaultMedium" component="div" className={s.game}>Игра</Typography>
      )}
      {isCoffee && (
        <CoffeeSteam
          className={s.steam}
          width={365}
          height={400}
        />
      )}
      {cardLink}
    </div>
  );
}

export { PromoCard };
