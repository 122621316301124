import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { ITile } from 'Card';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { Typography } from 'common/components/Typography';

import s from './index.css';

interface ICardTileProps {
  tile: ITile
  isMobile: boolean
}

function Tile({
  tile,
  isMobile,
}: ICardTileProps) {
  const {
    top100Prefix,
  } = useTop100Context();

  const { link } = tile;
  const urlObject: {
    to?: string
    href?: string
  } = {};

  urlObject[link ? 'to' : 'href'] = link
    ? link.link
    : 'mailto:horobook@rambler.ru';

  const Tag: any = link
    ? Link
    : 'a';

  return (
    <Tag
      className={cn(
        s.root,
        isMobile && s.rootFullWidth,
      )}
      {...urlObject}
      style={
        tile.image
          ? {
            backgroundImage: `url(${tile.image.url})`,
          }
          : {}
      }
      {...getTop100Markup(isMobile, top100Prefix, `author::${tile.title}`)}
    >
      <Typography variant="h2" component="span" className={s.title}>{tile.title}</Typography>
      <Typography
        variant="defaultMedium"
        className={s.subtitle}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: tile.subtitle }} />
      </Typography>
    </Tag>
  );
}

export { Tile };
