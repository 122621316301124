/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import cn from 'classnames';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import Button from '@rambler-components/button';

import { ICardProps } from 'Card';

import {
  GENDER,
  GENDER_CLASSIC,
} from 'config/constants/gender';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { isInHorizontalViewport } from 'common/utils/isInViewport';

import s from './styles.css';

interface ICardNamesByParams {
  namesByParams: NonNullable<ICardProps['tags_with_names']>
  isMobile: boolean
  className?: string
}

function NamesByParams({
  namesByParams,
  isMobile,
  className,
}: ICardNamesByParams) {
  const {
    top100Prefix,
  } = useTop100Context();
  const history = useHistory();
  const tagsList = useRef(namesByParams.tags.reduce((obj, tag) => ([
    ...obj,
    {
      slug:  tag.slug,
      title: tag.title,
      link:  tag.link,
    },
  ]), []));
  const [currentNavTag, setCurrentNavTag] = useState(
    tagsList.current.find(tag => tag.slug === namesByParams.current_tag_slug)!,
  );
  const [activeIndex, setActiveIndex] = useState(tagsList.current.indexOf(currentNavTag));
  const [currentTag, setCurrentTag] = useState(namesByParams.tags[activeIndex]);
  const [arrowsState, setArrowsState] = useState({
    left:  false,
    right: true,
  });
  const navListRef = useRef<HTMLDivElement>(null);

  const moveNav = useCallback((index: number = 0, withSmooth: boolean = false) => {
    if (navListRef.current) {
      const { needScrollToViewLeft } = isInHorizontalViewport(
        navListRef.current,
        // todo: fix
        // @ts-ignore
        navListRef.current.childNodes[index],
      );

      navListRef.current.scrollTo({
        left:     needScrollToViewLeft - navListRef.current.offsetLeft,
        behavior: withSmooth ? 'smooth' : 'auto',
      });
    }
  }, []);

  const onClickButton = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();

    history.push(currentNavTag.link);
  }, [currentNavTag]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = useCallback((shift: number) => {
    let newIndex = 0;
    const nextIndex = activeIndex + shift;
    const maxIndex = tagsList.current.length - 1;

    if (shift < 0) {
      newIndex = nextIndex <= 0 ? 0 : nextIndex;
    } else {
      newIndex = maxIndex > nextIndex ? nextIndex : maxIndex;
    }

    setActiveIndex(newIndex);
    setArrowsState({
      left:  newIndex !== 0,
      right: ![newIndex, newIndex + 1].includes(maxIndex),
    });
    moveNav(newIndex, true);
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeIndex) {
      moveNav(activeIndex, false);
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  // todo: у ListBubbles, NamesByParams и NamesFatherNames много общего. Переработать

  return (
    <div className={cn(s.root, className)}>
      <div className={s.nav}>
        {!isMobile && (
          <Icon
            className={cn(
              s.icon,
              s.iconLeft,
              !arrowsState.left && s.iconDisabled,
            )}
            id="arrow-next"
            onClick={() => arrowsState.left && handleClick(-2)}
            {...getTop100Markup(isMobile, top100Prefix, 'selection_table::selection_change')}
          />
        )}
        <div
          className={cn(
            s.navList,
            isMobile ? s.navListMobile : s.navListDesktop,
          )}
          ref={navListRef}
        >
          {tagsList.current.map((tag, index) => (
            <Link
              to={tag.link}
              className={cn(
                s.tab,
                currentNavTag.slug === tag.slug && s.tabActive,
              )}
              onClick={e => {
                e.preventDefault();
                if (currentNavTag.slug !== tag.slug) {
                  setCurrentTag(namesByParams.tags[index]);
                  setCurrentNavTag(tagsList.current[index]);
                  handleClick(index - activeIndex);
                }
              }}
              key={`NamesByParams-tag-${tag.slug}`}
              {...getTop100Markup(isMobile, top100Prefix, `lifehack_block::rubricator::${tag.slug}`)}
            >
              {tag.title}
            </Link>
          ))}
        </div>
        {!isMobile && (
          <Icon
            className={cn(
              s.icon,
              s.iconRight,
              !arrowsState.right && s.iconDisabled,
            )}
            id="arrow-next"
            onClick={() => arrowsState.right && handleClick(2)}
            {...getTop100Markup(isMobile, top100Prefix, 'selection_table::selection_change')}
          />
        )}
      </div>
      {!isMobile && (
        <div className={s.head}>
          <Typography variant="defaultMedium" className={s.title}>Имя</Typography>
          <Typography variant="defaultMedium" className={s.title}>Значение</Typography>
          <Typography variant="defaultMedium" className={s.title}>Качество</Typography>
        </div>
      )}
      <div className={cn(
        s.list,
        isMobile ? s.listMobile : s.listDesktop,
      )}
      >
        <div className={s.item}>
          {currentTag.names.map(name => (
            <Link
              to={name.link}
              className={s.name}
              key={name.link}
              {...getTop100Markup(isMobile, top100Prefix, 'selection_table::choice_of_name')}
            >
              <div className={s.cell}>
                <Icon
                  id={name.gender === GENDER.male ? GENDER_CLASSIC.man : GENDER_CLASSIC.woman}
                  className={cn(s.icon, s[name.gender])}
                />
                <Typography
                  variant="defaultMedium"
                  className={cn(s.value, s.valueName)}
                >
                  {name.name}
                </Typography>
              </div>
              <div className={s.cell}>
                {isMobile && (
                  <Typography
                    variant="defaultMedium"
                    className={s.title}
                  >
                    Значение
                  </Typography>
                )}
                <Typography variant="defaultMedium" className={s.value}>
                  {name.meaning}
                </Typography>
              </div>
              <div className={s.cell}>
                {isMobile && (
                  <Typography
                    variant="defaultMedium"
                    className={s.title}
                  >
                    Качество
                  </Typography>
                )}
                <Typography variant="defaultMedium" className={s.value}>
                  {name.character}
                </Typography>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {currentNavTag.link && (
        <Button
          href={currentNavTag.link}
          className={cn(
            s.more,
            isMobile ? s.moreMobile : s.moreDesktop,
          )}
          onClick={e => onClickButton(e)}
          {...getTop100Markup(isMobile, top100Prefix, `selection_table::watch_all_selection::${currentNavTag.link}`)}
        >
          Смотреть всю подборку
        </Button>
      )}
    </div>
  );
}

NamesByParams.defaultProps = {
  className: '',
};

export { NamesByParams };
