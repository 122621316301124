import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Button from '@rambler-components/button';

import { ICardProps } from 'Card';

import { Link } from 'react-router-dom';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.currentParams.longreadTopic,
  ],
  (
    isMobile,
    currentPage,
    currentSection,
    longreadTopic,
  ) => ({
    isMobile,
    currentPage,
    currentSection,
    longreadTopic,
  }),
);

interface ICardLongreadTopicsListProps {
  data: ICardProps['longreadTopicList']
  className?: string
}

function LongreadTopicsList({
  data,
  className,
}: ICardLongreadTopicsListProps) {
  const {
    isMobile,
    currentPage,
    currentSection,
    longreadTopic,
  } = useSelector(selectData);
  const [isOpened, setIsOpened] = useState(false);

  if (!data || !data.length) return null;

  const isActiveMain = currentPage === 'articles' && currentSection === 'list';

  return (
    <div
      className={cn(
        s.topics,
        isMobile ? s.topicsMobile : s.topicsDesktop,
        isOpened && s.topicsOpened,
        className,
      )}
    >
      <div className={s.list}>
        <Typography
          variant="defaultMedium"
          className={cn(
            s.link,
            isActiveMain && s.linkActive,
          )}
          element={<Link to="/longread/" />}
        >
          Все статьи
        </Typography>
        {data.map(item => {
          const link = `/${item.slug}/`;
          const isActiveTopic = !isActiveMain
            && currentPage === 'articles'
            && currentSection === 'topic'
            && (longreadTopic === item.slug);

          return (
            <Typography
              variant="defaultMedium"
              className={cn(
                s.link,
                isActiveTopic && s.linkActive,
              )}
              key={`${item.id}-${item.slug}`}
              element={<Link to={link} />}
            >
              {item.title}
            </Typography>
          );
        })}
      </div>
      <Button
        type="secondary"
        className={cn(
          s.button,
          isOpened && s.buttonOpened,
        )}
        icon={<Icon id="arrow-down" className={s.icon} />}
        onClick={() => setIsOpened(!isOpened)}
      />
    </div>
  );
}

LongreadTopicsList.defaultProps = {
  className: '',
};

export { LongreadTopicsList };
