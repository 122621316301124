import React from 'react';
import cn from 'classnames';

import { Title, ICardTitleProps } from '../Title/index';
import { ICardSocialLinksProps, SocialLinks } from '../SocialLinks';

import s from './index.css';

interface Props {
  titleProps: ICardTitleProps
  socialProps: ICardSocialLinksProps
  isMobile: boolean
  className?: string
}

function CardHeader({
  titleProps,
  socialProps,
  isMobile,
  className,
}: Props) {
  const socialsCount = Object.keys(socialProps.links || {}).length;

  return (
    <div
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        (!socialsCount || socialsCount > 2) && s.rootBlock,
        className,
      )}
    >
      <div className={cn(
        socialsCount && s.pageInfo,
      )}
      >
        <Title
          {...titleProps}
          wrapperClassName={s.title}
        />
      </div>
      {socialsCount
        ? (
          <SocialLinks
            {...socialProps}
            className={s.socials}
            isMobile={isMobile}
          />
        )
        : null}
    </div>
  );
}

CardHeader.defaultProps = {
  className: '',
};

export { CardHeader };
