import React from 'react';
import cn from 'classnames';
import _pick from 'lodash.pick';

import { Link } from 'react-router-dom';

import { ICardProps, IHighlight } from 'Card';

import signSummary from 'config/constants/signSummary';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardSignSummaryProps {
  summary: ICardProps['summary']
  isMobile: boolean
  className?: string
  highlight?: IHighlight['list'][0]
}

function ZodiacSignSummary({
  summary,
  isMobile,
  className,
  highlight,
}: ICardSignSummaryProps) {
  if (!summary) return null;

  const allSummaryKeys = Object.keys(signSummary);
  const currentSummaryKeys = Object.keys(_pick(summary, allSummaryKeys));

  if (!currentSummaryKeys.length) return null;

  return (
    <div
      className={cn(
        s.summary,
        isMobile && s.summaryMobile,
        className,
      )}
    >
      {currentSummaryKeys.map((summaryItem: keyof NonNullable<ICardProps['summary']>) => {
        const isStone = summaryItem === 'stone';
        const sign = highlight?.icon;

        return (
          <Typography
            variant="defaultMedium"
            component="div"
            className={cn(s.summaryItem, isMobile && s.summaryItemMobile)}
            key={summaryItem}
          >
            {isStone && sign
              ? (
                <Link
                  className={cn(s.summaryTitle, s.summaryTitleLink)}
                  to={`/znaki-zodiaka/birthstone/${sign}/`}
                >
                  {signSummary[summaryItem]}
                </Link>
              )
              : (
                <div className={s.summaryTitle}>{signSummary[summaryItem]}</div>
              )}
            <div className={s.summaryText}>{summary[summaryItem].join(', ')}</div>
          </Typography>
        );
      })}
    </div>
  );
}

ZodiacSignSummary.defaultProps = {
  className: '',
  highlight: undefined,
};

export { ZodiacSignSummary };
