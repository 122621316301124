import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { AlertBase } from 'common/components/Alerts/AlertBase';
import { APP_THEME } from 'config/constants';

import s from './index.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.adminData.settings.themes_disabled,
    (state: IAppState) => state.runtime.isBranding,
    (state: IAppState) => state.account.theme,
    (state: IAppState) => state.account.birthday,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (
    isThemesDisabled,
    isBranding,
    theme,
    birthday,
    isMobile,
  ) => ({
    isThemesDisabled,
    isBranding,
    theme,
    birthday,
    isMobile,
  }),
);

function ThemesAlert() {
  const {
    isThemesDisabled,
    isBranding,
    theme,
    birthday,
    isMobile,
  } = useSelector(selectData);
  const [isShown, setIsShown] = useState(false);

  const onAbort = useCallback(() => {
    window.localStorage.setItem('ThemesAlertClosed', 'true');
    setIsShown(false);
  }, []);

  useEffect(() => {
    if (isShown) {
      onAbort();
    }
  }, [theme]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isShown && theme === APP_THEME.Sign) {
      onAbort();
    }
  }, [birthday]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const storageItem = window.localStorage.getItem('ThemesAlertClosed');
    setIsShown(!storageItem || storageItem !== 'true');
  }, []);

  if (!isShown || isThemesDisabled || isBranding) return null;

  return (
    <AlertBase
      className={s.root}
      contentClassName={s.content}
      mainText="Появились темы оформления!<br>Выбирайте любую или попробуйте ввести дату своего рождения"
      onAbort={onAbort}
      abortProps={{ type: 'secondary' }}
      noSubmit
      top100ExtPrefix="themes_alert"
      isMobile={isMobile}
    />
  );
}

export { ThemesAlert };
