import React, { useState } from 'react';
import cn from 'classnames';

import _truncate from 'lodash.truncate';

import { IText } from 'Card';

import { TEXT_RANGE_BEFORE_TOGGLE } from 'config/constants/content';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const IS_SLICED = true;

interface IToggleTextProps {
  text: IText[]
  renderContent: (
    id: number,
    item: IText,
    isSliced: boolean,
    isMainRender: boolean,
    isLastContentElement: boolean,
  ) => JSX.Element | null
  isMobile: boolean
}

function ToggleText({
  text,
  renderContent,
  isMobile,
}: IToggleTextProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const [expanded, setExpanded] = useState(false);

  if (!text) return null;

  const renderToggle = () => (
    <Typography
      variant="defaultRegular"
      className={s.readMore}
      key="text-toggle-text-expand"
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      element={<button type="button" onClick={() => setExpanded(!expanded)} />}
      {...getTop100Markup(isMobile, top100Prefix, `text_expansion::${expanded ? 'hide_text' : 'more_text'}`)}
    >
      {!expanded ? 'Читать продолжение' : 'Свернуть'}
      <Icon id="arrow-down" className={cn(s.readMoreIcon, expanded && s.readMoreIconUp)} />
    </Typography>
  );

  const slicedText = (currentText: string, length: number) => (
    _truncate(currentText, {
      length,
      omission:  '\u00A0…', // использую юникод-символ неразрывного пробела, т.к. &nbsp; выводился текстом
      // eslint-disable-next-line no-useless-escape
      separator: /[\.,!\?…:;\(\-—\s]/,
    })
  );

  let textLength = 0;
  const textArray: (JSX.Element | null)[] = [];

  const textArrLength = text.length;

  text.every((item, innerIndex) => {
    const content = item.content || '';
    textLength += (content.length);
    const isVisible = textLength < TEXT_RANGE_BEFORE_TOGGLE || expanded;

    if (isVisible) {
      textArray.push(renderContent(
        innerIndex,
        item,
        false,
        false,
        innerIndex + 1 === textArrLength,
      ));
    } else {
      const newLength = content.length - (textLength - TEXT_RANGE_BEFORE_TOGGLE);
      const newContent = slicedText(content, newLength);
      const newItem = {
        ...item,
        content: newContent,
      };
      textArray.push(renderContent(
        innerIndex,
        newItem,
        IS_SLICED,
        false,
        innerIndex + 1 === textArrLength,
      ));
    }

    return isVisible;
  });

  if (textLength > TEXT_RANGE_BEFORE_TOGGLE) {
    textArray.push(renderToggle());
  }

  return (<>{textArray.map(item => item)}</>);
}

export { ToggleText };
