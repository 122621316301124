import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Helmet from 'react-helmet';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.fullUrl,
    // (state: IAppState) => state.runtime.top100,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    // (state: IAppState) => state.runtime.currentParams,
    (state: IAppState) => state.meta,
  ],
  (
    fullUrl,
    // top100,
    currentPage,
    currentSection,
    // currentParams,
    meta,
  ) => ({
    fullUrl,
    // top100,
    currentPage,
    currentSection,
    // currentParams,
    meta,
  }),
);

function Head() {
  const {
    fullUrl,
    // top100,
    currentPage,
    currentSection,
    // currentParams,
    meta,
  } = useSelector(selectData);

  const isLongreadDetail = currentPage === 'articles' && currentSection === 'detail';

  const {
    title,
    description,
    keywords,
    og_image:      ogImage,
    twitter_image: twitterImage,
    vk_image:      vkImage,
  } = meta;

  let canonical = fullUrl.replace(/utm_\w+=\w+(&|$)/g, '');

  if (fullUrl.includes('/zodiac/general/all/today/')) {
    canonical = canonical.replace('/zodiac/general/all/today/', '/');
  }

  let isSpecialCard = false;
  if (fullUrl.includes('/taro-keltskiy-krest/')) {
    const newCanonical = canonical.replace(/\d+\/card-\d+\//g, '');
    isSpecialCard = canonical !== newCanonical;
    canonical = newCanonical;
  }

  if (fullUrl.includes('/gadanie-na-runah/')) {
    const newCanonical = canonical.replace(/\d+\/rune-\d+\//g, '');
    isSpecialCard = canonical !== newCanonical;
    canonical = newCanonical;
  }

  let isPresonalDate = false;
  if (/.*\/personal\/[a-z-]*\/\d+-\d+-\d+\//ig.test(fullUrl)) {
    const newCanonical = canonical.replace(/\d+-\d+-\d+\//g, '');
    isPresonalDate = true;
    canonical = newCanonical;
  // "/personal/2024-01-01/" && "/personal/2024-01-01/2022-11-04/"
  } else if (/.*\/personal\/\d+-\d+-\d+\//ig.test(fullUrl)) {
    const newCanonical = canonical.replace(/\d+-\d+-\d+\//g, '');

    canonical = newCanonical;
  }

  const isNoIndex = isSpecialCard || isPresonalDate;

  return (
    <Helmet>
      <title>{title}</title>

      {keywords && keywords.length && (
        <meta name="keywords" content={keywords} />
      )}
      {description && description.length && (
        <meta name="description" content={description} />
      )}

      {/* HORO-4977 */}
      {/* {top100 === 'longread::article' && (
        <link rel="amphtml" href={`${fullUrl}amp/`} />
      )} */}

      <meta property="og:site_name" content="Рамблер/гороскопы" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonical} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ru_RU" />

      {description && description.length && (
        <meta property="og:description" content={description} />
      )}

      {ogImage && ogImage.length && (
        <meta property="og:image" content={ogImage} />
      )}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {vkImage && vkImage.length && (
        <meta property="vk:image" content={vkImage} />
      )}

      <meta name="twitter:title" content={title} />

      {twitterImage && twitterImage.length && (
        <meta name="twitter:image" content={twitterImage} />
      )}

      <meta name="robots" content={!isNoIndex ? 'all' : 'noindex, nofollow'} />
      <meta name="yandex" content={!isNoIndex ? 'all' : 'noindex, nofollow'} />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <link rel="canonical" href={canonical} />

      {isLongreadDetail && <script src="https://horoscopes.rambler.ru/widgets/sdk.js" async />}
    </Helmet>
  );
}

export { Head };
