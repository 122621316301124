import React, { useEffect, useRef } from 'react';

import SteamParticle from './SteamParticle';

interface ICoffeeSteamProps {
  width: number
  height: number
  className?: string
}

function CoffeeSteam({
  width,
  height,
  className,
}: ICoffeeSteamProps) {
  const isActive = useRef(true);

  useEffect(() => {
    const requestAnimationFrame = window.requestAnimationFrame
      || window.mozRequestAnimationFrame
      || window.webkitRequestAnimationFrame
      || window.msRequestAnimationFrame;

    window.requestAnimationFrame = requestAnimationFrame;

    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d')!;

    canvas.height = height;
    canvas.width = width;

    const parts: {
      x: number
      y: number
      size: number
      startSize: number
      endSize: number
      angle: number
      startLife: number
      lifeTime: number
      velY: number
      velX: number
      alpha: number
      update: (maxLifeTime: number) => void
    }[] = [];
    const minSpawnTime = 40;
    const maxLifeTime = Math.min(4000, (canvas.height / (1.5 * 60)) * 1000);
    const emitterX = canvas.width / 2;
    const emitterY = canvas.height - 10;
    const steamImage = new window.Image();
    let lastTime = new Date().getTime();

    const spawn = () => {
      if (new Date().getTime() > lastTime + minSpawnTime) {
        lastTime = new Date().getTime();
        parts.push(new SteamParticle(emitterX, emitterY));
      }
    };

    const renderSteam = () => {
      let len = parts.length;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      while (len) {
        len -= 1;

        if (!isActive.current) break;

        if (parts[len].y < 0 || parts[len].lifeTime > maxLifeTime) {
          parts.splice(len, 1);
        } else {
          parts[len].update(maxLifeTime);

          ctx.save();
          const offsetX = -parts[len].size / 2;
          const offsetY = -parts[len].size / 2;

          ctx.translate(parts[len].x - offsetX, parts[len].y - offsetY);
          ctx.rotate((parts[len].angle / 180) * Math.PI);
          ctx.globalAlpha = parts[len].alpha;
          ctx.drawImage(steamImage, offsetX, offsetY, parts[len].size, parts[len].size);
          ctx.restore();
        }
      }

      if (isActive.current) {
        spawn();
        // @ts-ignore
        requestAnimationFrame(renderSteam.bind(this));
      }
    };

    steamImage.src = '/ts-special/coffee/steam.png';
    // @ts-ignore
    steamImage.onload = renderSteam.bind(this);

    return () => {
      isActive.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <canvas id="canvas" className={className} />
  );
}

CoffeeSteam.defaultProps = {
  className: '',
};

export { CoffeeSteam };
