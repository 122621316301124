import React from 'react';

import { ICardProps } from 'Card';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface ICardClusterRelatedProps {
  related: NonNullable<ICardProps['article']>['related']
  resource: NonNullable<ICardProps['article']>['resource']
  isMobile: boolean
}

function ClusterRelated({
  related,
  resource,
  isMobile,
}: ICardClusterRelatedProps) {
  const {
    top100Prefix,
  } = useTop100Context();

  if (!related) return null;

  const title = resource && resource.title
    ? `${resource.title}: последние новости`
    : 'Последние новости';

  return (
    <div className={s.related}>
      <span className={s.title}>{title}</span>
      {related.map((item, index) => (
        <a
          className={s.link}
          href={`${item.url}?utm_medium=more&utm_source=rhoroscopes`}
          key={item.url}
          rel="noopener noreferrer nofollow"
          {...getTop100Markup(isMobile, top100Prefix, `partner_source_news::${index + 1}`)}
        >
          {item.title}
        </a>
      ))}
    </div>
  );
}

export { ClusterRelated };
