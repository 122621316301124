import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import YandexEvent from 'utils/counters/YandexEvent';
import top100Wrapper from 'utils/counters/top100Wrapper';

import { YANDEX_METRICS } from 'config/constants/counters';
import {
  EMO_SPLIT_AS_IS,
  EMO_SPLIT_TEST_1,
  EMO_SPLIT_TEST_2,
  EMO_SPLIT_TEST_3,
  EMO_SPLIT_NAME,
  EMO_SPLIT_VALUE_NAME,
} from 'config/constants/splits';

import { Cards } from './Variants/Cards';
import { Toggle } from './Variants/Toggle';
import { Text } from './Variants/Text';

import s from './styles.css';

interface IEmoWidget {
  className?: string
}

function EmoWidget({ className }: IEmoWidget) {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);
  const widgetData = useSelector((state: IAppState) => state.emojiWidget.data);
  const currentPath = useSelector((state: IAppState) => state.runtime.path);
  const widgetSplitValue = useSelector((state: IAppState) => state.runtime.splits.emoji_widget
    || EMO_SPLIT_AS_IS);

  const isRender = widgetSplitValue === EMO_SPLIT_AS_IS ? false : Object.keys(widgetData)?.length
  && currentPath === `/${widgetData?.sign}/`;

  useEffect(() => {
    const splitValueName = EMO_SPLIT_VALUE_NAME[widgetSplitValue];
    const splitVersionName = isMobile ? 'mob' : 'web';
    const params = {
      [EMO_SPLIT_NAME]: {
        [splitVersionName]: [splitValueName],
      },
    };

    (new YandexEvent(YANDEX_METRICS.COMMON)).send({
      type: 'params',
      params,
    });

    top100Wrapper.addSplit(`${EMO_SPLIT_NAME}_${splitVersionName}_${splitValueName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRender) {
      (new YandexEvent(YANDEX_METRICS.COMMON)).send({
        type: 'reachGoal',
        data: 'emo_widget_show',
      });
    }
  }, [isRender]);

  if (!isRender) {
    return null;
  }

  const splitName = widgetSplitValue === EMO_SPLIT_TEST_1 ? 'emo_horo_split1' : 'emo_horo_split2';

  return (
    <div className={cn(
      s.root,
      className,
    )}
    >
      {widgetSplitValue === EMO_SPLIT_TEST_1 && (
        <Cards splitName={splitName} widgetData={widgetData} />
      )}
      {widgetSplitValue === EMO_SPLIT_TEST_2 && (
        <Toggle splitName={splitName} widgetData={widgetData} />
      )}
      {widgetSplitValue === EMO_SPLIT_TEST_3 && (
        <Text splitName={splitName} widgetData={widgetData} />
      )}
    </div>
  );
}

EmoWidget.defaultProps = {
  className: '',
};

export { EmoWidget };
