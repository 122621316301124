import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { IBubble } from 'Card';

import { SignSelectWidget } from './SignSelectWidget';
import { BubbleItem } from './BubbleItem';
import { useSelectSign } from './useSelectSign';
import { useHideWidget } from './useHideWidget';
import { AccountModal } from './AccountModal';

import s from './styles.css';

const TOOLTIP_TIMER_DURATION = 5000;

interface ISignBubbles {
  className?: string
  bubbles: IBubble[]
}

export const SignBubbles: React.FC<ISignBubbles> = React.memo(({ bubbles = [], className }) => {
  const currentPath = useSelector((state: IAppState) => state.runtime.path);
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  /**  флаг принудительного показа тултипа после скрытия виджета выбора знака */
  const [widgetHideTooltip, setwidgetHideTooltip] = useState(false);

  /** открытие окна выбора даты рождения в аккаунте */
  const [accountOpen, setAccountOpen] = useState(false);

  useEffect(() => {
    setAccountOpen(false);
  }, [currentPath]);

  const {
    selectSign,
    signChecked,
    isAccountSign,
    onSaveSign,
    onCancelSaveSign,
  } = useSelectSign();

  const {
    hideWidget,
    onHide,
  } = useHideWidget(signChecked, selectSign);

  /** Cтраница конкретного знака */
  const currentSign = bubbles.find(({ sign }) => currentPath.includes(sign || ''));
  /** Страница китайского гороскопа */
  const isChinese = currentPath.includes('/chinese/');
  /** Страница всех знаков */
  const isAllSigns = currentPath.includes('/all/');

  /** Скрытие виджета сохранения пользователем */
  const handleHideWidget = () => {
    onHide();
    setwidgetHideTooltip(true);

    const tooltipTimer = window.setTimeout(() => {
      setwidgetHideTooltip(false);
      window.clearTimeout(tooltipTimer);
    }, TOOLTIP_TIMER_DURATION);
  };

  /** Сохранение знака */
  const handleSaveSign = (sign: IBubble['sign'], name: IBubble['name']) => {
    onSaveSign(sign, name);
    onHide();
  };

  /** Отмена сохранения знака */
  const handleCancelSelectSign = (name: IBubble['name']) => {
    if (isAccountSign) {
      setAccountOpen(true);
      return;
    }

    onCancelSaveSign(name);
    onHide();
  };

  return (
    <div className={cn(s.root, isMobile && s.mobile, className)}>
      {!isAllSigns && !hideWidget && currentSign && (
        <div className={s.selectWidgetCard}>
          <SignSelectWidget
            sign={currentSign.sign}
            name={currentSign.name}
            onSave={handleSaveSign}
            onHide={handleHideWidget}
          />
        </div>
      )}
      <div className={s.bubblesCard}>
        <div className={s.bubbles}>
          {bubbles.map((bubble: IBubble) => (
            <BubbleItem
              key={bubble.sign}
              bubble={bubble}
              isChinese={isChinese}
              isAccountSign={isAccountSign}
              currentSign={currentSign}
              selectSign={selectSign}
              signChecked={signChecked}
              showForceTooltip={bubble.sign === currentSign?.sign ? widgetHideTooltip : false}
              onSave={handleSaveSign}
              onCancel={handleCancelSelectSign}
            />
          ))}
        </div>
      </div>
      {isAccountSign && (
        <AccountModal
          isOpen={accountOpen}
          sign={selectSign as SIGN}
          onClose={() => setAccountOpen(false)}
        />
      )}
    </div>
  );
});
