import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Button from '@rambler-components/button';
import { ThemeProvider } from '@rambler-components/theme';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import YandexEvent from 'utils/counters/YandexEvent';
import { YANDEX_METRICS } from 'config/constants/counters';

import { IBubble } from 'Card';

import s from './styles.css';

interface ISignSelectWidget {
  sign: IBubble['sign']
  name: IBubble['name']
  onSave: (sign: IBubble['sign'], name: IBubble['name']) => void
  onHide: () => void
}

export const SignSelectWidget: React.FC<ISignSelectWidget> = React.memo(({
  sign,
  name,
  onSave,
  onHide,
}) => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const theme = {
    button: {
      white: {
        color: '#315efb',
      },
    },
  };

  const handleSave = () => {
    onSave(sign, name);
    (new YandexEvent(YANDEX_METRICS.COMMON)).send({
      type: 'reachGoal',
      data: 'card_zodiak_save',
    });
  };

  const handleCancel = () => {
    onHide();
    (new YandexEvent(YANDEX_METRICS.COMMON)).send({
      type: 'reachGoal',
      data: 'card_zodiak_hide',
    });
  };

  return (
    <div className={cn(s.root, isMobile && s.mobile)}>
      <div className={s.header}>
        <Icon id={`widget-${isMobile ? 'medium' : 'small'}-${sign}`} className={s.signIcon} />
        <div>
          <Typography
            variant="h3"
            className={s.title}
          >
            {`Вы - ${name}?`}
          </Typography>
          <Typography
            variant="defaultMedium"
            component="p"
            className={cn(s.text, s.textRow)}
          >
            Сохраните выбор, и все прогнозы подстроятся под ваш знак
          </Typography>
        </div>
      </div>
      <Typography
        variant="defaultMedium"
        component="p"
        className={cn(s.text, s.textColumn)}
      >
        Сохраните выбор, и все прогнозы подстроятся под ваш знак
      </Typography>
      <div className={s.buttons}>
        <Button
          className={s.saveButton}
          onClick={handleSave}
        >
          Да, сохранить
        </Button>
        {!isMobile && (
        <ThemeProvider theme={theme}>
          <Button
            type="white"
            onClick={handleCancel}
          >
            Скрыть
          </Button>
        </ThemeProvider>
        )}
        {isMobile && (
          <button
            type="button"
            aria-label="Скрыть"
            className={s.closeButton}
            onClick={handleCancel}
          >
            <Icon id="close" className={s.closeIcon} />
          </button>
        )}
      </div>
    </div>
  );
});
