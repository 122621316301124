import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { ICoffeeGame } from 'Card';

import {
  SYMBOLS_COUNT,
  STEPS,
  LOADING_TIME,
  COFFEE_SHARE_TEXT,
} from 'config/constants/fortune';

import { getCoffeGameSnippet, setCoffeeGameSnippet } from 'common/redux/fortune';

import CoffeeLogic from 'common/utils/coffeeLogic';
import { Icon } from 'common/components/Icon';

import { initShareButtons } from 'utils/share/initShareButtons';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { CoffeeSteam } from '../CoffeeSteam';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.origin,
    (state: IAppState) => state.fortune.coffeeGameSnippet.snippet,
    (state: IAppState) => state.fortune.coffeeGameSnippet.isSnippetReady,
    (state: IAppState) => state.fortune.coffeeGameSnippet.isSnippetFailed,
  ],
  (
    origin,
    snippet,
    isSnippetReady,
    isSnippetFailed,
  ) => ({
    origin,
    snippet,
    isSnippetReady,
    isSnippetFailed,
  }),
);

interface IFortuneCoffeeProps {
  coffeeGame: ICoffeeGame[]
  className?: string
}

type TimeType = 'past' | 'present' | 'future';
type SubstrateType = 'circle' | 'side';
type GameStepsType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

function FortuneCoffeeDesktop({
  coffeeGame,
  className,
}: IFortuneCoffeeProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    origin,
    snippet,
    isSnippetReady,
    isSnippetFailed,
  } = useSelector(selectData);
  const dispatch = useDispatch();

  const coffeeLogic = useRef(new CoffeeLogic('desktop', coffeeGame));

  const prevStep = useRef(STEPS.MAIN);
  const was8StepRendered = useRef(false);
  const gameMounted = useRef(false);

  const ramblerLikesRef = useRef(null);
  const [ramblerLikesInited, setRamblerLikesInited] = useState(false);

  const [step, setStep] = useState<GameStepsType>(STEPS.MAIN);

  const getTop100 = (tail: string) => getTop100Markup(false, top100Prefix, tail);

  const updateStep = () => {
    const newStep = coffeeLogic.current.getCurrentStep();

    prevStep.current = step;
    setStep(newStep);
  };

  const init = () => {
    dispatch(setCoffeeGameSnippet());
    coffeeLogic.current.init('desktop', coffeeGame);
    updateStep();
    was8StepRendered.current = false;
  };

  const nextGameStep = () => {
    coffeeLogic.current.nextStep();
    updateStep();
  };

  useEffect(() => {
    if (gameMounted.current) {
      if (prevStep.current === STEPS.MAIN) {
        setTimeout(nextGameStep, LOADING_TIME);
      } else if (prevStep.current === STEPS.FUTURE_DESCRIPTION) {
        const result = coffeeLogic.current.getResult();
        dispatch(getCoffeGameSnippet(result.past, result.present, result.future));
      }
    }

    if (!gameMounted.current) {
      gameMounted.current = true;
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  const setGameStep = (gameStep: GameStepsType) => {
    coffeeLogic.current.setStep(gameStep);
    updateStep();
  };

  // @ts-ignore
  const getPrevStep = (time: TimeType) => coffeeLogic.current.getPrevStep(time);
  const getNextStep = (time: TimeType) => coffeeLogic.current.getNextStep(time);

  const selectSymbol = (time: TimeType, symbol: number) => {
    coffeeLogic.current.selectSymbol(time, symbol);
    nextGameStep();
  };

  const renderRandomSubstrate = (
    time: TimeType,
    type: SubstrateType,
    randomSubstrateClassName: string,
  ) => {
    const { randomSubstrate } = coffeeLogic.current.getRandomSubstrate(time, type);

    return (
      <Icon
        id={`coffee-${type}-${randomSubstrate}`}
        key={`${time}-${type}-${randomSubstrate}`}
        className={cn(s[`image${type}${randomSubstrate}`], randomSubstrateClassName)}
      />
    );
  };

  const renderRandomSymbol = (
    time: TimeType,
    type: 'symbols',
    index: number,
    randomSymbolClassName: string,
  ) => {
    const { randomSymbol, style } = coffeeLogic.current.getRandomSymbol(time, type, index);
    const symbolCode = coffeeLogic.current.getCoffeeSymbol(randomSymbol);

    return (
      /* eslint-disable-next-line */
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: symbolCode }}
        key={`${time}-${type}-${randomSymbol}`}
        className={randomSymbolClassName}
        style={{ ...style }}
        onClick={() => selectSymbol(time, randomSymbol)}
      />
    );
  };

  const renderRandomSymbols = (
    time: TimeType,
    randomSymbolClassName: string,
  ) => [...Array(SYMBOLS_COUNT)].map((item, index) => renderRandomSymbol(
    time,
    'symbols',
    index,
    randomSymbolClassName,
  ));

  const renderUsedSubstrate = (
    time: TimeType,
    type: SubstrateType,
    coffeeSubstrateClassName: string,
  ) => {
    const { usedSubstrate } = coffeeLogic.current.getSubstrateByIndex(time, type, 0);

    return (
      <Icon
        id={`coffee-${type}-${usedSubstrate}`}
        key={`${time}-${type}`}
        className={cn(s[`image${type}${usedSubstrate}`], coffeeSubstrateClassName)}
      />
    );
  };

  const renderUsedSymbol = (
    time: TimeType,
    type: 'symbols',
    index: number,
    usedSymbolClassName: string,
  ) => {
    const {
      usedSymbol,
      isActive,
      style,
    } = coffeeLogic.current.getSymbolByIndex(time, type, index);
    const symbolCode = coffeeLogic.current.getCoffeeSymbol(usedSymbol);

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: symbolCode }}
        key={`${time}-${type}-${usedSymbol}`}
        className={cn(
          usedSymbolClassName,
          isActive && s.stepSymbolActive,
        )}
        style={{ ...style }}
      />
    );
  };

  const renderUsedSymbols = (
    time: TimeType,
    usedSymbolClassName: string,
  ) => [...Array(SYMBOLS_COUNT)].map((item, index) => renderUsedSymbol(
    time,
    'symbols',
    index,
    usedSymbolClassName,
  ));

  const renderIcon = (time: TimeType, iconClassName: string) => {
    const iconCode = coffeeLogic.current.getIcon(time);

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: iconCode }}
        className={iconClassName}
      />
    );
  };

  const renderNavigation = (time: TimeType) => (
    <>
      {time !== 'past' && (
        // eslint-disable-next-line
        <div
          className={s.stepArrowLeft}
          onClick={() => setGameStep(getPrevStep(time))}
          {...getTop100(`mini_game::screen_${time}::before`)}
        />
      )}
      {/* eslint-disable-next-line */}
      <div
        className={s.stepArrowRight}
        onClick={() => setGameStep(getNextStep(time))}
        {...getTop100(`mini_game::screen_${time}::next`)}
      />
      <div className={s.stepNavigation}>
        <div
          className={cn(s.stepNavigationText, time !== 'past' && s.stepNavigationInactive)}
          {...getTop100(`mini_game::screen_${time}::past`)}
        >
          <Icon id="coffee-past" className={cn(s.imagePast, s.stepNavigationIcon)} />
          {' '}
          Прошлое
        </div>
        <div
          className={cn(s.stepNavigationText, time !== 'present' && s.stepNavigationInactive)}
          {...getTop100(`mini_game::screen_${time}::present`)}
        >
          <Icon id="coffee-present" className={cn(s.imagePresent, s.stepNavigationIcon)} />
          {' '}
          Настоящее
        </div>
        <div
          className={cn(s.stepNavigationText, time !== 'future' && s.stepNavigationInactive)}
          {...getTop100(`mini_game::screen_${time}::future`)}
        >
          <Icon id="coffee-future" className={cn(s.imageFuture, s.stepNavigationIcon)} />
          {' '}
          Будущее
        </div>
      </div>
    </>
  );

  const renderStars = (gameStep: GameStepsType) => (
    <>
      {[...Array(coffeeLogic.current.getStars(gameStep, 'big'))].map((item, index: number) => (
        <Icon
          id="coffee-star-big-desktop"
          className={cn(s.stepStar, s.imageStarBig, s[`step${gameStep}StarBig${index}`])}
          // eslint-disable-next-line react/no-array-index-key
          key={`big-${gameStep}-${index}`}
        />
      ))}
      {[...Array(coffeeLogic.current.getStars(gameStep, 'small'))].map((item, index: number) => (
        <Icon
          id="coffee-star-small"
          className={cn(s.stepStar, s.imageStarSmall, s[`step${gameStep}StarSmall${index}`])}
          // eslint-disable-next-line react/no-array-index-key
          key={`small-${gameStep}-${index}`}
        />
      ))}
    </>
  );

  useEffect(() => {
    if (!ramblerLikesInited && step === STEPS.RESULT && snippet) {
      initShareButtons({
        node:            ramblerLikesRef.current!,
        cardTitle:       COFFEE_SHARE_TEXT,
        cardDescription: COFFEE_SHARE_TEXT,
        cardImage:       snippet || `${origin}/ts-special/coffee/sharing.png`,
        isMobile:        false,
        iconSize:        33,
      });

      setRamblerLikesInited(true);
    }
  }, [step, snippet]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSharing = () => {
    if (!isSnippetFailed && !isSnippetReady) {
      return (
        <Icon id="coffee-loading-desktop" className={cn(s.imageLoading, s.step8Loading)} />
      );
    }

    return (
      <div className={s.step8Sharing}>
        <div
          className={cn(
            'rambler-share',
            s.share,
          )}
          ref={ramblerLikesRef}
        />
      </div>
    );
  };

  const renderText = (time: TimeType) => (
    <div className={s.stepTextContainer}>
      <div className={s.stepTitle}>
        {coffeeLogic.current.getTitle(time)}
      </div>
      <div className={s.stepSubtitle}>
        {coffeeLogic.current.getSubtitle(time)}
      </div>
      <div className={s.stepText}>
        {coffeeLogic.current.getText(time)}
      </div>
    </div>
  );

  const renderResult = (gameStep: GameStepsType, time: TimeType) => (
    <>
      <div className={s[`step${gameStep}InfoTitle`]}>
        {coffeeLogic.current.getTitle(time)}
      </div>
      <div className={s[`step${gameStep}InfoSubtitle`]}>
        {coffeeLogic.current.getSubtitle(time)}
      </div>
    </>
  );

  const renderStep0 = () => (
    <div className={cn(s.step, s.step0)}>
      <Icon id="coffee-header-desktop" className={cn(s.imageHeader, s.step0Header)} />
      <div className={s.step0Text}>
        Гадание на кофейной гуще — один из самых доступных
        <br />
        и вкусных способов узнать свою судьбу. Мы приготовили
        <br />
        эту чашку для вас. Переверните её скорее!
      </div>
      {/* eslint-disable-next-line */}
      <div
        className={s.step0Button}
        onClick={nextGameStep}
        {...getTop100('mini_game::screen_start::start')}
      />
      <CoffeeSteam
        className={s.step0Steam}
        width={365}
        height={400}
      />
      <div className={s.step0Cup} />
      {renderStars(STEPS.MAIN)}
    </div>
  );

  const renderStep1 = () => (
    <div className={cn(s.step, s.step1)}>
      <Icon id="coffee-loading-desktop" className={cn(s.imageLoading, s.step1Loading)} />
      <div className={s.step1Text}>Ваша судьба определяется</div>
      <div className={s.step1Cup} />
      {renderStars(STEPS.LOADING)}
    </div>
  );

  const renderStep2 = () => (
    <div className={cn(s.step, s.step2)}>
      <div className={s.step2Text}>Сначала разберем ваше прошлое. Найдите символ в гуще</div>
      <Icon id="coffee-line" className={cn(s.imageLine, s.stepLine)} />
      <div className={s.step2Plate}>
        <div className={s.stepSymbols}>
          {renderRandomSubstrate('past', 'circle', s.step2Circle)}
          {renderRandomSymbols('past', s.stepSymbol)}
        </div>
      </div>
      {renderStars(STEPS.PAST)}
    </div>
  );

  const renderStep3 = () => (
    <div className={cn(s.step, s.step3)}>
      <div className={s.step3Plate}>
        {renderUsedSubstrate('past', 'circle', s.step3Circle)}
        {renderUsedSymbols('past', s.stepSymbolStatic)}
      </div>
      {renderIcon('past', s.stepIcon)}
      {renderText('past')}
      {renderNavigation('past')}
      {renderStars(STEPS.PAST_DESCRIPTION)}
    </div>
  );

  const renderStep4 = () => (
    <div className={cn(s.step, s.step4)}>
      <div className={s.step4Text}>Теперь посмотрим на настоящее</div>
      <Icon id="coffee-line" className={cn(s.imageLine, s.stepLine)} />
      <div className={s.step4Cup}>
        <div className={s.stepSymbols}>
          {renderRandomSubstrate('present', 'circle', s.step4Circle)}
          {renderRandomSymbols('present', s.stepSymbol)}
        </div>
      </div>
      {renderStars(STEPS.PRESENT)}
    </div>
  );

  const renderStep5 = () => (
    <div className={cn(s.step, s.step5)}>
      <div className={s.step5Cup}>
        {renderUsedSubstrate('present', 'circle', s.step5Circle)}
        {renderUsedSymbols('present', s.stepSymbolStatic)}
      </div>
      {renderIcon('present', s.stepIcon)}
      {renderText('present')}
      {renderNavigation('present')}
      {renderStars(STEPS.PRESENT_DESCRIPTION)}
    </div>
  );

  const renderStep6 = () => (
    <div className={cn(s.step, s.step6)}>
      <div className={s.step6Text}>
        Время заглянуть
        <br />
        в будущее
      </div>
      <Icon id="coffee-line" className={cn(s.imageLine, s.stepLine)} />
      <div className={s.step6Cup}>
        <div className={s.stepSymbols}>
          {renderRandomSubstrate('future', 'side', s.step6Side)}
          {renderRandomSymbols('future', s.stepSymbol)}
        </div>
      </div>
      {renderStars(STEPS.FUTURE)}
    </div>
  );

  const renderStep7 = () => (
    <div className={cn(s.step, s.step7)}>
      <div className={s.step7Cup}>
        {renderUsedSubstrate('future', 'side', s.step7Side)}
        {renderUsedSymbols('future', s.stepSymbolStatic)}
      </div>
      {renderIcon('future', s.stepIcon)}
      {renderText('future')}
      {renderNavigation('future')}
      {renderStars(STEPS.FUTURE_DESCRIPTION)}
    </div>
  );

  const renderStep8 = () => (
    <div className={cn(s.step, s.step8)}>
      <div className={s.step8Title}>Поделитесь магией кофе с друзьями!</div>
      {renderSharing()}
      <div className={s.step8Info}>
        {['past', 'present', 'future'].map((time: TimeType) => (
          <div className={s.step8InfoPart} key={time}>
            {renderIcon(time, s.step8Icon)}
            {renderResult(STEPS.RESULT, time)}
          </div>
        ))}
      </div>
      {/* eslint-disable-next-line */}
      <div
        className={s.step8Button}
        onClick={init}
        {...getTop100('mini_game::screen_results::to_start')}
      />
      {renderStars(STEPS.RESULT)}
    </div>
  );

  const renderGameStep = {
    0: renderStep0,
    1: renderStep1,
    2: renderStep2,
    3: renderStep3,
    4: renderStep4,
    5: renderStep5,
    6: renderStep6,
    7: renderStep7,
    8: renderStep8,
  };

  if (step === STEPS.RESULT && !was8StepRendered.current) {
    was8StepRendered.current = true;
  }

  return (
    <div
      className={cn(
        s.root,
        className,
      )}
      key={step}
    >
      {renderGameStep[step]()}
    </div>
  );
}

FortuneCoffeeDesktop.defaultProps = {
  className: '',
};

export { FortuneCoffeeDesktop };
