import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router';
import cn from 'classnames';

import Button from '@rambler-components/button';
import Search, { SuggestResponseType } from '@rambler-components/search';

import { ICardProps } from 'Card';

import { fetchSuggestedData } from 'utils/fetchSuggestedData';

import { useTop100Context } from 'common/contexts/top100Context';

import { getTop100 } from './index';

import s from './styles.css';

interface ICardFormProps {
  form: Extract<ICardProps['form'], 'search_dreams' | 'names_search' | 'search_primety' | 'search_taro'>
  onSuggestAction?: (query: string, length?: number) => Promise<SuggestResponseType>
  showSearchButton?: boolean
}

type SuggestItemType = {
  id?: number
  name: string
  slug: string
};

// todo: Может их все же загружать
const POPULAR_DREAMS: SuggestItemType[] = [
  { id: 5723, name: 'беременность', slug: 'beremennost' },
  { id: 5755, name: 'змея', slug: 'zmeya' },
  { id: 4367, name: 'рыба', slug: 'ryba' },
  { id: 5862, name: 'свадьба', slug: 'svadba' },
  { id: 3198, name: 'деньги', slug: 'dengi' },
  { id: 3663, name: 'зубы', slug: 'zuby' },
  { id: 3200, name: 'кровь', slug: 'krov' },
  { id: 4398, name: 'собака', slug: 'sobaka' },
  { id: 3988, name: 'паук', slug: 'pauk' },
  { id: 4074, name: 'пожар', slug: 'pozhar' },
];
const POPULAR_NAMES: SuggestItemType[] = [
  { name: 'Александр', slug: 'aleksandr' },
  { name: 'Анастасия', slug: 'anastasiya' },
  { name: 'Арсен', slug: 'arsen' },
  { name: 'Варвара', slug: 'varvara' },
  { name: 'Денис', slug: 'denis' },
  { name: 'Елена', slug: 'elena' },
  { name: 'Каролина', slug: 'karolina' },
  { name: 'Маргарита', slug: 'margarita' },
  { name: 'Михаил', slug: 'mihail' },
  { name: 'Сергей', slug: 'sergey' },
];
const POPULAR_OMENS: SuggestItemType[] = [
  { name: 'Кошка', slug: 'koshka' },
  { name: 'Синица', slug: 'siniqa' },
  { name: 'Паук', slug: 'pauk' },
  { name: 'Деньги', slug: 'dengi' },
  { name: 'Собака', slug: 'sobaka' },
  { name: 'Вода', slug: 'voda' },
  { name: 'Погода', slug: 'pogoda' },
  { name: 'Зеркало', slug: 'zerkalo' },
  { name: 'Бабочка', slug: 'babochka' },
  { name: 'Кольцо', slug: 'kolco' },
];

const TYPES_BY_FORMS: Record<ICardFormProps['form'], keyof APIsUrls['search']> = {
  search_dreams:  'dreams',
  names_search:   'names',
  search_primety: 'omens',
  search_taro:    'taro',
};

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.reloadKey,
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime,
  ],
  (
    reloadKey,
    isMobile,
    runtime,
  ) => ({
    reloadKey,
    isMobile,
    runtime,
  }),
);

function SearchForm({
  form,
  onSuggestAction,
  showSearchButton,
}: ICardFormProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const type = TYPES_BY_FORMS[form];
  const isDreams = form === 'search_dreams';
  const isNames = form === 'names_search';
  const isOmens = form === 'search_primety';

  const history = useHistory();
  const isFirstRenderDone = useRef(false);
  const isSearchEmpty = useRef(true);
  const {
    reloadKey,
    isMobile,
    runtime,
  } = useSelector(selectData);

  const day = new Date();
  const initialSuggestIndex = (day.getMonth() + day.getFullYear()) % POPULAR_DREAMS.length;
  let initSuggest;
  if (isDreams) {
    initSuggest = POPULAR_DREAMS[initialSuggestIndex];
  } else if (isNames) {
    initSuggest = POPULAR_NAMES[initialSuggestIndex];
  } else if (isOmens) {
    initSuggest = POPULAR_OMENS[initialSuggestIndex];
  }
  const [defaultSuggest, setDefaultSuggest] = useState(initSuggest);
  const [suggestItem, setSuggestItem] = useState<any>();

  const getRandomSuggest = () => {
    if (!isDreams && !isNames && !isOmens) return undefined;
    let anotherSuggest: SuggestItemType[] = [];

    if (isDreams) {
      anotherSuggest = POPULAR_DREAMS.filter(dream => dream.id !== (defaultSuggest || {}).id);
    } else if (isNames) {
      anotherSuggest = POPULAR_NAMES.filter(name => name.slug !== (defaultSuggest || {}).slug);
    } else if (isOmens) {
      anotherSuggest = POPULAR_OMENS.filter(name => name.slug !== (defaultSuggest || {}).slug);
    }

    return anotherSuggest[Math.floor(Math.random() * (anotherSuggest.length - 1))];
  };

  useEffect(() => {
    isFirstRenderDone.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRenderDone.current) {
      setDefaultSuggest(getRandomSuggest());
    }
  }, [reloadKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPlaceholder = () => {
    switch (type) {
      case 'dreams':
        return isMobile
          ? `Например, ${defaultSuggest!.name}`
          : `Что вам приснилось? Например, ${defaultSuggest!.name}`;
      case 'names':
        return isMobile
          ? `Например, ${defaultSuggest!.name}`
          : `Найти имя или отчество. Например, ${defaultSuggest!.name}`;
      case 'omens':
        return isMobile
          ? `Например, ${defaultSuggest!.name}`
          : `Приметы по ключевому слову. Например, ${defaultSuggest!.name}`;
      case 'taro':
        return isMobile
          ? 'Например, Туз Жезлов или Шут'
          : 'Имя карты (например, Туз Жезлов или Шут)';
      default:
        return '';
    }
  };

  const buttonText = {
    dreams: 'Найти в сонниках',
    names:  'Найти',
    omens:  'Найти',
    taro:   'Найти карту',
  };

  const urlPart = {
    dreams: 'dreams/word',
    names:  'names/name',
    omens:  'primety/word',
    taro:   'taro/znachenie-kart',
  };

  const formSearch = () => (
    <Search
      className={cn(
        s.search,
        s.input,
        isMobile && s.inputMobile,
      )}
      placeholder={getPlaceholder()}
      showSearchButton={showSearchButton}
      type="border"
      display="normal"
      action=""
      onSuggestAction={(query: string) => {
        isSearchEmpty.current = query.length === 0;

        if (onSuggestAction) {
          return onSuggestAction(query);
        }

        return fetchSuggestedData({ runtime }, query, type);
      }}
      onSubmit={(e, query, submitDetails) => {
        e.preventDefault();
        if (submitDetails) {
          setSuggestItem(submitDetails?.suggestItem);
          history.push(submitDetails?.suggestItem.url);
        }
      }}
      {...getTop100(isMobile, top100Prefix, form, 'input')}
    />
  );

  const formButton = (onClick?: any) => (
    <Button
      className={cn(
        s.button,
        isMobile && s.buttonMobile,
      )}
      onClick={onClick}
      {...getTop100(isMobile, top100Prefix, form, 'result_button')}
    >
      {buttonText[type]}
    </Button>
  );

  const formByType = [
    formSearch(),
    formButton(
      () => {
        if (suggestItem) {
          history.push(suggestItem.url);
        } else if (isSearchEmpty.current && defaultSuggest) {
          history.push(`/${urlPart[type]}/${defaultSuggest.slug}/`);
        }
      },
    ),
  ];

  return (
    <>
      {formByType.map((item: JSX.Element, index: number) => {
        const key = `CardForm-${form}-${index}`;

        return (
          <Fragment key={key}>
            {item}
          </Fragment>
        );
      })}
    </>
  );
}

SearchForm.defaultProps = {
  onSuggestAction:  null,
  showSearchButton: false,
};

export { SearchForm };
