// @ts-nocheck
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import ResizeObserver from 'resize-observer-polyfill';

import Button from '@rambler-components/button';
import { RadioButton, RadioGroup } from '@rambler-components/radio-button';
import Textarea from '@rambler-components/textarea';
import Input from '@rambler-components/input';
import FieldStatus from '@rambler-components/field-status';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';

import {
  SET_RUNTIME_VARIABLE,
  sendFeedbackForm,
} from 'common/redux/runtime';

import s from './index.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.captchaKey,
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.userEmail,
    (state: IAppState) => state.runtime.feedbackFormSubmitted,
  ],
  (
    captchaKey,
    isMobile,
    userEmail,
    feedbackFormSubmitted,
  ) => ({
    captchaKey,
    isMobile,
    userEmail,
    feedbackFormSubmitted,
  }),
);

enum Step {
  Question,
  Reason,
  Thanks,
}

enum Reason {
  Ads = 1,
  Design,
  Content,
  Bugs
}

type InputStatus = {
  status?: 'error',
  statusMessage?: string
};

interface IFeedbackFormProps {
  isCard?: boolean,
}

function FeedbackForm({ isCard }: IFeedbackFormProps) {
  const dispatch = useDispatch();
  const {
    captchaKey,
    isMobile,
    userEmail,
    feedbackFormSubmitted,
  } = useSelector(selectData);
  const isFeedbakFormVisible = useSelector(
    state => state.runtime.adminData.settings.feedback_visibility,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [step, setStep] = useState(Step.Question);
  const [radioValue, setRadioValue] = useState<Reason>();
  const [commentValue, setCommentValue] = useState('');
  const [email, setEmail] = useState('');
  const [commentStatus, setCommentStatus] = useState<InputStatus>({});
  const [emailStatus, setEmailStatus] = useState<InputStatus>({});
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaContainerRef = useRef<HTMLDivElement>(null);
  const captchaSizeRef = useRef('normal');
  const captchaWidgetKey = useRef(null);
  const fromRef = useRef<HTMLDivElement>(null);
  const isFeedbackNegative = useRef(true);

  useEffect(() => setEmail(userEmail || ''), [userEmail]);

  const getCaptchaSize = () => (
    // 302 - размер стандартной капчи
    // 40 - отступы от краев
    fromRef.current!.getBoundingClientRect().width < 302 + 40
      ? 'compact'
      : 'normal'
  );

  const setInitial = () => {
    if (feedbackFormSubmitted) {
      dispatch({
        type:  SET_RUNTIME_VARIABLE,
        name:  'feedbackFormSubmitted',
        value: false,
      });
    }

    setIsOpen(false);
    setStep(Step.Question);
    setRadioValue(undefined);
    setCommentValue('');
  };

  const goToThanks = () => {
    setStep(Step.Thanks);

    setTimeout(() => {
      setInitial();

      if (isCard) {
        setIsCardVisible(false);
      }
    }, 2000);
  };

  useEffect(() => {
    if (feedbackFormSubmitted) {
      goToThanks();
    }
  }, [feedbackFormSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((isOpen || isCard) && !scriptLoaded) {
      if (!document.getElementById('hcaptcha')) {
        captchaSizeRef.current = getCaptchaSize();
        // @ts-ignore
        window.onHCaptchaScriptLoad = () => {
          setScriptLoaded(true);
          // @ts-ignore
          delete window.onHCaptchaScriptLoad;
        };

        const hCaptchaScript = document.createElement('script');
        hCaptchaScript.src = 'https://js.hcaptcha.com/1/api.js?onload=onHCaptchaScriptLoad&render=explicit';
        hCaptchaScript.id = 'hcaptcha';
        document.body.appendChild(hCaptchaScript);
      } else {
        setScriptLoaded(true);
      }
    }
  }, [isOpen, isCard]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((isOpen || isCard) && scriptLoaded) {
      const formNode = fromRef.current;

      let resizeObserver: ResizeObserver | null = new ResizeObserver(() => {
        const newSize = getCaptchaSize();

        if (newSize !== captchaSizeRef.current) {
          captchaSizeRef.current = newSize;
          window.hcaptcha.reset(captchaWidgetKey.current, {
            size: newSize,
          });
        }
      });

      resizeObserver.observe(formNode!);

      return () => {
        if (formNode) {
          resizeObserver!.unobserve(formNode);
        } else {
          resizeObserver!.disconnect();
        }

        resizeObserver = null;
      };
    }

    return undefined;
  }, [isOpen, isCard, scriptLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scriptLoaded && step === Step.Reason) {
      try {
        captchaWidgetKey.current = window.hcaptcha.render(
          captchaContainerRef.current,
          {
            sitekey:            captchaKey,
            callback:           (token: string) => setCaptchaToken(token),
            'expired-callback': () => setCaptchaToken(''),
            size:               captchaSizeRef.current,
          },
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Не удалось отобразить капчу', e);
      }
    }
  }, [step, scriptLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isFeedbakFormVisible || !isCardVisible) return null;

  const onCommentFocus = () => {
    if (!radioValue) setRadioValue(Reason.Ads);
  };

  const sendData = (token = '') => {
    const problemTypesByReasons: Record<Reason, string> = {
      [Reason.Ads]:     'adv',
      [Reason.Bugs]:    'tech',
      [Reason.Content]: 'data',
      [Reason.Design]:  'design',
    };

    const data = {
      url:            window.location.href,
      problem_type:   problemTypesByReasons[radioValue!],
      email,
      text:           commentValue,
      hcaptcha_token: token || captchaToken,
    };

    dispatch(sendFeedbackForm(data));
  };

  const checkEmail = () => {
    if (!email) {
      setEmailStatus({
        status:        'error',
        statusMessage: 'Пустой email',
      });

      return false;
    }

    if (email && !/^.+@.+\..+$/.test(email)) {
      setEmailStatus({
        status:        'error',
        statusMessage: 'Некорректный email',
      });

      return false;
    }

    return true;
  };

  const submit = async () => {
    if (!commentValue) {
      setCommentStatus({
        status:        'error',
        statusMessage: radioValue !== Reason.Bugs ? 'Опишите вашу проблему' : 'Оставьте комментарий',
      });

      return;
    }

    if (!checkEmail()) return;

    sendData();
  };

  const top100Prefix = 'feedback';

  const header = (
    <>
      <div className={s.header}>
        <Typography variant="h2" component="div">Оставить отзыв</Typography>
        {!isCard && (
          <Icon
            id="close"
            className={s.close}
            onClick={() => setInitial()}
            {...getTop100Markup(isMobile, top100Prefix, 'exit')}
          />
        )}
      </div>
      <Typography variant="defaultMedium" className={s.text}>
        Мы обновили интерфейс Гороскопов и нам важно ваше мнение
      </Typography>
    </>
  );

  // т.к. radialGradient и linearGradient не работают при подтягивании через use - пришлось так
  // + они всё равно нигде больше не используются и не будут
  const loveIcon = (className: string) => (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <radialGradient id="radialGradient-b" cx="50%" cy="-21.763%" r="120.004%" fx="50%" fy="-21.763%" gradientTransform="matrix(0 1 -.92857 0 .298 -.718)">
          <stop offset="0%" stopColor="#FF228B" />
          <stop offset="100%" stopColor="#E70043" />
        </radialGradient>
        <linearGradient id="linearGradient-c" x1="50%" x2="50%" y1="0%" y2="99.628%">
          <stop offset="0%" stopColor="#FFF" />
          <stop offset="100%" stopColor="#E84263" stopOpacity="0" />
        </linearGradient>
        <filter id="filter-d" width="146.2%" height="185.7%" x="-23.1%" y="-42.9%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
        </filter>
        <path id="path-a" d="M22.115 19.554c-1.685 1.619-4.196 3.628-7.531 6.028-.349.25-.82.25-1.168 0-3.335-2.4-5.846-4.41-7.531-6.028C3.21 16.988.132 13.392.005 9.463-.063 7.289.49 5.07 1.672 3.343 3.393.826 6.445-.463 9.23.15c1.916.422 3.64 1.73 4.77 3.5 1.13-1.77 2.854-3.078 4.77-3.5 2.785-.613 5.837.676 7.559 3.191 1.182 1.73 1.735 3.947 1.665 6.12-.126 3.93-3.205 7.526-5.879 10.092z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1 2)">
        <mask id="mask-e" fill="#fff">
          <use xlinkHref="#path-a" />
        </mask>
        <use fill="url(#radialGradient-b)" xlinkHref="#path-a" />
        <path fill="url(#linearGradient-c)" d="M20.004 1c3.27 0 6.378 2.79 6.848 6.632.603 2.512-.47 8.149-6.293 7.277-4.562-.682-6.105-2.949-6.559-5.532-.454 2.583-1.997 4.85-6.559 5.532-5.823.872-6.896-4.765-6.293-7.277C1.618 3.791 4.725 1 7.996 1c2.808 0 5.344 2.057 6.005 4.788C14.66 3.057 17.196 1 20.004 1z" filter="url(#filter-d)" mask="url(#mask-e)" opacity=".64" />
      </g>
    </svg>
  );

  const cryIcon = (className: string) => (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50.049%" y2="99.97%" id="linearGradient1">
          <stop stopColor="#FFAE21" offset="0%" />
          <stop stopColor="#FFC019" offset="0%" />
          <stop stopColor="#FFDE56" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="93.82%" id="linearGradient2">
          <stop stopColor="#FFD000" offset="0%" />
          <stop stopColor="#FFCB08" offset="20.195%" />
          <stop stopColor="#FFAB3E" offset="78.813%" />
          <stop stopColor="#FFAC3C" offset="100%" />
        </linearGradient>
        <filter x="-11.5%" y="-11.5%" width="123.1%" height="123.1%" filterUnits="objectBoundingBox" id="filter1">
          <feGaussianBlur stdDeviation="1" in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(2 2)" fillRule="nonzero" fill="none">
        <circle fill="url(#linearGradient1)" cx="14" cy="14" r="14" />
        <circle fill="url(#linearGradient2)" filter="url(#filter1)" cx="14" cy="14" r="13" />
        <path d="M14 18a9.148 9.148 0 0 1 7.33 3.15.5.5 0 0 1-.7.71A8.21 8.21 0 0 0 14 19a9.727 9.727 0 0 0-6.66 2.87h-.02a.498.498 0 0 1-.68-.048.498.498 0 0 1 0-.682A10.682 10.682 0 0 1 14 18Zm-5-8c.552 0 1 .672 1 1.5S9.552 13 9 13s-1-.672-1-1.5.448-1.5 1-1.5Zm10 0c.552 0 1 .672 1 1.5s-.448 1.5-1 1.5-1-.672-1-1.5.448-1.5 1-1.5Z" fill="#2A344D" />
        <path d="M5.48 8.48a.504.504 0 0 1-.12-1 7.193 7.193 0 0 0 4.77-3.64.5.5 0 0 1 .87.49 8.23 8.23 0 0 1-5.4 4.13l-.12.02Zm17.57-.38a.503.503 0 0 0-.36-.61 7.2 7.2 0 0 1-4.78-3.64.502.502 0 0 0-.884-.026.503.503 0 0 0 .014.516 8.272 8.272 0 0 0 5.41 4.12h.12a.492.492 0 0 0 .48-.36Z" fill="#B6893D" />
        <path d="M6.823 22.042c1.354.263 2.659-.579 2.912-1.881.169-.87-.293-2.859-1.386-5.973-2.178 2.477-3.353 4.15-3.522 5.019-.253 1.302.641 2.571 1.996 2.835Z" fill="#65CCFF" />
      </g>
    </svg>
  );

  const buttons = (
    <div className={s.buttons}>
      <Button
        type="white"
        onClick={() => {
          isFeedbackNegative.current = false;
          dispatch(sendFeedbackForm());
        }}
        className={s.button}
        {...getTop100Markup(isMobile, top100Prefix, 'like_click')}
      >
        {loveIcon(s.emoji)}
        Нравится
      </Button>
      <Button
        type="white"
        onClick={() => setStep(Step.Reason)}
        className={s.button}
        {...getTop100Markup(isMobile, top100Prefix, 'dislike_click')}
      >
        {cryIcon(s.emoji)}
        <span className={cn(step === Step.Reason && s.active)}>Не нравится</span>
      </Button>
    </div>
  );

  const captchaContainer = (
    <div
      ref={captchaContainerRef}
      className={s.captchaContainer}
    />
  );

  const isButtonEnabled = captchaToken && email && commentValue;

  const contentBySteps: Record<Step, JSX.Element> = {
    [Step.Question]: (
      <>
        {header}
        {buttons}
      </>
    ),
    [Step.Reason]: (
      <>
        {header}
        {buttons}

        <Typography variant="defaultMedium" className={s.reasonQuestion}>
          Что именно вам не понравилось?
        </Typography>

        <RadioGroup
          type="border"
          className={s.radioGroup}
          value={radioValue}
          onChange={setRadioValue}
        >
          <RadioButton
            className={s.radio}
            value={Reason.Ads}
            {...getTop100Markup(isMobile, top100Prefix, 'dislike_click::because_ads')}
          >
            Слишком много рекламы
          </RadioButton>
          <RadioButton
            className={s.radio}
            value={Reason.Design}
            {...getTop100Markup(isMobile, top100Prefix, 'dislike_click::because_design')}
          >
            Не нравится дизайн / Неудобно
          </RadioButton>
          <RadioButton
            className={s.radio}
            value={Reason.Content}
            {...getTop100Markup(isMobile, top100Prefix, 'dislike_click::because_content')}
          >
            Не нашел то, что искал
          </RadioButton>
          <RadioButton
            className={s.radio}
            value={Reason.Bugs}
            {...getTop100Markup(isMobile, top100Prefix, 'dislike_click::because_technical_disadvantages')}
          >
            Технические недостатки
          </RadioButton>
        </RadioGroup>

        <Textarea
          className={s.comment}
          type="border"
          rows={4}
          placeholder={radioValue !== Reason.Bugs
            ? 'Комментарий'
            : 'Опишите проблему'}
          onFocus={() => {
            onCommentFocus();
            setCommentStatus({});
          }}
          value={commentValue}
          status={commentStatus.status}
          onChange={(e: any) => {
            setCommentValue(e.target.value);
          }}
          {...getTop100Markup(isMobile, top100Prefix, 'dislike_click::write_comment')}
        />
        {commentStatus.statusMessage && (
          <span className={s.statusMessage}>
            {commentStatus.statusMessage}
          </span>
        )}

        <Typography variant="defaultMedium" className={s.emailText}>
          Оставьте вашу почту, чтобы мы вам ответили
        </Typography>

        <FieldStatus
          status={emailStatus.status}
          message={emailStatus.statusMessage}
        >
          <Input
            className={s.emailInput}
            type="border"
            placeholder="Адрес почты"
            value={email}
            status={emailStatus.status}
            onChange={(e: any) => setEmail(e.target.value)}
            onFocus={() => setEmailStatus({})}
            onBlur={() => checkEmail()}
          />
        </FieldStatus>

        {captchaContainer}

        <Button
          className={cn(s.submit, !captchaToken && s.disabled)}
          onClick={submit}
          disabled={!isButtonEnabled}
          {...getTop100Markup(isMobile, top100Prefix, 'dislike_click::send_comment')}
        >
          Отправить
        </Button>
      </>
    ),
    [Step.Thanks]: (
      <div
        className={cn(s.thanks, isCard && s.thanksCard)}
        {...getTop100Markup(isMobile, top100Prefix, isFeedbackNegative.current
          ? 'dislike_thank_you_screen'
          : 'like_thank_you_screen')}
      >
        <Typography variant="h3" component="div">Спасибо</Typography>
        <span className={s.heart}>
          {loveIcon(s.heartEmoji)}
        </span>
      </div>
    ),
  };

  return (
    <div
      className={cn(
        s.root,
        {
          [s.formWrapper]:     isOpen || isCard,
          [s.formWrapperCard]: isCard,
          [s.mobile]:          isMobile && !isCard,
          [s.desk]:            !isMobile && !isCard,
        },
        step === Step.Question && s.questionStep,
        step === Step.Thanks && s.thanksStep,
        step === Step.Reason && s.reasonStep,
      )}
    >
      {!isOpen && !isCard
        ? (
          <Button
            onClick={() => setIsOpen(true)}
            style={{ height: 40, width: 40 }}
            {...getTop100Markup(isMobile, top100Prefix, 'opened_feedback')}
          >
            <Icon id="idea" style={{ width: 20, height: 20 }} />
          </Button>
        )
        : (
          <div
            className={cn(s.form, isCard && s.formCard)}
            ref={fromRef}
          >
            {contentBySteps[step]}
          </div>
        )}
    </div>
  );
}

FeedbackForm.defaultProps = {
  isCard: false,
};

export { FeedbackForm };
