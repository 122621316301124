import React from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardVideoProps {
  video: NonNullable<ICardProps['video']>
  isMobile: boolean
  className?: string
}

function Video({
  video: {
    // type,
    title,
    width,
    height,
    src,
    params,
    annotation,
  },
  isMobile,
  className,
}: ICardVideoProps) {
  const aspectRatio = width / height;

  const neededWidth = 640;
  // чтобы высота была кратна 5
  const neededHeight = Math.round((neededWidth / aspectRatio) / 5) * 5;

  return (
    <div
      className={cn(
        s.iframe,
        isMobile && s.iframeMobile,
        className,
      )}
    >
      <iframe
        className={cn(
          s.video,
          isMobile && s.videoMobile,
        )}
        title={title}
        width={neededWidth}
        height={neededHeight}
        src={src}
        allow={params.allow}
        // eslint-disable-next-line react/no-unknown-property
        allowFullScreen={params.allowFullScreen}
        frameBorder={`${params.frameBorder}`}
      />
      {annotation && (
        <Typography
          variant="defaultRegular"
          component="p"
          className={s.text}
        >
          {annotation}
        </Typography>
      )}
    </div>
  );
}

Video.defaultProps = {
  className: '',
};

export { Video };
