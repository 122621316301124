import React, {
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import _throttle from 'lodash.throttle';
import Cookies from 'js-cookie';

import ImageComponent from '@rambler-components/image';

import { Link } from 'react-router-dom';

import { ICardProps } from 'Card';
import { Icon } from 'common/components/Icon';

import { safeGet } from 'utils/safeGet';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import useIsInViewport from 'common/hooks/useIsInViewport';

import { STORIES_ANIMATION_EXPIRES } from 'config/constants/content';

import s from './styles.css';

const STORIES_COUNT_PER_SHIFT = 5;
const STORY_WIDTH = 120;
const STORY_MARGIN = 10;
const STORY_WIDTH_WITH_MARGIN = STORY_WIDTH + STORY_MARGIN;

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.isBot,
  ],
  (
    isMobile,
    isBot,
  ) => ({
    isMobile,
    isBot,
  }),
);

enum DIRECTION {
  prev = 'prev',
  next = 'next',
}

interface ICardStoriesProps {
  stories: ICardProps['stories']
  isLazy: ICardProps['isLazy']
  isFirstStory: ICardProps['isFirstStory']
  wrapperClassName?: string
}

function Stories({
  stories,
  isLazy,
  isFirstStory,
  wrapperClassName,
}: ICardStoriesProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    isMobile,
    isBot,
  } = useSelector(selectRuntimeData);
  const storiesNode = useRef<HTMLDivElement>(null);
  const prevArrowNode = useRef<HTMLButtonElement>(null);
  const nextArrowNode = useRef<HTMLButtonElement>(null);
  const anchorNode = useRef<HTMLDivElement>(null);
  const isAnchorInView = useIsInViewport(anchorNode);

  const storiesWidth = stories && stories.length
    ? stories.length * STORY_WIDTH_WITH_MARGIN - STORY_MARGIN
    : 0;

  const isStoriesAnimationShown = Cookies.get('storiesAnimationShown') === 'true';
  const shouldBeAnimated = !isStoriesAnimationShown && isFirstStory && isMobile;

  const disableAnimation = () => {
    Cookies.set('storiesAnimationShown', 'true', { expires: STORIES_ANIMATION_EXPIRES });
  };

  useEffect(() => {
    if (storiesNode.current && shouldBeAnimated) {
      storiesNode.current.style.marginLeft = `-${storiesWidth - window.innerWidth}px`;
    }
  }, [storiesNode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (storiesNode.current && isAnchorInView && shouldBeAnimated) {
      storiesNode.current.classList.add(s.storiesAnimated);
      disableAnimation();
    }
  }, [isAnchorInView, shouldBeAnimated]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!stories || !stories.length) return null;

  const isArrowVisible = !isMobile && stories.length > STORIES_COUNT_PER_SHIFT;

  const onScroll = _throttle(() => {
    if (storiesNode.current) {
      const { scrollLeft } = storiesNode.current;
      const prevArrowNodeClassList = prevArrowNode.current?.classList;
      const nextArrowNodeClassList = nextArrowNode.current?.classList;

      if (scrollLeft === 0) {
        prevArrowNodeClassList?.add(s.arrowHidden);
      } else {
        if (prevArrowNodeClassList?.contains(s.arrowHidden)) {
          prevArrowNodeClassList?.remove(s.arrowHidden);
        }

        if (scrollLeft >= storiesWidth - STORIES_COUNT_PER_SHIFT * STORY_WIDTH_WITH_MARGIN) {
          nextArrowNodeClassList?.add(s.arrowHidden);
        } else if (nextArrowNodeClassList?.contains(s.arrowHidden)) {
          nextArrowNodeClassList?.remove(s.arrowHidden);
        }
      }
    }
  }, 100);

  const shiftStories = (direction: DIRECTION) => {
    if (storiesNode.current) {
      const scrollLeft = Math.ceil(storiesNode.current.scrollLeft);
      const scrolledStoriesFraction = scrollLeft / STORY_WIDTH_WITH_MARGIN;
      const scrolledStoriesCount = Math.ceil(scrolledStoriesFraction);
      const isWholeStoryScrolled = scrollLeft % STORY_WIDTH_WITH_MARGIN === 0;
      const scrolledStoriesShift = isWholeStoryScrolled || direction === DIRECTION.prev
        ? STORIES_COUNT_PER_SHIFT
        : STORIES_COUNT_PER_SHIFT - 1;

      const nextScrolledStoriesCount = scrolledStoriesCount
        + (direction === DIRECTION.next ? 1 : -1) * scrolledStoriesShift;

      const options: ScrollToOptions = {
        left:     Math.max(nextScrolledStoriesCount * STORY_WIDTH_WITH_MARGIN, 0),
        top:      0,
        behavior: 'smooth',
      };
      storiesNode.current.scrollTo(options);
    }
  };

  const arrowButton = (direction: DIRECTION) => (
    <button
      className={cn(s.arrow, s[`arrow-${direction}`], direction === DIRECTION.prev && s.arrowHidden)}
      type="button"
      ref={direction === DIRECTION.prev ? prevArrowNode : nextArrowNode}
      onClick={() => shiftStories(direction)}
      key={direction}
      aria-label={`${direction === DIRECTION.prev ? 'Предыдущая' : 'Следующая'} история`}
    >
      <Icon id="arrow-next" className={s.icon} />
    </button>
  );

  return (
    <>
      <div
        className={cn(
          s.stories,
          isMobile && s.storiesMobile,
          wrapperClassName,
        )}
        ref={storiesNode}
        onScroll={!isMobile ? onScroll : () => {}}
      >
        {isArrowVisible && arrowButton(DIRECTION.prev)}
        {stories.map((item, index) => {
          const { url } = item.image;

          const rubricAlias = safeGet(() => item.topic_alias, 'longread');
          const link = item.link || `/${rubricAlias}/${item.id}-${item.slug}/`;

          const titleWithWBR = item.title.includes('<wbr />');

          return (
            <Link
              className={cn(
                s.story,
                isMobile && s.storyMobile,
              )}
              to={link}
              key={`story-${item.id}`}
              style={{
                width:       `${STORY_WIDTH}px`,
                marginRight: index === stories.length - 1 ? 0 : `${STORY_MARGIN}px`,
              }}
              {...getTop100Markup(isMobile, top100Prefix, link)}
            >
              <ImageComponent
                className={cn(
                  s.image,
                  isLazy && s.imageLazy,
                )}
                isImg={isBot || !isLazy}
                isS3
                src={url}
                alt={item.title}
                isLazy={isLazy}
                width={120}
                height={200}
              />
              {titleWithWBR
                ? (
                  <span
                    className={s.title}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />
                )
                : (
                  <span className={s.title}>
                    {item.title}
                  </span>
                )}
            </Link>
          );
        })}
        {isArrowVisible && arrowButton(DIRECTION.next)}
      </div>
      {shouldBeAnimated && <div ref={anchorNode} />}
    </>
  );
}

Stories.defaultProps = {
  wrapperClassName: '',
};

export { Stories };
