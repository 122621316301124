import React, {
  useState,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import _find from 'lodash.find';

import Select from '@rambler-components/select';

import { IText } from 'Card';

import { GENDER_CLASSIC } from 'config/constants/gender';
import { SIGN } from 'config/constants/sign';
import signNames from 'config/constants/signNames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { signNameOptions } from 'common/utils/signNameValues';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.account,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (
    account,
    isMobile,
  ) => ({
    account,
    isMobile,
  }),
);

interface ICompatibilityTableProps {
  table: IText['percentage']
}

type GenderType = 'woman' | 'man';
type TablesRefsType = string[];

function CompatibilityTable({ table }: ICompatibilityTableProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const signSelect = signNameOptions('zodiac');
  const {
    account,
    isMobile,
  } = useSelector(selectData);
  // вернёт undefined если sign: null
  const userSign = _find(signSelect, sign => sign.value === account.sign);
  let userGender: GenderType = 'woman';

  if (account.gender) {
    userGender = account.gender === 'female' ? 'woman' : 'man';
  }

  const [mainGender, setMainGender] = useState<GenderType>(userGender);
  const [mainSign, setMainSign] = useState(
    userSign ? `${userSign.value}` : `${signSelect[0].value}`,
  );
  const tablesRefs = useRef<TablesRefsType>([]);

  if (!table) return null;

  const tables = Object.keys(table).map((genderKey: keyof typeof GENDER_CLASSIC) => {
    const genderDataKeys = Object.keys(table[genderKey]);

    const gender = genderKey;

    return genderDataKeys.map((genderSignKey: keyof typeof SIGN) => {
      const genderSignData = table[genderKey][genderSignKey];
      const genderSignDataKeys = Object.keys(genderSignData);
      const genderSignDivKey = `CompatibilityTable-${genderKey}-${genderSignKey}`;

      const mainGenderUrlPart = `${gender === 'woman' ? 'zhenshhina' : 'muzhchina'}-${signNames.zodiacTranslit[genderSignKey]}`;

      tablesRefs.current.push(`${gender}-${genderSignKey}`);

      return (
        <div
          className={cn(
            s.table,
            isMobile ? s.tableMobile : s.tableDesktop,
          )}
          key={genderSignDivKey}
        >
          <div className={cn(
            s.compatibilityTable,
            isMobile ? s.compatibilityTableMobile : s.compatibilityTableDesktop,
          )}
          >
            {genderSignDataKeys.map((genderSignPairKey: keyof typeof SIGN) => {
              const genderSignPairDivKey = `${genderSignDivKey}-${genderSignPairKey}`;
              const percent = genderSignData[genderSignPairKey];

              const partnerGenderUrlPart = `${gender === 'woman' ? 'muzhchina' : 'zhenshhina'}-${signNames.zodiacTranslit[genderSignPairKey]}`;

              const url = gender === 'woman'
                ? `${mainGenderUrlPart}-${partnerGenderUrlPart}/`
                : `${partnerGenderUrlPart}-${mainGenderUrlPart}/`;

              return (
                <Link
                  className={cn(
                    s.compatibilityTableSign,
                    isMobile ? s.compatibilityTableSignMobile : s.compatibilityTableSignDesktop,
                  )}
                  key={genderSignPairDivKey}
                  to={url}
                  title={`${gender === 'woman' ? 'Мужчина' : 'Женщина'}-${signNames.zodiac[genderSignPairKey]}`}
                >
                  <Typography
                    variant="defaultMedium"
                    className={cn(
                      s.compatibilityTableValue,
                      isMobile ? s.compatibilityTableValueMobile : s.compatibilityTableValueDesktop,
                    )}
                  >
                    {`${percent}%`}
                  </Typography>
                  <div className={cn(
                    s.compatibilityTableColumn,
                    isMobile ? s.compatibilityTableColumnMobile : s.compatibilityTableColumnDesktop,
                  )}
                  >
                    <div
                      className={cn(
                        s.compatibilityTablePercent,
                        isMobile
                          ? s.compatibilityTablePercentMobile
                          : s.compatibilityTablePercentDesktop,
                        s[genderSignPairKey],
                      )}
                      style={isMobile
                        ? { width: `${percent}%` }
                        : { height: `${percent}%` }}
                    />
                  </div>
                  <Icon
                    id={genderSignPairKey}
                    className={cn(
                      s.compatibilityTableIcon,
                      isMobile
                        ? s.compatibilityTableIconMobile
                        : s.compatibilityTableIconDesktop,
                      s[genderSignPairKey],
                    )}
                  />
                </Link>
              );
            })}
          </div>
          <Typography
            variant="defaultMedium"
            className={cn(
              s.describeTable,
              isMobile ? s.describeTableMobile : s.describeTableDesktop,
            )}
          >
            Таблица совместимости
            {' '}
            {gender === 'woman' ? 'Женщины' : 'Мужчины'}
            -
            {signNames.zodialGenitive[genderSignKey]}
            {' '}
            с
            {' '}
            {gender === 'woman' ? 'мужскими' : 'женскими'}
            {' '}
            знаками
          </Typography>
        </div>
      );
    });
  });

  const activeTableIndex = tablesRefs.current.indexOf(`${mainGender}-${mainSign}`);
  const leftPost = activeTableIndex === 0
    ? 0
    : `-${100 * activeTableIndex}%`;

  const genderOptions = [{
    value: 'man',
    label: 'Я — мужчина',
  }, {
    value: 'woman',
    label: 'Я — женщина',
  }];

  return (
    <>
      <Typography variant={isMobile ? 'h3' : 'h2'} component="h2" className={isMobile ? s.h3 : s.h2}>Таблица совместимости знаков зодиака</Typography>
      <div className={cn(
        s.compatibilitySelects,
        isMobile ? s.compatibilitySelectsMobile : s.compatibilitySelectsDesktop,
      )}
      >
        <Select
          className={cn(
            s.select,
            isMobile ? s.selectMobile : s.selectDesktop,
          )}
          value={mainGender}
          placeholder="Ваш пол"
          options={genderOptions}
          zIndex={150}
          onChange={(gender: GenderType) => setMainGender(gender)}
          {...getTop100Markup(isMobile, top100Prefix, 'compatibility_table::gender')}
        />
        <Select
          className={cn(
            s.select,
            isMobile ? s.selectMobile : s.selectDesktop,
          )}
          value={mainSign}
          placeholder="Ваш знак зодиака"
          options={signSelect}
          zIndex={100}
          onChange={value => setMainSign(value as string)}
          {...getTop100Markup(isMobile, top100Prefix, 'compatibility_table::sign')}
        />
      </div>
      <div className={s.tablesWrapper}>
        <div
          className={s.tables}
          style={{
            transform: `translateX(${leftPost})`,
          }}
        >
          {tables}
        </div>
      </div>
    </>
  );
}

export { CompatibilityTable };
