import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import ImageComponent from '@rambler-components/image';

import { Link } from 'react-router-dom';

import { ICardProps } from 'Card';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.isBot,
  ],
  (
    isMobile,
    isBot,
  ) => ({
    isMobile,
    isBot,
  }),
);

interface ICardLongreadListImageLinkProps {
  image: NonNullable<ICardProps['article']>['image']
  title?: NonNullable<ICardProps['article']>['title']
  isLazy: ICardProps['isLazy']
  link: ICardProps['link']
  wrapperClassName?: string
}

function LongreadListImageLink({
  image,
  title,
  isLazy,
  link,
  wrapperClassName,
}: ICardLongreadListImageLinkProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    isMobile,
    isBot,
  } = useSelector(selectData);

  if (!image || !image.url || !link) return null;

  return (
    <Link
      className={cn(
        s.link,
        wrapperClassName,
      )}
      to={link.link}
      {...getTop100Markup(isMobile, top100Prefix, `article_block::card_click::${link.link}`)}
    >
      <ImageComponent
        className={cn(
          s.image,
          (isLazy || isMobile) && s.imageLazy,
        )}
        isImg={isBot || (!isLazy && !isMobile)}
        isS3
        src={image.url}
        alt={title}
        isLazy={isLazy}
        width={640}
        height={360}
      />
    </Link>
  );
}

LongreadListImageLink.defaultProps = {
  title:            '',
  wrapperClassName: '',
};

export { LongreadListImageLink };
