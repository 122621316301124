import React, {
  useCallback,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import ImageComponent from '@rambler-components/image';

import { ICardProps } from 'Card';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isBot,
  ],
  isBot => ({
    isBot,
  }),
);

interface ICardImageProps {
  image: ICardProps['image']
  title?: ICardProps['title']
  isLazy: ICardProps['isLazy']
  isIllustration?: boolean
  wrapperClassName?: string
  isMobile?: boolean
  height?: number
  width?: number
}

function Image({
  image,
  title,
  isLazy,
  isIllustration,
  wrapperClassName,
  isMobile,
  height,
  width,
}: ICardImageProps) {
  const { isBot } = useSelector(selectRuntimeData);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const imageLoadedCallback = useCallback(() => setImageLoaded(true), []);

  if (!image || !image.url) return null;

  const {
    url,
    source = { title: '', url: '' },
  } = image || {};

  const resWidth = isIllustration && isMobile ? 680 : 1280;
  const resHeight = isIllustration ? 440 : 720;

  return (
    <div
      className={cn(
        s.root,
        isIllustration ? s.rootIllustration : s.rootDefault,
        isImageLoaded && s.rootLoaded,
        wrapperClassName,
      )}
    >
      <ImageComponent
        className={cn(
          s.picture,
          !isBot && isLazy && s.pictureLazy,
          !isBot && isLazy && isIllustration && isMobile && s.illustrationMobile,
          !isBot && isLazy && isIllustration && !isMobile && s.illustrationDesktop,
        )}
        isImg={isBot || !isLazy}
        isS3
        src={url}
        alt={title}
        isLazy={isLazy}
        width={width || 640}
        height={height || Math.floor((resHeight * 640) / resWidth)}
        onLoad={imageLoadedCallback}
      />
      {source && source.title && (
        <Typography variant="defaultMedium" className={s.source}>
          Фото:
          {' '}
          {source.title}
        </Typography>
      )}
    </div>
  );
}

Image.defaultProps = {
  title:            '',
  isIllustration:   false,
  wrapperClassName: '',
  isMobile:         false,
  height:           null,
  width:            null,
};

export { Image };
