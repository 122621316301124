import React from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardClusterAnnotationProps {
  bodyShort: NonNullable<ICardProps['article']>['body_short']
  isMobile: boolean
}

function ClusterAnnotation({
  bodyShort,
  isMobile,
}: ICardClusterAnnotationProps) {
  if (!bodyShort) return null;

  return (
    <Typography
      variant="defaultRegular"
      component="p"
      className={cn(s.annotation, isMobile && s.annotationMobile)}
    >
      {bodyShort}
    </Typography>
  );
}

export { ClusterAnnotation };
