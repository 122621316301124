import React from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardNameDays {
  nameDays: ICardProps['name_days']
  isMobile: boolean
  className?: string
}

function NameDays({
  nameDays,
  isMobile,
  className,
}: ICardNameDays) {
  if (!nameDays || !nameDays.list.length) return null;

  return (
    <div className={cn(
      s.root,
      isMobile ? s.rootMobile : s.rootDesktop,
      className,
    )}
    >
      <h2 className={cn(
        s.title,
        isMobile ? s.titleMobile : s.titleDesktop,
      )}
      >
        {nameDays.title}
      </h2>
      <div className={s.list}>
        {nameDays.list.map((month, index) => {
          const key = `NameDays-${index}`;

          return (
            <span
              className={s.month}
              key={key}
            >
              <Typography variant="defaultMedium" className={s.monthName}>{month.month}</Typography>
              <Typography variant="defaultMedium" className={s.monthDates}>{month.dates}</Typography>
            </span>
          );
        })}
      </div>
    </div>
  );
}

NameDays.defaultProps = {
  className: '',
};

export { NameDays };
