import React, {
  useState,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import _find from 'lodash.find';

import Select from '@rambler-components/select';

import type { SignTypeChinese } from 'config/constants/sign';
import signNames from 'config/constants/signNames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { signNameOptions } from 'common/utils/signNameValues';

import s from 'common/components/Card/Text/styles.css';
import { chineseCompatibilityData } from './data';

const selectData = createSelector(
  [
    (state: IAppState) => state.account.gender,
    (state: IAppState) => state.runtime.currentParams.chineseCompatibilitySign,
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
  ],
  (
    accountGender,
    compatibilitySign,
    isMobile,
    currentPage,
    currentSection,
  ) => ({
    accountGender,
    compatibilitySign,
    isMobile,
    showTable: currentPage === 'chinese' && currentSection === 'compatibility' && typeof compatibilitySign !== 'undefined',
  }),
);

type GenderType = 'man' | 'woman';

function ChineseCompatibilityTable({ className }: {className?: string}) {
  const {
    top100Prefix,
  } = useTop100Context();
  const signSelect = signNameOptions('chinese');
  const {
    accountGender,
    compatibilitySign,
    isMobile,
    showTable,
  } = useSelector(selectData);
  // вернёт undefined если sign: null
  const userSign = _find(signSelect, sign => sign.value === compatibilitySign);
  let userGender: GenderType = 'man';

  if (accountGender) {
    userGender = accountGender === 'female' ? 'woman' : 'man';
  }

  const [mainGender, setMainGender] = useState<GenderType>(userGender);
  const [mainSign, setMainSign] = useState(
    userSign ? `${userSign.value}` : `${signSelect[0].value}`,
  );
  const tablesRefs = useRef<string[]>([]);

  if (!showTable) return null;

  const tables = Object.keys(chineseCompatibilityData)
    .map((genderKey: keyof typeof GENDER_CLASSIC) => {
      const genderDataKeys = Object.keys(chineseCompatibilityData[genderKey]);

      const gender = genderKey;

      return genderDataKeys.map((genderSignKey: SignTypeChinese) => {
        const genderSignData = chineseCompatibilityData[genderKey][genderSignKey];
        const genderSignDataKeys = Object.keys(genderSignData);
        const genderSignDivKey = `ChineseCompatibilityTable-${genderKey}-${genderSignKey}`;

        const mainGenderUrlPart = `${gender === 'woman' ? 'zhenshchina' : 'muzhchina'}-${signNames.chineseTranslit[genderSignKey]}`;

        tablesRefs.current.push(`${gender}-${genderSignKey}`);

        return (
          <div
            className={cn(
              s.table,
              isMobile ? s.tableMobile : s.tableDesktop,
            )}
            key={genderSignDivKey}
          >
            <div className={cn(
              s.compatibilityTable,
              isMobile ? s.compatibilityTableMobile : s.compatibilityTableDesktop,
            )}
            >
              {genderSignDataKeys.map((genderSignPairKey: SignTypeChinese) => {
                const genderSignPairDivKey = `${genderSignDivKey}-${genderSignPairKey}`;
                const percent = genderSignData[genderSignPairKey];

                const partnerGenderUrlPart = `${gender === 'woman' ? 'muzhchina' : 'zhenshchina'}-${signNames.chineseTranslit[genderSignPairKey]}`;

                const url = gender === 'man'
                  ? `/chinese/compatibility/${mainGenderUrlPart}-${partnerGenderUrlPart}/`
                  : `/chinese/compatibility/${partnerGenderUrlPart}-${mainGenderUrlPart}/`;

                return (
                  <Link
                    className={cn(
                      s.compatibilityTableSign,
                      isMobile ? s.compatibilityTableSignMobile : s.compatibilityTableSignDesktop,
                    )}
                    key={genderSignPairDivKey}
                    to={url}
                    title={`${gender === 'woman' ? 'Мужчина' : 'Женщина'}-${signNames.chinese[genderSignPairKey]}`}
                  >
                    <Typography
                      variant="defaultMedium"
                      className={cn(
                        s.compatibilityTableValue,
                        isMobile
                          ? s.compatibilityTableValueMobile
                          : s.compatibilityTableValueDesktop,
                      )}
                    >
                      {`${percent}%`}
                    </Typography>
                    <div className={cn(
                      s.compatibilityTableColumn,
                      isMobile
                        ? s.compatibilityTableColumnMobile
                        : s.compatibilityTableColumnDesktop,
                    )}
                    >
                      <div
                        className={cn(
                          s.compatibilityTablePercent,
                          isMobile
                            ? s.compatibilityTablePercentMobile
                            : s.compatibilityTablePercentDesktop,
                          s[genderSignPairKey],
                        )}
                        style={isMobile
                          ? { width: `${percent}%` }
                          : { height: `${percent}%` }}
                      />
                    </div>
                    <Icon
                      id={genderSignPairKey}
                      className={cn(
                        s.compatibilityTableIcon,
                        isMobile
                          ? s.compatibilityTableIconMobile
                          : s.compatibilityTableIconDesktop,
                        s[genderSignPairKey],
                      )}
                    />
                  </Link>
                );
              })}
            </div>
            <Typography
              variant="defaultMedium"
              className={cn(
                s.describeTable,
                isMobile ? s.describeTableMobile : s.describeTableDesktop,
              )}
            >
              Таблица совместимости
              {' '}
              {gender === 'woman' ? 'Женщины' : 'Мужчины'}
              -
              {signNames.chineseGenitive[genderSignKey]}
              {' '}
              с
              {' '}
              {gender === 'woman' ? 'мужскими' : 'женскими'}
              {' '}
              знаками
            </Typography>
          </div>
        );
      });
    });

  const activeTableIndex = tablesRefs.current.indexOf(`${mainGender}-${mainSign}`);
  const leftPost = activeTableIndex === 0
    ? 0
    : `-${100 * activeTableIndex}%`;

  const title = compatibilitySign === 'all' ? 'Таблица совместимости знаков китайского гороскопа' : `Таблица совместимости ${signNames.chineseGenitive[compatibilitySign!]} с другими знаками`;

  const genderOptions = [{
    value: 'man',
    label: 'Я — мужчина',
  }, {
    value: 'woman',
    label: 'Я — женщина',
  }];

  return (
    <div className={className}>
      <Typography variant={isMobile ? 'h3' : 'h2'} component="h2" className={isMobile ? s.h3 : s.h2}>{title}</Typography>
      <div className={cn(
        s.compatibilitySelects,
        isMobile ? s.compatibilitySelectsMobile : s.compatibilitySelectsDesktop,
      )}
      >
        <Select
          className={cn(
            s.select,
            isMobile ? s.selectMobile : s.selectDesktop,
          )}
          value={mainGender}
          placeholder="Ваш пол"
          options={genderOptions}
          zIndex={150}
          onChange={(gender: GenderType) => setMainGender(gender)}
          {...getTop100Markup(isMobile, top100Prefix, 'compatibility_table::gender')}
        />
        {compatibilitySign === 'all' && (
          <Select
            className={cn(
              s.select,
              isMobile ? s.selectMobile : s.selectDesktop,
            )}
            value={mainSign}
            placeholder="Ваш знак зодиака"
            options={signSelect}
            zIndex={100}
            onChange={value => setMainSign(value as string)}
            {...getTop100Markup(isMobile, top100Prefix, 'compatibility_table::sign')}
          />
        )}
      </div>
      <div className={s.tablesWrapper}>
        <div
          className={s.tables}
          style={{
            transform: `translateX(${leftPost})`,
          }}
        >
          {tables}
        </div>
      </div>
    </div>
  );
}

ChineseCompatibilityTable.defaultProps = {
  className: '',
};

export default ChineseCompatibilityTable;
