import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import format from 'date-fns/format';

import Button from '@rambler-components/button';

import { ICardProps } from 'Card';

import Select from '@rambler-components/select';
import InputDate from 'common/components/InputDate';

import { DATE_FORMAT } from 'config/constants/calendar';

import { useTop100Context } from 'common/contexts/top100Context';
import { useChinese } from './hooks/useChinese';
import { useDashboard } from './hooks/useDashboard';
import { useDateByDeviceContext } from './contexts/dateByDeviceContext';

import { getSizeStyle, getTop100, Size } from './index';
import s from './styles.css';

const DEFAULT_YEAR = 1990;

interface ICardDateByDeviceProps {
  form: Extract<ICardProps['form'], 'chinese_sign_for_date' | 'dashboard_personal'>
  isMobile: boolean
  minYear: number
  maxYear: number
  maxDate?: string
}

interface ISelectValueProps {
  label: string
  value: number
  disabled?: boolean
}

function DateByDevice({
  form,
  isMobile,
  minYear,
  maxYear,
  maxDate,
}: ICardDateByDeviceProps) {
  const birthday = useSelector((state: IAppState) => state.account.birthday);

  const accountValues = useMemo(() => {
    const isChineseForm = form === 'chinese_sign_for_date';

    if (birthday && isChineseForm) {
      const currentDate = birthday.split('-');

      const day = Number(currentDate[2] || null);
      const month = Number(currentDate[1] || null);
      const year = Number(currentDate[0] || DEFAULT_YEAR);

      return {
        day,
        month,
        year,
      };
    }

    return null;
  }, [birthday, form]);

  const initValues = {
    day:   accountValues?.day || null,
    month: accountValues?.month || null,
    year:  accountValues?.year || DEFAULT_YEAR,
  };

  const {
    top100Prefix,
  } = useTop100Context();
  const {
    isButtonClicked,
    setIsButtonClicked,
  } = useDateByDeviceContext();
  // eslint-disable-next-line
  const [dateValue, setDateValue] = useState(birthday || '');
  const [dayValue, setDayValue] = useState<number | null>(initValues.day);
  const [monthValue, setMonthValue] = useState<number | null>(initValues.month);
  const [yearValue, setYearValue] = useState<number>(initValues.year);

  const {
    checkChineseDateAction,
  } = useChinese();
  const {
    checkDashboardDateAction,
  } = useDashboard();

  useEffect(() => {
    if (isMobile && birthday) {
      setDateValue(birthday);
    }

    if (!isMobile && accountValues) {
      setDayValue(accountValues.day);
      setMonthValue(accountValues.month);
      setYearValue(accountValues.year);
    }
  }, [birthday, accountValues, isMobile]);

  const setMaxDay = () => {
    const year = yearValue || 2000;
    const month = monthValue || 0;
    const maxDayInMonth = new Date(year, month, 0).getDate();

    // ограничение по количеству дней в месяце
    if (dayValue && dayValue > maxDayInMonth) {
      setDayValue(maxDayInMonth);
    }
  };

  useEffect(() => {
    setMaxDay();
  }, [monthValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMaxDay();
  }, [yearValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsButtonClicked(false);
  }, [dayValue, monthValue, yearValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkDate = () => {
    const isDateCorrect = isMobile
      ? Boolean(dateValue)
      : Boolean(dayValue && monthValue && yearValue);

    if (isDateCorrect) {
      let date = '';
      if (isMobile) {
        date = dateValue;
      } else {
        const day = `${dayValue && dayValue < 10 ? '0' : ''}${dayValue}`;
        const month = `${monthValue && monthValue < 10 ? '0' : ''}${monthValue}`;
        const year = yearValue;
        date = `${year}-${month}-${day}`;
      }

      checkChineseDateAction(form, date);
      checkDashboardDateAction(form, date);
    }

    setIsButtonClicked(true);
  };

  const dayValues = () => {
    const year = yearValue || new Date().getFullYear();
    const month = monthValue || 0;
    const maxDayInMonth = new Date(year, month, 0).getDate();

    return [...Array(31)].map((item, index) => ({
      label:    `${index + 1}`,
      value:    index + 1,
      disabled: index + 1 > maxDayInMonth,
    }));
  };

  const monthValues = () => {
    const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

    return monthNames.map((item, index) => ({
      label: item,
      value: index + 1,
    }));
  };

  const yearValues = () => {
    const yearsCount = maxYear - minYear;

    return [...Array(yearsCount)].map((item, index) => ({
      label: `${minYear + index}`,
      value: minYear + index,
    }));
  };

  const formSelect = (
    values: ISelectValueProps[],
    placeholder: string,
    type: string,
    size: Size = 'normal',
    status?: 'success' | 'warning' | 'error',
    value?: any,
    onChange?: any,
  ) => (
    <Select
      className={cn(
        s.select,
        s[`select-${type}`],
        getSizeStyle(isMobile, size),
      )}
      placeholder={placeholder}
      value={value}
      zIndex={150}
      type="border"
      status={status}
      options={values}
      onChange={onChange || null}
    />
  );

  const formInput = (
    placeholder: string,
    top100Tail: string,
    value?: string,
    size: Size = 'normal',
    status?: 'error' | 'warning' | 'success',
    bottomBound?: string,
    topBound?: string,
    onChange?: any,
  ) => (
    <InputDate
      className={cn(
        s.input,
        getSizeStyle(isMobile, size),
        isMobile && s.inputMobile,
      )}
      placeholder={placeholder}
      isMobile={isMobile}
      type="border"
      value={value}
      status={status}
      onChange={onChange || null}
      min={bottomBound}
      max={topBound}
      {...getTop100(isMobile, top100Prefix, form, top100Tail || placeholder)}
    />
  );

  const formButton = (onClick?: any) => (
    <Button
      className={cn(
        s.button,
        isMobile && s.buttonMobile,
      )}
      onClick={onClick || null}
      {...getTop100(isMobile, top100Prefix, form, 'result_button')}
    >
      {form === 'chinese_sign_for_date' ? 'Показать знак' : 'Рассчитать'}
    </Button>
  );

  const formByType = isMobile
    ? [
      formInput(
        'Дата рождения',
        'birth_date',
        dateValue,
        'normal',
        (isButtonClicked && !dateValue) ? 'error' : undefined,
        `${minYear}-01-01`,
        maxDate || `${maxYear}-12-31`,
        (date: Date) => setDateValue(format(new Date(date), DATE_FORMAT)),
      ),
      formButton(
        checkDate,
      ),
    ]
    : [
      formSelect(
        dayValues(),
        'День',
        'day',
        'normal',
        (isButtonClicked && !dayValue) ? 'error' : undefined,
        dayValue,
        (val: any) => {
          if (!val.disabled) {
            setDayValue(val);
          }
        },
      ),
      formSelect(
        monthValues(),
        'Месяц',
        'month',
        'normal',
        (isButtonClicked && !monthValue) ? 'error' : undefined,
        monthValue,
        (val: any) => { setMonthValue(val); },
      ),
      formSelect(
        yearValues(),
        'Год',
        'year',
        'normal',
        (isButtonClicked && !yearValue) ? 'error' : undefined,
        yearValue,
        (val: any) => { setYearValue(val); },
      ),
      formButton(
        checkDate,
      ),
    ];

  return (
    <>
      {formByType.map((item: JSX.Element, index: number) => {
        const key = `CardForm-${form}-${index}`;

        return (
          <Fragment key={key}>
            {item}
          </Fragment>
        );
      })}
    </>
  );
}

DateByDevice.defaultProps = {
  maxDate: '',
};

export { DateByDevice };
