import React from 'react';

import Image from '@rambler-components/image';

import { getUrlWithUtmParams } from 'utils/getUrlWithUtmParams';

import s from '../styles.css';

interface IItem {
  item: ClusterGallerySlideType;
  positionCount: number
  allItemsCount: number
}

export function Item({ item, positionCount, allItemsCount }: IItem) {
  const { url, source, description } = item;

  const sourceUrl = source?.url || '';
  const imageSourceTitle = source?.title || sourceUrl;
  const isRamblerSource = sourceUrl.includes('rambler.ru');
  const sourceLink = isRamblerSource ? getUrlWithUtmParams(
    sourceUrl,
    {
      medium: 'source',
      source: 'rhoroscopes',
    },
  ) : sourceUrl;

  return (
    <div className={s.item}>
      <div className={s.imgCover}>
        {url && (
          <Image
            isImg
            isLazy
            className={s.img}
            src={url}
            alt={description}
            width={640}
            height={360}
          />
        )}
      </div>
      <div className={s.info}>
        <div className={s.count}>
          {positionCount}
          /
          {allItemsCount}
        </div>
        <div className={s.description}>
          <span>{description}</span>
          {imageSourceTitle && (
            <span>
              &nbsp;Фото:&nbsp;
              <a href={sourceLink}>{imageSourceTitle}</a>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
