import React, {
  Fragment,
  useState,
  useEffect,
} from 'react';
import cn from 'classnames';

import { useHistory } from 'react-router';

import Button from '@rambler-components/button';

import {
  addDays,
  format,
} from 'date-fns';

import { ICardProps } from 'Card';

import InputDate from 'common/components/InputDate';

import { useTop100Context } from 'common/contexts/top100Context';

import { getSizeStyle, getTop100, Size } from './index';
import s from './styles.css';

interface ICardDashboardByDateProps {
  form: ICardProps['form']
  isMobile: boolean
}

function DashboardByDate({
  form,
  isMobile,
}: ICardDashboardByDateProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const todayDate = format(new Date(), 'yyyy-MM-dd');
  const history = useHistory();
  const [birthValue, setBirthValue] = useState('');
  const [predictionValue, setPredictionValue] = useState(todayDate);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setIsButtonClicked(false);
  }, [birthValue, predictionValue]);

  const checkDate = () => {
    if (birthValue && predictionValue) {
      history.push(`/personal/${predictionValue}/${birthValue}/`);
    }

    setIsButtonClicked(true);
  };

  const formInput = (
    placeholder: string,
    top100Tail: string,
    size: Size = 'normal',
    value?: string,
    status?: 'success' | 'warning' | 'error',
    onChange?: any,
    bottomBound?: string,
    topBound?: string,
    calendarInitDate?: string,
  ) => (
    <InputDate
      className={cn(
        s.input,
        getSizeStyle(isMobile, size),
        isMobile && s.inputMobile,
      )}
      placeholder={placeholder}
      isMobile={isMobile}
      status={status}
      type="border"
      value={value}
      onChange={onChange}
      min={bottomBound}
      max={topBound}
      calendarInitDate={calendarInitDate}
      {...getTop100(isMobile, top100Prefix, form, top100Tail || placeholder)}
    />
  );

  const formButton = (onClick?: any) => (
    <Button
      className={cn(
        s.button,
        isMobile && s.buttonMobile,
      )}
      onClick={onClick}
      {...getTop100(isMobile, top100Prefix, form, 'result_button')}
    >
      Рассчитать
    </Button>
  );

  const formInputs = [
    formInput(
      'Дата рождения',
      'birth_date',
      'normal',
      birthValue,
      (isButtonClicked && !birthValue) ? 'error' : undefined,
      setBirthValue,
      '1910-01-01',
      `${new Date().getFullYear() - 5}-01-01`,
    ),
    formInput(
      'Дата прогноза',
      'prediction_date',
      'normal',
      predictionValue,
      (isButtonClicked && !predictionValue) ? 'error' : undefined,
      setPredictionValue,
      '1910-01-01',
      format(addDays(new Date(), 21), 'yyyy-MM-dd'),
      todayDate,
    ),
    formButton(
      checkDate,
    ),
  ];

  return (
    <>
      {formInputs.map((item: JSX.Element, index: number) => {
        const key = `CardForm-${form}-${index}`;

        return (
          <Fragment key={key}>
            {item}
          </Fragment>
        );
      })}
    </>
  );
}

export { DashboardByDate };
