import React, { useRef } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';

import { Ad } from 'common/components/Ad';

import { ICardProps } from 'Card';

import { safeGet } from 'utils/safeGet';

import { Title } from 'common/components/Card/Title';
import { Tags } from 'common/components/Card/Tags';
import { Footer } from 'common/components/Card/Footer';

import cardStyles from 'common/components/Card/styles.css';

import { ClusterAnnotation } from './components/ClusterAnnotation';
import { ClusterMediaSection } from './components/ClusterMainMedia';
import { ClusterText } from './components/ClusterText';
import { ClusterRelated } from './components/ClusterRelated';

interface ICardLongreadClusterProps {
  isMobile: boolean
  article: ICardProps['article']
  tags: ICardProps['tags']
  likes: ICardProps['likes']
  isLazy: ICardProps['isLazy']
  wrapperClassName?: string
}

function LongreadCluster({
  isMobile,
  article,
  tags,
  likes,
  isLazy,
  wrapperClassName,
}: ICardLongreadClusterProps) {
  const textReaded = useRef(false);

  if (!article) return null;

  const sendPageReadEvent = (isInView: boolean) => {
    if (window.rcm && !textReaded.current && isInView) {
      textReaded.current = true;

      window.rcm('pageread', {
        itemId: article.id,
      });
    }
  };

  const rubricAlias = safeGet(() => article.topic.alias, 'longread');
  const link = { link: `/${rubricAlias}/${article.id}-${article.normalized_title}/` };

  return (
    <div className={wrapperClassName}>
      <Title
        title={article.long_title}
        isMobile={isMobile}
        link={link}
        type="longread_detail"
        isLink={false}
        isMainCard
      />
      <Tags
        wrapperClassName={cn(
          cardStyles.tags,
          isMobile ? cardStyles.tagsMobile : cardStyles.tagsDesktop,
        )}
        tags={tags}
        isMobile={isMobile}
        isCluster
      />
      <ClusterAnnotation
        bodyShort={article.body_short}
        isMobile={isMobile}
      />
      <ClusterMediaSection
        cluster={article}
        isLazy={isLazy}
      />
      <ClusterText
        text={article.body}
      />
      <InView as="div" onChange={(inView: boolean) => sendPageReadEvent(inView)} />
      {isMobile && (
        <Ad name="marketing_content_mobile" />
      )}
      <Ad
        name={
          isMobile
            ? 'sponsored_m'
            : 'sponsored'
        }
      />
      <ClusterRelated
        related={article.related}
        resource={article.resource}
        isMobile={isMobile}
      />
      <Footer
        className={cn(
          cardStyles.footer,
          isMobile ? cardStyles.footerMobile : cardStyles.footerDesktop,
        )}
        title={article.title}
        link={link}
        likes={likes}
        isMobile={isMobile}
      />
    </div>
  );
}

LongreadCluster.defaultProps = {
  wrapperClassName: '',
};

export { LongreadCluster };
