import React, { useCallback } from 'react';
import cn from 'classnames';

import { useHistory } from 'react-router';

import Button from '@rambler-components/button';

import { ICardProps } from 'Card';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { Typography } from 'common/components/Typography';

import s from './styles.css';

interface ICardDayNumberProps {
  data: ICardProps['dashboard_day_number']
  isMobile: boolean
  isMainCard: boolean
  className?: string
}

function DayNumber({
  className,
  isMobile,
  isMainCard,
  data,
}: ICardDayNumberProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const history = useHistory();

  const onClickButton = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();

    history.push('/personal/');

    window.scrollTo(0, 0);
  }, [top100Prefix]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!data) return null;

  const {
    title,
    teaser,
    positive,
    negative,
  } = data;

  return (
    <div className={cn(
      s.number,
      isMobile ? s.numberMobile : s.numberDesktop,
      (isMainCard || isMobile) && s.numberMargins,
      isMainCard ? s.numberDetail : s.numberList,
      className,
    )}
    >
      <div className={s.teaser}>
        <Typography
          variant="h2"
          component={isMainCard ? 'h2' : 'span'}
          className={s.title}
        >
          {title}
        </Typography>
        <div className={cn(
          s.wrapper,
          isMainCard ? s.wrapperDetail : s.wrapperList,
          isMobile ? s.wrapperMobile : s.wrapperDesktop,
        )}
        >
          <Typography
            variant="h3"
            component="span"
            className={cn(
              s.num,
              !isMainCard && !isMobile && s.numSecondaryDesktop,
            )}
          >
            {teaser.number}
          </Typography>
          <Typography
            variant="defaultBold"
            className={cn(
              s.summary,
              isMainCard ? s.summaryDetail : s.summaryList,
              isMobile ? s.summaryMobile : s.summaryDesktop,
            )}
          >
            {teaser.summary}
          </Typography>
        </div>
      </div>
      {isMainCard && (
        <>
          <div className={cn(
            s.info,
            isMobile ? s.infoMobile : s.infoDesktop,
            s.infoPos,
          )}
          >
            <Typography
              variant="h3"
              component="h4"
              className={s.subTitle}
            >
              Что стоит делать
            </Typography>
            <ul className={s.list}>
              {positive.map((pos, index) => {
                const key = `DayNumber-positive-${index}-${pos.length}`;

                return (
                  <Typography
                    variant="defaultMedium"
                    key={key}
                    className={s.listItem}
                    component="li"
                  >
                    {pos}
                  </Typography>
                );
              })}
            </ul>
          </div>
          <div className={cn(
            s.info,
            isMobile ? s.infoMobile : s.infoDesktop,
            s.infoNeg,
          )}
          >
            <h4 className={cn(
              s.subTitle,
              isMobile ? s.subTitleMobile : s.subTitleDesktop,
            )}
            >
              Чего делать не стоит
            </h4>
            <ul className={s.list}>
              {negative.map((neg, index) => {
                const key = `DayNumber-negative-${index}-${neg.length}`;

                return (
                  <li
                    key={key}
                    className={s.listItem}
                  >
                    {neg}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
      {!isMainCard && !isMobile && (
        <Button
          href="/personal/"
          className={s.more}
          onClick={e => onClickButton(e)}
          {...getTop100Markup(isMobile, top100Prefix, 'dashboard::moreButton')}
        >
          Узнать подробнее
        </Button>
      )}
    </div>
  );
}

DayNumber.defaultProps = {
  className: '',
};

export { DayNumber };
