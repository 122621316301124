import React, {
  useRef,
  useMemo,
  useCallback,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useHistory } from 'react-router';
import {
  Link,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import Button from '@rambler-components/button';

import { months } from 'config/constants/routes';

import { IOmensList } from 'Card';

import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.currentParams.url,
  ],
  (
    currentPage,
    currentSection,
    paramUrl,
  ) => ({
    currentPage,
    currentSection,
    paramUrl,
  }),
);

interface ICardOmensList {
  omensList: IOmensList
  isMobile: boolean
  className?: string
}

function OmensList({
  omensList,
  isMobile,
  className,
}: ICardOmensList) {
  const history = useHistory();
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    currentPage,
    currentSection,
    paramUrl,
  } = useSelector(selectData);
  const { pathname } = useLocation();
  const {
    link,
    name_alt: nameAlt,
    omens,
  } = omensList;

  const isOmensMonthPage = useRouteMatch(`/primety/calendar/:month(${months})`);
  const isLink = pathname === '/primety/calendar/' || isOmensMonthPage;
  const omensArr = useRef<IOmensList['omens']>(link ? omens.slice(0, 4) : omens);
  const showMore = link ? nameAlt : '';
  const isEvenWhite = useRef(currentPage === 'omens' && (
    (currentSection === 'month' && paramUrl)
    || (currentSection === 'calendar' && !paramUrl)
  ));

  const onClickButton = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();

    history.push(link!);
  }, [link]); // eslint-disable-line react-hooks/exhaustive-deps

  const moreBtnOrLink = useMemo(() => {
    if (isLink) {
      return (
        <Typography
          variant="defaultMedium"
          className={s.moreLink}
          element={(
            <Link
              to={link!}
              {...getTop100Markup(isMobile, top100Prefix, 'primety_with_link::link_click')}
            />
          )}
        >
          {showMore}
        </Typography>
      );
    }

    return (
      <Button
        href={link!}
        onClick={e => onClickButton(e)}
        className={cn(
          s.moreButton,
          isMobile ? s.moreButtonMobile : s.moreButtonDesktop,
        )}
        {...getTop100Markup(isMobile, top100Prefix, 'primety_with_button::button_click')}
      >
        {showMore}
      </Button>
    );
  }, [isLink, showMore, link]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!omensList || !omensList.omens.length) return null;

  return (
    <div className={cn(
      s.root,
      isMobile ? s.rootMobile : s.rootDesktop,
      className,
    )}
    >
      <ul className={cn(
        s.list,
        isMobile ? s.rootMobile : s.rootDesktop,
      )}
      >
        {omensArr.current.map(omen => {
          const key = `omen-${omen.id}`;

          return (
            <Typography
              variant="defaultMedium"
              component="li"
              className={cn(
                s.omen,
                isEvenWhite ? s.omenEvenWhite : s.omenEvenBlue,
              )}
              key={key}
            >
              {omen.text}
            </Typography>
          );
        })}
      </ul>
      {link ? moreBtnOrLink : null}
    </div>
  );
}

OmensList.defaultProps = {
  className: '',
};

export default OmensList;
