import React from 'react';
import { Link } from 'react-router-dom';

import { IText } from 'Card';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { htmlParser } from 'common/utils/textParser';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface IParagraphWithLinkProps {
  item: IText
  isMobile: boolean
  isSliced?: boolean
}

function ParagraphWithLink({
  item,
  isMobile,
  isSliced,
}: IParagraphWithLinkProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  if (!item) return null;

  return (
    <>
      <Typography
        variant="defaultRegular"
        className={s.paragraph}
        component="p"
      >
        {htmlParser(item.content || '', isMobile, top100Prefix)}
      </Typography>
      {!isSliced && (
        <div className={s.linkBlock}>
          <Typography
            variant="defaultRegular"
            className={s.link}
            element={<Link to={item.link!} />}
            {...getTop100Markup(isMobile, top100Prefix, `text_links::${item.linkContent}::${item.link}`)}
          >
            {item.linkContent}
            <Icon
              className={s.linkIcon}
              id="read-more"
            />
          </Typography>
        </div>
      )}
    </>
  );
}

ParagraphWithLink.defaultProps = {
  isSliced: false,
};

export { ParagraphWithLink };
