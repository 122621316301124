import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import Popup from '@rambler-components/popup';
import { Icon } from 'common/components/Icon';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import { AccountForm } from '../AccountForm';

import s from './styles.css';

interface IAccountModal {
  isOpen: boolean
  sign: SIGN
  onClose: () => void
}

export const AccountModal: React.FC<IAccountModal> = React.memo(({ isOpen, sign, onClose }) => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const {
    top100Prefix,
  } = useTop100Context();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {!isMobile && (
        <Popup
          width={380}
          zIndex={5005}
          isOpen={isOpen}
          onClose={handleClose}
        >
          <AccountForm sign={sign} onClose={onClose} />
        </Popup>
      )}
      {isMobile && (
        <div className={cn(s.root, isOpen && s.visible)}>
          <button
            type="button"
            aria-label="Закрыть"
            className={s.closeButton}
            onClick={handleClose}
            {...getTop100Markup(true, top100Prefix, `bubbles_account::${sign}::close_button`)}
          >
            <Icon id="close" className={s.closeIcon} />
          </button>
          <AccountForm sign={sign} onClose={onClose} />
        </div>
      )}
    </>
  );
});
