import React, {
  useState,
  useCallback,
} from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';

import Button from '@rambler-components/button';

import {
  namesTypes,
  namesCategoriesExtended,
} from 'config/constants/names';

import Select from '@rambler-components/select';

import { useTop100Context } from 'common/contexts/top100Context';

import { getSizeStyle, getTop100 } from './index';

import s from './styles.css';

const namesTypesList = {
  noType: 'Без подборки',
  ...namesTypes,
};

const namesCategoriesExtendedList = {
  noCategory: 'Без категории',
  ...namesCategoriesExtended,
};

interface ICardFormProps {
  isMobile: boolean
}

function NamesCategoriesForm({ isMobile }: ICardFormProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const history = useHistory();
  const [typeSelect, setTypeSelect] = useState<keyof typeof namesTypesList>('baby');
  const [caterogySelect, setCategorySelect] = useState<keyof typeof namesCategoriesExtendedList>('modern');

  const onClick = useCallback(() => {
    const type = caterogySelect === 'girls' || caterogySelect === 'boys' || typeSelect === 'noType'
      ? ''
      : typeSelect;

    const url = `/names/${type ? `${type}/` : ''}${caterogySelect !== 'noCategory' ? `${caterogySelect}/` : ''}`;

    history.push(url);
  }, [typeSelect, caterogySelect]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeType = useCallback(type => {
    setTypeSelect(type);
  }, []);

  const onChangeCategory = useCallback(category => {
    setCategorySelect(category);
  }, []);

  return (
    <>
      <Select
        className={getSizeStyle(isMobile, 'normal')}
        value={typeSelect}
        zIndex={150}
        type="border"
        onChange={onChangeType}
        options={Object.keys(namesTypesList).map((typeKey: keyof typeof namesTypesList) => {
          const isDisabled = (typeKey !== 'baby' && typeKey !== 'noType')
            && (caterogySelect === 'girls' || caterogySelect === 'boys');

          return {
            label:    namesTypesList[typeKey],
            value:    typeKey,
            disabled: isDisabled,
          };
        })}
      />
      <Select
        className={getSizeStyle(isMobile, 'normal')}
        value={caterogySelect}
        zIndex={150}
        type="border"
        onChange={onChangeCategory}
        options={Object.keys(namesCategoriesExtendedList).map(
          (categoryKey: keyof typeof namesCategoriesExtendedList) => {
            const isDisabled = (typeSelect !== 'baby' && typeSelect !== 'noType')
              && (categoryKey === 'girls' || categoryKey === 'boys');

            return {
              label:    namesCategoriesExtendedList[categoryKey],
              value:    categoryKey,
              disabled: isDisabled,
            };
          },
        )}
      />
      <Button
        className={cn(
          s.button,
          isMobile && s.buttonMobile,
        )}
        onClick={onClick}
        {...getTop100(isMobile, top100Prefix, 'names_categories', 'result_button')}
      >
        Перейти
      </Button>
    </>
  );
}

export { NamesCategoriesForm };
