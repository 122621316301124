import React, { Fragment } from 'react';
import cn from 'classnames';

import { Typography } from 'common/components/Typography';

import s from './index.css';

interface Props {
  specs: {
    key: string
    value: string[]
  }[]
  isMobile?: boolean
  onClick?: (item: string) => void
  specClassName?: string
  wrapperClassName?: string
  clickableTextClassName?: string
}

function Specs({
  specs,
  isMobile,
  onClick,
  specClassName,
  wrapperClassName,
  clickableTextClassName,
}: Props) {
  if (!specs || !specs.length) return null;

  return (
    <Typography variant="defaultMedium" className={cn(s.root, wrapperClassName)}>
      {specs.map(({ key, value }, specIndex) => (
        <span
          key={key || specIndex}
          className={cn(s.spec, specClassName)}
        >
          {key
            ? (
              <span className={cn(
                s.name,
                isMobile ? s.nameMobile : s.nameDesktop,
              )}
              >
                {key}
              </span>
            ) : null}

          <span className={s.value}>
            {value.map((item, valueIndex) => (
              <Fragment key={item}>
                <span
                  {...onClick ? {
                    onClick:   () => onClick(item),
                    className: cn(
                      s.valueText,
                      isMobile && s.valueTextMobile,
                      s.clickable,
                      clickableTextClassName,
                    ),
                  } : {
                    className: cn(s.valueText, isMobile && s.valueTextMobile),
                  }}
                >
                  {item}
                </span>
                {valueIndex !== value.length - 1 ? ', ' : ''}
              </Fragment>
            ))}
          </span>
        </span>
      ))}
    </Typography>
  );
}

Specs.defaultProps = {
  isMobile:               false,
  onClick:                () => {},
  specClassName:          '',
  wrapperClassName:       '',
  clickableTextClassName: '',
};

export { Specs };
