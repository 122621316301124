import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { ICardProps } from 'Card';

import {
  getOrderedAds,
  getTextBreakPoint,
  getAdditionalAdsBreakPoint,
} from 'config/constants/content';

import { Ad } from 'common/components/Ad';
import { Typography } from 'common/components/Typography';

import { useParagraphsMob, CUT_TYPE } from 'common/utils/clusterContent/useParagraphsMob';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.puid6,
    (state: IAppState) => state.runtime.puid18,
    (state: IAppState) => state.runtime.isMobile,
  ],
  (
    puid6,
    puid18,
    isMobile,
  ) => ({
    puid6,
    puid18,
    isMobile,
  }),
);

interface ICardClusterTextProps {
  text: NonNullable<ICardProps['article']>['body']
  isPremium?: boolean
}

function ClusterText({
  text,
  isPremium,
}: ICardClusterTextProps) {
  const {
    puid6,
    puid18,
    isMobile,
  } = useSelector(selectData);

  const {
    paragraphsListBeforeCut,
    paragraphsListInCut,
  } = useParagraphsMob(text, [], CUT_TYPE.NO_CUT);

  if (!text) return null;

  const puids = {
    puid6,
    puid18,
  };

  const getTextComponent = (
    Component: any,
    paragraphText: string,
    config: TagConfigType | EmbedConfigType | undefined,
    isMediaContent: boolean,
    isBeforeMedia?: boolean,
  ) => (
    <Component
      className={cn(
        s.paragraph,
        isMobile ? s.paragraphMobile : s.paragraphDesktop,
        isMediaContent && s.paragraphMedia,
        isBeforeMedia && s.paragraphBeforeMedia,
      )}
      text={paragraphText}
      config={config}
      clusterIndex={0}
      puids={puids}
      isMediaContent={isMediaContent}
    />
  );

  const adOrdered = getOrderedAds(isMobile);
  const textBreakPoint = getTextBreakPoint(isMobile);
  const additionalAdsBreakPoint = getAdditionalAdsBreakPoint(isMobile);

  let textLength = 0;
  let adCount = 0;

  const combinedContent = [...paragraphsListBeforeCut, ...paragraphsListInCut];
  const combinedContentLength = combinedContent.length;

  return (
    <Typography
      variant="defaultRegular"
      className={cn(
        s.content,
        isMobile ? s.contentMobile : s.contentDesktop,
        isPremium && s.contentPremium,
      )}
      element={<div itemProp="articleBody" />}
    >
      {combinedContent.map((paragraphConfig, index) => {
        const {
          config,
          Component,
          // bannerIndex,
          text: paragraphText,
          isMediaContent,
        } = paragraphConfig;

        // Не нужно ставить рекламу сразу после заголовка
        const isAdStop = paragraphText.includes('<h2>') || paragraphText.includes('<h3>');

        const textComponent = getTextComponent(
          Component,
          paragraphText,
          config,
          isMediaContent,
          (combinedContent[index + 1] || {}).isMediaContent,
        );

        let adName = null;
        let breakPointFactor = 1;

        const textForAdAnalyze = paragraphText
          .replace(/\s{2,}/g, '')
          .replace(/<\/?[^>]+(>|$)/g, '')
          .replace(/\r|\n/g, '');

        textLength += textForAdAnalyze.length;

        if (isMobile && adCount > 2) {
          breakPointFactor = 2;
        }

        if (!isAdStop) {
          if (textLength >= (textBreakPoint * breakPointFactor) && adCount < adOrdered.length) {
            adName = adOrdered[adCount];
            adCount += 1;
            textLength = 0;
          } else if (adCount >= adOrdered.length && textLength >= additionalAdsBreakPoint) {
            adName = isMobile ? 'content4' : 'center';
            textLength = 0;
          }
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            {textComponent}
            {!isPremium && index + 1 < combinedContentLength && adName && (
              <Ad
                name={adName}
                wrapperClassName={cn(s.ad, isMobile ? s.adMobile : s.adDesktop)}
              />
            )}
          </Fragment>
        );
      })}
    </Typography>
  );
}

ClusterText.defaultProps = {
  isPremium: false,
};

export { ClusterText };
