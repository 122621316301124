import React from 'react';
import { ICardProps } from 'Card';

import { FortuneGame } from '../@Fortune/Game';

import s from './index.css';

interface Props {
  special: ICardProps['special']
}

function Special({
  special,
}: Props) {
  if (!special) return null;

  const {
    game_id: gameId,
    iframe_link: iframeLink,
  } = special;

  return (
    <div className={s.root}>
      <FortuneGame link={iframeLink} name={gameId} />
    </div>
  );
}

export { Special };
