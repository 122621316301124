/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import {
  ICardProps,
  INameBubble,
} from 'Card';

import { Ad } from 'common/components/Ad';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { isInHorizontalViewport } from 'common/utils/isInViewport';

// import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface ICardListBubblesProps {
  listBubbles: NonNullable<ICardProps['list_bubbles']>
  isMobile: boolean
  type: ICardProps['type']
  className?: string
}

const CHUNKS_COUNT = (isMobile: boolean = false) => (isMobile ? 2 : 3);

function ListBubbles({
  listBubbles,
  isMobile,
  type,
  className = '',
}: ICardListBubblesProps) {
  // const {
  //   top100Prefix,
  // } = useTop100Context();
  const {
    title,
    columns,
    tabs,
  } = listBubbles;

  // eslint-disable-next-line max-len
  const getChunks = (arr: INameBubble[]) => arr.reduce<INameBubble[][]>((resultArray, item, index) => {
    const chunkSize = Math.ceil(arr.length / CHUNKS_COUNT(isMobile));
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  const chunkedBubbles = useRef(listBubbles);
  const tabsList = useRef<string[]>([]);

  chunkedBubbles.current.tabs = useMemo(() => tabs.map(tab => {
    if (tab.tab_name) {
      tabsList.current.push(tab.tab_name);
    }

    return {
      ...tab,
      chunkedList: columns && tab.list.length >= CHUNKS_COUNT(isMobile)
        ? getChunks(tab.list)
        : [tab.list],
    };
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeIndex, setActiveIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState(chunkedBubbles.current.tabs[0]);
  const [arrowsState, setArrowsState] = useState({
    left:  false,
    right: tabs.length > 18,
  });

  const navListRef = useRef<HTMLDivElement>(null);
  const moveNav = useCallback((newArrowsState, index: number = 0, withSmooth: boolean = false) => {
    const scrollWrapper = navListRef.current;

    if (scrollWrapper) {
      const { needScrollToViewLeft } = isInHorizontalViewport(
        scrollWrapper,
        // todo: fix
        // @ts-ignore
        scrollWrapper.childNodes[index],
      );

      const newLeft = needScrollToViewLeft - scrollWrapper.offsetLeft;

      scrollWrapper.scrollTo({
        left:     newLeft,
        behavior: withSmooth ? 'smooth' : 'auto',
      });

      const isEndOfScroll = scrollWrapper.scrollWidth <= newLeft + scrollWrapper.offsetWidth;

      if (isEndOfScroll) {
        setArrowsState({
          ...newArrowsState,
          right: false,
        });
      } else {
        setArrowsState(newArrowsState);
      }
    }
  }, []);

  const handleClick = useCallback((shift: number) => {
    let newIndex = 0;
    const nextIndex = activeIndex + shift;
    const maxIndex = tabsList.current.length - 1;

    if (shift < 0) {
      newIndex = nextIndex <= 0 ? 0 : nextIndex;
    } else {
      newIndex = maxIndex > nextIndex ? nextIndex : maxIndex;
    }

    const nextArrowsState = (!arrowsState.left && !arrowsState.right) || tabs.length <= 18
      ? {
        left:  false,
        right: false,
      }
      : {
        left:  newIndex !== 0,
        right: ![newIndex, newIndex + 1].includes(maxIndex),
      };

    setActiveIndex(newIndex);
    moveNav(nextArrowsState, newIndex, true);
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeIndex) {
      moveNav(arrowsState, activeIndex, false);
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  if (!listBubbles) return null;

  // const getTop100 = (postfix: string) => ({
  //   [`data-horo${isMobile ? '-mobile' : ''}-new`]: `${top100Prefix}::${postfix}`,
  // });

  // todo: у ListBubbles, NamesByParams и NamesFatherNames много общего. Переработать

  return (
    <>
      <div
        className={cn(
          s.root,
          isMobile ? s.rootMobile : s.rootDesktop,
          className,
        )}
      >
        {title && (
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
            component="h2"
            className={s.title}
          >
            {title}
          </Typography>
        )}
        {tabsList.current.length > 0 ? (
          <div className={s.nav}>
            {!isMobile && (
              <Icon
                className={cn(
                  s.icon,
                  s.iconLeft,
                  !arrowsState.left && s.iconDisabled,
                )}
                id="arrow-next"
                onClick={() => arrowsState.left && handleClick(-2)}
              />
            )}
            <div
              className={cn(
                s.navList,
                isMobile ? s.navListMobile : s.navListDesktop,
              )}
              ref={navListRef}
            >
              {tabsList.current.map((tab, index) => (
                <span
                  className={cn(
                    s.tab,
                    currentTab.tab_name === tab && s.tabActive,
                  )}
                  onClick={() => {
                    if (currentTab.tab_name !== tab) {
                      setCurrentTab(chunkedBubbles.current.tabs[index]);
                      setActiveIndex(index);
                      handleClick(index - activeIndex);
                    }
                  }}
                  key={`ListBubbles-tab-${tab}`}
                >
                  {tab}
                </span>
              ))}
            </div>
            {!isMobile && (
              <Icon
                className={cn(
                  s.icon,
                  s.iconRight,
                  !arrowsState.right && s.iconDisabled,
                )}
                id="arrow-next"
                onClick={() => arrowsState.right && handleClick(2)}
              />
            )}
          </div>
        ) : null}
        <div
          className={cn(
            s.list,
            isMobile ? s.listMobile : s.listDesktop,
          )}
        >
          {currentTab.chunkedList.map((chunk, cIndex) => {
            const chunkIndex = `ListBubbles-${currentTab.tab_name}-${cIndex}`;

            return (
              <div
                className={cn(
                  s.chunk,
                  columns ? s.chunkColumn : s.chunkLine,
                  isMobile ? s.chunkMobile : s.chunkDesktop,
                )}
                key={chunkIndex}
              >
                {chunk.map(item => {
                  const {
                    link,
                    name,
                  } = item;
                  const key = `${chunkIndex}-${link}`;

                  return (
                    <Typography
                      variant="defaultMedium"
                      className={cn(
                        s.defaultBubble,
                        s.link,
                        item.sign && s[item.sign],
                      )}
                      key={key}
                      element={<Link to={item.link} />}
                    >
                      {name}
                    </Typography>
                  );
                })}
              </div>
            );
          })}
          {currentTab.chunkedList.length < CHUNKS_COUNT(isMobile)
            ? <div className={s.listFake} />
            : null}
        </div>
      </div>
      {type === 'list_view_alphabet' && (
        <Ad
          name={isMobile ? 'content1' : 'center'}
          wrapperClassName={isMobile ? s.adMobile : s.adDesktop}
        />
      )}

    </>
  );
}

ListBubbles.defaultProps = {
  className: '',
};

export { ListBubbles };
