import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { ICardProps } from 'Card';

import { SIGN } from 'config/constants/sign';
import signNames from 'config/constants/signNames';

import { fetchCompatibilityFormData } from 'common/redux/compatibility';
import { signNameOptions } from 'common/utils/signNameValues';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import Input from '@rambler-components/input';
import Select from '@rambler-components/select';

import { Text } from 'common/components/Card/Text';
import { Icon } from 'common/components/Icon';
import { ReadMore } from 'common/components/Card/ReadMore';
import { Typography } from 'common/components/Typography';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.compatibility,
  ],
  compatibility => ({
    compatibility,
  }),
);

interface ICompatibilityFormProps {
  defaultCompatibility: CompatibilityData
  isMainCard: ICardProps['isMainCard']
  isMobile: boolean
  className?: string
}

function CompatibilityForm({
  defaultCompatibility,
  isMainCard,
  isMobile,
  className,
}: ICompatibilityFormProps) {
  const {
    top100Prefix,
  } = useTop100Context();
  const dispatch = useDispatch();
  const { compatibility } = useSelector(selectData);

  if (!defaultCompatibility) return null;

  const currentCompatibility = !compatibility.sign_man || !compatibility.sign_woman
    ? defaultCompatibility
    : compatibility;

  const currentMan: keyof typeof SIGN = currentCompatibility.sign_man;
  const currentWoman: keyof typeof SIGN = currentCompatibility.sign_woman;

  const zodiacSignNames = Object.keys(signNames.zodiac).slice(1);

  const changeSign = async (val: any, type: string) => {
    if (type === 'man' && currentMan !== val) {
      await dispatch(fetchCompatibilityFormData(val, currentWoman));
    } else if (type === 'woman' && currentWoman !== val) {
      await dispatch(fetchCompatibilityFormData(currentMan, val));
    }
  };

  const getTop100 = (tail: string) => getTop100Markup(isMobile, top100Prefix, `form::compatibility::${tail}`);

  const formSelect = (
    values: {
      label: string
      value: string
    }[],
    value: keyof typeof SIGN,
    icon: any,
    type: 'woman' | 'man',
  ) => (
    <div className={s.selectContainer}>
      <Select
        value={value}
        placeholder="Знак зодиака"
        type="border"
        options={values}
        onChange={(val: any) => changeSign(val, type)}
        zIndex={type === 'woman' ? 150 : 100}
        {...getTop100(`${type === 'woman' ? 'her_sign' : 'his_sign'}`)}
      />
      <span className={cn(s.selectIcon, s[`selectIcon-${type}`])}>{icon}</span>
    </div>
  );

  const getPrevSign = (sign: keyof typeof SIGN) => {
    if (sign === zodiacSignNames[0]) {
      const lastSign = zodiacSignNames[zodiacSignNames.length - 1] as keyof typeof SIGN;
      return lastSign;
    }
    const prevSign = zodiacSignNames[zodiacSignNames.indexOf(sign) - 1] as keyof typeof SIGN;
    return prevSign;
  };

  const getNextSign = (sign: keyof typeof SIGN) => {
    if (sign === zodiacSignNames[zodiacSignNames.length - 1]) {
      const firstSign = zodiacSignNames[0] as keyof typeof SIGN;
      return firstSign;
    }
    const nextSign = zodiacSignNames[zodiacSignNames.indexOf(sign) + 1] as keyof typeof SIGN;
    return nextSign;
  };

  const formCylinder = (
    value: keyof typeof SIGN,
    icon: any,
    type: 'woman' | 'man',
  ) => (
    <div className={s.cylinder}>
      <button
        type="button"
        className={s.prevSign}
        onClick={() => changeSign(getPrevSign(value), type)}
        {...getTop100(`${type === 'woman' ? 'her_sign' : 'his_sign'}::button::prev_sign`)}
      >
        {signNames.zodiac[getPrevSign(value)]}
        <div className={s.button}>
          <Icon id="arrow-down" className={s.icon} />
        </div>
      </button>
      <Input
        className={s.input}
        type="border"
        value={signNames.zodiac[value]}
        key={value}
        icon={icon}
        placeholder="Знак зодиака"
      />
      <button
        type="button"
        className={s.nextSign}
        onClick={() => changeSign(getNextSign(value), type)}
        {...getTop100(`${type === 'woman' ? 'her_sign' : 'his_sign'}::button::next_sign`)}
      >
        {signNames.zodiac[getNextSign(value)]}
        <div className={s.button}>
          <Icon id="arrow-down" className={s.icon} />
        </div>
      </button>
    </div>
  );

  const signOptions = signNameOptions('zodiac');

  const iconMan = (
    <div className={s.iconContainer}>
      <Icon id="man" className={s.icon} />
    </div>
  );
  const iconWoman = (
    <div className={s.iconContainer}>
      <Icon id="woman" className={s.icon} />
    </div>
  );

  const link = {
    link: `/sovmestimost-znakov-zodiaka/zhenshhina-${signNames.zodiacTranslit[currentWoman]}-muzhchina-${signNames.zodiacTranslit[currentMan]}/`,
  };

  return (
    <div
      className={cn(
        s.root,
        className,
      )}
    >
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component="h2"
        className={s.title}
      >
        Рассчитайте свою совместимость
      </Typography>
      <div className={cn(s.form, isMobile && s.formMobile)}>
        {
          isMobile
            ? formSelect(signOptions, currentWoman, iconWoman, 'woman')
            : formCylinder(currentWoman, iconWoman, 'woman')
        }
        <Typography
          variant="h1"
          className={s.percentage}
          element={(
            <Link
              to={link.link}
              {...getTop100('percent_link')}
            />
          )}
        >
          {`${currentCompatibility.percentage}%`}
        </Typography>
        {
          isMobile
            ? formSelect(signOptions, currentMan, iconMan, 'man')
            : formCylinder(currentMan, iconMan, 'man')
        }
      </div>
      <Text
        wrapperClassName={s.text}
        text={currentCompatibility.text}
        isMainCard={isMainCard}
        isFormText
      />
      <ReadMore
        className={cn(
          s.readMore,
          isMobile ? s.readMoreMobile : s.readMoreDesktop,
        )}
        link={link}
        isMobile={isMobile}
      />
    </div>
  );
}

CompatibilityForm.defaultProps = {
  className: '',
};

export { CompatibilityForm };
