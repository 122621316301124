import React from 'react';
import cn from 'classnames';

import { ICardProps } from 'Card';
import s from './index.css';

interface Props {
  data: ICardProps['about_block']
  isMobile?: boolean
  className?: string
}

function AboutBlock({
  data,
  isMobile,
  className,
}: Props) {
  if (!data || !data.length) return null;

  return (
    <div
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        className,
      )}
    >
      {data.map(({ key, value }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span className={s.aboutItem} key={`about-item-${index}`}>
          <span className={s.aboutItemKey}>{key}</span>
          <span
            className={s.aboutItemValue}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: value,
            }}
          />
        </span>
      ))}
    </div>
  );
}

AboutBlock.defaultProps = {
  isMobile:  false,
  className: '',
};

export { AboutBlock };
