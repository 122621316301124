import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Typography } from 'common/components/Typography';

import { EmojiCard, EmojiCardItemType } from '../../EmojiCard';
import { EmoForecast, Reactions } from '../../EmoForecast';

import s from './styles.css';

const TABS: EmojiCardItemType[] = [
  {
    name:        'fine',
    icon:        'emoji-fine',
    title:       'Всё хорошо',
    textDataKey: 'everything_fine_text',
  },
  {
    name:        'doubt',
    icon:        'emoji-doubt',
    title:       'Сомнения',
    textDataKey: 'in_doubt_text',
  },
  {
    name:        'support',
    icon:        'emoji-support',
    title:       'Упадок сил',
    textDataKey: 'relationship_difficulties_text',
  },
  {
    name:        'event',
    icon:        'emoji-event',
    title:       'Кое-что жду',
    textDataKey: 'important_event_text',
  },
];

type PropsType = {
  splitName: string
  widgetData: EmoWidgetDataType
};

export const Toggle: React.FC<PropsType> = React.memo(({ splitName, widgetData }) => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const [activeTab, setActiveTab] = useState(TABS[0].name);
  const [reactions, setReactions] = useState<{[key: string]: Reactions}>({});

  const tabHandleClick = (name: EmojiCardItemType['name']) => {
    setActiveTab(name);
  };

  const forecastData = TABS.find(({ name }) => name === activeTab) || {} as EmojiCardItemType;

  return (
    <div className={cn(
      s.root,
      isMobile && s.mobile,
    )}
    >
      <Typography
        variant="h3"
        className={s.title}
      >
        Что вы сегодня чувствуете? Нажмите и получите совет
      </Typography>
      <div className={s.tabs}>
        {isMobile && (
          <div className={s.scroll}>
            <div className={s.scrollContent}>
              {TABS.map((item: EmojiCardItemType) => (
                <EmojiCard
                  key={item.name}
                  type="tab"
                  isActive={activeTab === item.name}
                  item={item}
                  splitName={splitName}
                  onClick={() => tabHandleClick(item.name)}
                />
              ))}
            </div>
          </div>
        )}
        {!isMobile && TABS.map((item: EmojiCardItemType) => (
          <EmojiCard
            key={item.name}
            type="tab"
            isActive={activeTab === item.name}
            item={item}
            splitName={splitName}
            onClick={() => tabHandleClick(item.name)}
          />
        ))}
      </div>
      <EmoForecast
        splitName={splitName}
        widgetData={widgetData}
        reactions={reactions}
        onReaction={(reaction: {[key: string]: Reactions}) => setReactions(reaction)}
        forecastData={forecastData}
      />
    </div>
  );
});
