import React, { useMemo, useEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import Hint from '@rambler-components/hint';

import signNames from 'config/constants/signNames';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';
import YandexEvent from 'utils/counters/YandexEvent';

import { YANDEX_METRICS } from 'config/constants/counters';

import { EmojiCardItemType } from '../EmojiCard';

import s from './styles.css';

export enum Reactions {
  Like = 'like',
  Dislike = 'dislike'
}

export enum DisplayVariant {
  Text = 'text',
  Card = 'card'
}

type PropsType = {
  widgetData: EmoWidgetDataType
  forecastData: EmojiCardItemType
  splitName: string
  withIcon?: boolean
  withReactions?: boolean
  withHint?: boolean
  reactions: any
  onReaction: any
  displayVariant?: DisplayVariant
  className?: string
  onBack?: () => void
};

export const EmoForecast: React.FC<PropsType> = React.memo(({
  widgetData,
  forecastData,
  splitName,
  withIcon,
  withHint,
  reactions,
  onReaction,
  displayVariant = '',
  withReactions = true,
  className,
  onBack,
}) => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const {
    top100Prefix,
  } = useTop100Context();

  const { ref: rootRef, inView } = useInView({
    threshold: 0.9,
  });

  const top100Postfix = `${splitName}::
    ${widgetData.sign}::${widgetData.date}::${displayVariant === DisplayVariant.Text ? 'all' : forecastData.name}`;

  useEffect(() => {
    if (inView) {
      (new YandexEvent(YANDEX_METRICS.COMMON)).send({
        type: 'reachGoal',
        data: 'emo_widget_read',
      });
    }
  }, [inView, forecastData.name]);

  const forecastText = useMemo(() => {
    if (Object.keys(widgetData)?.length && forecastData) {
      const dataKey = forecastData.textDataKey as keyof EmoWidgetDataType;
      return widgetData?.[dataKey] || '';
    }

    return '';
  }, [forecastData, widgetData]);

  const signDativeName = signNames.zodiacDative[widgetData.sign as keyof typeof SIGN];
  const titleByName = {
    fine:    `Как ${signDativeName} сделать день еще лучше`,
    doubt:   `Совет ${signDativeName}, как избежать ошибок`,
    support: `Что поможет ${signDativeName} пережить этот период`,
    event:   `Что делать ${signDativeName}, чтобы всё прошло отлично`,
  };
  const forecastTitle = displayVariant === DisplayVariant.Text
    ? forecastData.title : titleByName[forecastData.name];

  const reactionHandleClick = (reactionName: Reactions) => {
    onReaction({ ...reactions, [forecastData.name]: reactionName });
  };

  const backHandleClick = () => {
    if (onBack) {
      onBack();
    }
  };

  return (
    <div
      ref={rootRef}
      className={cn(
        s.root,
        displayVariant && s[displayVariant],
        isMobile && s.mobile,
        className,
      )}
    >
      {onBack && (
        <button
          type="button"
          aria-label="Назад"
          className={s.backButton}
          onClick={backHandleClick}
        >
          <Icon
            id="back"
            className={s.backIcon}
          />
        </button>
      )}
      <div className={s.title}>
        {withIcon && (
          <Icon id={forecastData.icon} className={s.titleIcon} />
        )}
        <Typography
          variant={displayVariant === DisplayVariant.Card ? 'h3' : 'defaultBold'}
          className={s.titleText}
        >
          {forecastTitle}
        </Typography>
      </div>
      <Typography
        variant="defaultRegular"
        className={s.forecastText}
      >
        {forecastText}
      </Typography>
      {withReactions && (
        <div className={s.reactions}>
          <Typography
            variant="defaultMedium"
            className={s.reactionsText}
          >
            Это было полезно?
          </Typography>
          <button
            type="button"
            aria-label="Нравиться"
            disabled={reactions[forecastData.name] === Reactions.Dislike}
            className={cn(
              s.loveButton,
              reactions[forecastData.name] === Reactions.Like && s.active,
            )}
            onClick={() => reactionHandleClick(Reactions.Like)}
            {...getTop100Markup(isMobile, top100Prefix, `${top100Postfix}::like`)}
          >
            <Icon
              id="love"
              className={s.loveIcon}
            />
          </button>
          <button
            type="button"
            aria-label="Не нравиться"
            disabled={reactions[forecastData.name] === Reactions.Like}
            className={cn(
              s.dislikeButton,
              reactions[forecastData.name] === Reactions.Dislike && s.active,
            )}
            onClick={() => reactionHandleClick(Reactions.Dislike)}
            {...getTop100Markup(isMobile, top100Prefix, `${top100Postfix}::dislike`)}
          >
            <Icon
              id="dislike"
              className={s.dislikeIcon}
            />
          </button>
          {withHint && (
            <Hint className={s.hint}>
              Советы написаны исходя из сегодняшних прогнозов и гороскопов для вашего знака.
            </Hint>
          )}
        </div>
      )}
    </div>
  );
});
