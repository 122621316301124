import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { ICardProps } from 'Card';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import s from './styles.css';

interface ICardMiniProps {
  title: ICardProps['title']
  link: ICardProps['link']
  isMobile: boolean
  isH2Title?: boolean
  className?: string
  iconType?: string
}

function Mini({
  title,
  link,
  isMobile,
  isH2Title,
  iconType,
  className,
}: ICardMiniProps) {
  const {
    top100Prefix,
  } = useTop100Context();

  if (!link) return null;

  return (
    <Link
      className={cn(
        s.mini,
        className,
      )}
      to={link.link}
      {...getTop100Markup(isMobile, top100Prefix, `mini_block::${link.link}`)}
    >
      {iconType && <Icon id={iconType} className={cn(s.icon, s.iconLeft)} />}
      {isH2Title
        ? <Typography variant="defaultBold" component="h2" className={s.title}>{title}</Typography>
        : <Typography variant="defaultBold" className={s.title}>{title}</Typography>}
      {!isMobile && <Icon id="read-more" className={cn(s.icon, s.iconRight)} />}
    </Link>
  );
}

Mini.defaultProps = {
  className: '',
  isH2Title: false,
  iconType:  '',
};

export { Mini };
