import React, {
  useState, useEffect, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import add from 'date-fns/add';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import { wordDeclination } from 'common/utils/wordDeclination';

import s from './index.css';

const TIMER_INTERVAL = 1000;

interface Props {
  view: string
  isMobile: boolean
  className?: string
}

function RetrogradeMercuryAlert({
  view,
  isMobile,
  className,
}: Props) {
  const serverInitialTime = useSelector((state: IAppState) => state.runtime.serverInitialTime)
  const adminTimer = useSelector(
    (state: IAppState) => state.runtime.adminData.timers.retrograde_mercury_timer,
  );

  // для удобства тестирования
  const { search } = useLocation();
  const testTime = new URLSearchParams(search).get('retrogradCurrentTime');

  const {
    top100Prefix,
  } = useTop100Context();

  const startTime = useRef(testTime ? new Date(testTime).getTime() : serverInitialTime);
  const retrogradeStart = new Date(adminTimer.enabled_from).getTime();
  const retrogradeEnd = new Date(adminTimer.enabled_to).getTime();

  const isVisible = adminTimer.enabled && (startTime.current < retrogradeEnd);
  const isHorizontal = view === 'horizontal';

  const [isRMStarted, setIsRMStarted] = useState(startTime.current > retrogradeStart);

  const [timer, setTimer] = useState({
    days:    0,
    hours:   0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        const isRMAlreadyStarted = startTime.current > retrogradeStart;
        const finalTime = isRMAlreadyStarted ? retrogradeEnd : retrogradeStart;
        const days = differenceInDays(finalTime, startTime.current);
        let newTime = add(startTime.current, { days });
        const hours = differenceInHours(finalTime, newTime);
        newTime = add(newTime, { hours });
        const minutes = differenceInMinutes(finalTime, newTime);
        newTime = add(newTime, { minutes });
        const seconds = differenceInSeconds(finalTime, newTime);

        startTime.current += TIMER_INTERVAL;

        if (isRMStarted !== isRMAlreadyStarted) {
          setIsRMStarted(isRMAlreadyStarted);
        }

        setTimer({
          days,
          hours,
          minutes,
          seconds,
        });
      }, TIMER_INTERVAL);

      return () => clearInterval(interval);
    }

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isVisible) return null;

  return (
    <a
      href="/retrograde-mercury/"
      {...getTop100Markup(isMobile, top100Prefix, 'selection_table::selection_change')}
      className={cn(
        s.root,
        isHorizontal ? s.rootHorizontal : s.rootVertical,
        isMobile && s.rootMobile,
        className,
      )}
    >
      <Typography
        variant={isMobile ? 'smallBold' : 'defaultBold'}
        component="span"
        className={s.title}
      >
        Ретроградный Меркурий
        {!isHorizontal && !isMobile ? <br /> : <>&nbsp;</>}
        {!isRMStarted ? 'наступит через:' : 'продлится:'}
      </Typography>
      <div
        className={cn(
          s.timerContainer,
          isHorizontal ? s.timerContainerHorizontal : s.timerContainerVertical,
          isMobile && s.timerContainerMobile,
        )}
      >
        <div
          className={cn(
            s.timer,
            isHorizontal ? s.timerHorizontal : s.timerVertical,
            isMobile && s.timerMobile,
          )}
        >
          {Object.keys(timer).map((segment: 'days' | 'hours' | 'minutes' | 'seconds') => {
            const val = timer[segment];

            let unit = '';
            switch (segment) {
              case 'days':
                unit = wordDeclination(val, 'days');
                break;
              case 'hours':
                unit = wordDeclination(val, 'hours');
                break;
              case 'minutes':
                unit = 'мин.';
                break;
              case 'seconds':
                unit = 'сек.';
                break;
              default:
                unit = '';
            }

            return (
              <div
                className={cn(
                  s.segment,
                  isHorizontal ? s.segmentHorizontal : s.segmentVertical,
                  isMobile && s.segmentMobile,
                )}
                key={segment}
              >
                <Typography
                  variant={isHorizontal || isMobile ? 'h3' : 'h1'}
                  component="span"
                  className={cn(
                    s.number,
                    isHorizontal ? s.numberHorizontal : s.numberVertical,
                  )}
                >
                  {val <= 9 ? '0' : ''}
                  {val}
                </Typography>
                <Typography
                  variant={isHorizontal || isMobile ? 'xSmallMedium' : 'smallMedium'}
                  className={cn(
                    s.text,
                    isHorizontal ? s.textHorizontal : s.textVertical,
                    isMobile && s.textMobile,
                  )}
                >
                  {unit}
                </Typography>
              </div>
            );
          })}
        </div>
        <Typography
          variant="defaultBold"
          className={cn(
            s.link,
            (isHorizontal || isMobile) ? s.linkHorizontal : s.linkVertical,
          )}
        >
          {(!isHorizontal && !isMobile)
            ? 'Узнать подробности'
            : <Icon id="arrow-forward" className={s.arrow} />}
        </Typography>
      </div>
    </a>
  );
}

RetrogradeMercuryAlert.defaultProps = {
  className: '',
};

export { RetrogradeMercuryAlert };
