import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { ICardProps } from 'Card';

import {
  CALENDAR_LEGENDS,
} from 'config/constants/calendar';

import s from './styles.css';

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.isMobile,
    (state: IAppState) => state.calendar,
  ],
  (isMobile, calendar) => ({
    isMobile,
    calendar,
  }),
);

interface IHairHighlight {
  date: ICardProps['date']
  link: ICardProps['link']
  className?: string
}

function HairHighlight({
  date,
  link,
  className,
}: IHairHighlight) {
  const {
    top100Prefix,
  } = useTop100Context();
  const {
    isMobile,
    calendar,
  } = useSelector(selectRuntimeData);

  const dayData = useMemo(() => (date && typeof date === 'string' && calendar[date] ? calendar[date].hair : null), [date, calendar]);

  if (!dayData) return null;

  const Element: any = link ? Link : 'div';
  const highlightLink = link ? link.link : '';

  return (
    <div
      className={cn(
        s.root,
        isMobile && s.rootMobile,
        className,
      )}
    >
      <Typography
        variant="defaultMedium"
        className={cn(s.highlight, highlightLink && s.highlightClickable)}
        element={(
          <Element
            to={highlightLink}
            {...highlightLink ? getTop100Markup(isMobile, top100Prefix, `highlighted::${highlightLink}`) : {}}
          />
        )}
      >
        <Typography
          variant="h3"
          component="div"
          className={s.top}
        >
          <Icon id={`calendar-haircut-${dayData.haircut}-current`} className={s.icon} />
          <span>
            {CALENDAR_LEGENDS.haircut.statuses[dayData.haircut].slice(0, -1)}
            ый день для стрижки
          </span>
        </Typography>
        <div className={cn(s.bottom, isMobile && s.bottomMobile)}>
          {['manicure', 'epilation', 'bodycare'].map((type, typeIndex) => (
            <span className={s.bottomLine} key={type}>
              {(isMobile || typeIndex !== 0) && (<span className={s.bottomDot}>•</span>)}
              {CALENDAR_LEGENDS[type].statuses[dayData[type]]}
              {' '}
              {CALENDAR_LEGENDS[type].label}
            </span>
          ))}
        </div>
      </Typography>
    </div>
  );
}

HairHighlight.defaultProps = {
  className: '',
};

export { HairHighlight };
